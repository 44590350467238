import React from "react";
import BaseDialog from "view/components/BaseDialog";
import { ServingEntity } from "model/entity";
import {
    DialogActions,
    Button,
    DialogContentText,
    CircularProgress
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteServingCancel, deleteServingInit } from "control/actions";
import { Alert } from "@material-ui/lab";

const DeleteServing: React.FC = () => {
    const dispatch = useDispatch();
    const item = useSelector(ServingEntity.selectedItem);
    const status = useSelector(ServingEntity.deleteStatus);

    const handleClose = () => {
        dispatch(deleteServingCancel());
    };

    const confirmDelete = () => {
        if (item) {
            dispatch(
                deleteServingInit({
                    dId: item.dId,
                    sId: item.sId,
                    pH: item.pH,
                    dt: item.dt,
                    ts: item.ts
                })
            );
        }
    };

    return (
        <BaseDialog
            open={status !== "closed"}
            label="delete-serving"
            title="Delete Serving"
            onClose={handleClose}
            actions={
                <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={confirmDelete}
                    >
                        {status === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "confirm"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Are you sure you want to delete the serving?
            </DialogContentText>

            {status === "fail" ? (
                <Alert severity="error">Failed to delete the serving</Alert>
            ) : (
                <div />
            )}
        </BaseDialog>
    );
};

export default DeleteServing;
