import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";

const DataRequiredAlert: React.FC<{
    onClose: () => void;
}> = props => {
    return (
        <Alert
            severity="error"
            style={{ marginBottom: 12 }}
            action={
                <IconButton
                    size="small"
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            }
        >
            <AlertTitle>Required data missing</AlertTitle>
            <span>Name, Address and Meals Served are required fields.</span>
        </Alert>
    )
}

export default DataRequiredAlert;
