import React from "react";
import { Typography } from "@material-ui/core";

const TableTitle: React.FC<{
    title: string;
    isMobile: boolean;
}> = ({ title, isMobile }) => {
    if (!isMobile) {
        return <div />;
    }

    return (
        <Typography variant="h6" style={{ padding: "16px 0px 0px 16px" }}>
            {title}
        </Typography>
    );
};

export default TableTitle;
