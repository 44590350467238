import { AppState } from "model/reducers";
import { createSelector } from "@reduxjs/toolkit";
import { get, cloneDeep, values } from "lodash";

export interface IAccReport {
    nCB: number;
    nCL: number;
    nCS: number;
    nCD: number;
    nAB: number;
    nAL: number;
    nAS: number;
    nAD: number;
}

export interface IServing extends IAccReport {
    dId: string;
    sId: string;
    dt: string;
    pH: string;
    ts: string;
}

export class ServingEntity {
    public static selectedDate(appState: AppState) {
        return appState.serving.date;
    }

    public static selectedSiteId(appState: AppState) {
        return appState.serving.siteId;
    }

    public static items(appState: AppState) {
        return createSelector(
            (state: AppState) => values(state.serving.items),
            items => cloneDeep(items)
        )(appState);
    }

    public static itemStatus(appState: AppState) {
        return appState.serving.status;
    }

    public static createStatus(appState: AppState) {
        return appState.serving.createStatus;
    }

    public static updateStatus(appState: AppState) {
        return appState.serving.updateStatus;
    }

    public static deleteStatus(appState: AppState) {
        return appState.serving.deleteStatus;
    }

    public static selectedRecord(appState: AppState): IServing | null {
        const id = appState.serving.selected;
        const table = appState.serving.items;
        return get(table, id || "", null);
    }

    public static selectedItem(appState: AppState): IServing | null {
        return appState.serving.selectedItem;
    }

    public static phoneRecords(appState: AppState) {
        return appState.serving.phRecords;
    }

    public static phoneRecordsStatus(appState: AppState) {
        return appState.serving.phRecordsStatus;
    }
}
