import {
    createProductionRecord,
    loadProductionCount,
    loadProductionCountFail,
    loadProductionCountSuccess,
    createProductionRecordFail,
    createProductionRecordSuccess,
    updateProductionRecord,
    updateProductionRecordFail,
    updateProductionRecordSuccess
} from "control/actions";
import { UserEntity } from "model/entity";
import { takeEvery, put, select } from "redux-saga/effects";
import productionTransport from "./transport/production.transport";
import logger from "util/logger";

function* loadProdCountProcess(action: any) {
    try {
        const district = yield select(UserEntity.getDistrict);
        const data: any = yield productionTransport.listProductions({
            districtId: district.id,
            siteId: action.payload.siteId,
            date: action.payload.date
        });
        yield put(loadProductionCountSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(loadProductionCountFail());
    }
}

function* createProdProcess(action: any) {
    try {
        const data: any = yield productionTransport.createProduction(
            action.payload
        );
        yield put(createProductionRecordSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(createProductionRecordFail());
    }
}

function* updateProdProcess(action: any) {
    try {
        const data: any = yield productionTransport.updateProduction(
            action.payload
        );
        yield put(updateProductionRecordSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(updateProductionRecordFail());
    }
}

export default function* servingSagas() {
    yield takeEvery(loadProductionCount, loadProdCountProcess);
    yield takeEvery(createProductionRecord, createProdProcess);
    yield takeEvery(updateProductionRecord, updateProdProcess);
}
