import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress
} from "@material-ui/core";
import { UserEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import logger from "util/logger";
import { registerNewUser } from "control/actions";
import { isEmpty } from "lodash";
import SelectDistrict from "../../components/SelectDistrict";

const RegistrationDialog: React.FC = () => {
    const isComplete = useSelector(UserEntity.isUserRegComplete);
    const isAuthenticated = useSelector(UserEntity.isAuthenticated);

    // do not close the dialog until reg is done
    const handleClose = () => {
        logger.info("Will be closed once reg is complete");
    };

    // complete the user registration
    const [districtId, setDistrictId] = React.useState("");

    const dispatch = useDispatch();
    const completeRegistration = () => {
        logger.info(districtId);
        if (!isEmpty(districtId)) {
            dispatch(registerNewUser(districtId));
        }
    };

    return (
        <Dialog
            open={!isComplete && isAuthenticated}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                Account Pending Approval
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please use the form below to submit additional information
                </DialogContentText>

                {/* Select Box */}
                <SelectDistrict value={districtId} onChange={setDistrictId} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={completeRegistration}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const InProgressDialog: React.FC = () => {
    return (
        <Dialog open={true}>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
};

const UserRegistrationDialog: React.FC = () => {
    const inProgress = useSelector(UserEntity.isUserRegInProgress);
    const isAuthenticated = useSelector(UserEntity.isAuthenticated);

    if (!isAuthenticated) {
        return <div />;
    }

    return inProgress ? <InProgressDialog /> : <RegistrationDialog />;
};

export default UserRegistrationDialog;
