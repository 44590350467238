import React from "react";
import logo from "./logo.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    logo: {
        maxWidth: 200
    }
});

const Logo: React.FC = () => {
    const styles = useStyles();
    return <img className={styles.logo} src={logo} alt="Logo" />;
};

export default Logo;
