import React from "react";
import { milTimeToAmPm } from "lib/date.lib";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        minWidth: 180
    }
});

const DayOfWeek: React.FC<{
    label: string;
    tf: {
        s: string;
        e: string;
    };
}> = props => {
    const tf = props.tf;

    return tf ? (
        <span>
            {props.label} ({`${milTimeToAmPm(tf.s)} - ${milTimeToAmPm(tf.e)}`})
        </span>
    ) : (
        <span>
            {props.label} <b>(Closed)</b>
        </span>
    );
};

const HoursOfOperation: React.FC<{
    hop: any;
}> = props => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <DayOfWeek label={"Mon"} tf={props.hop.m} />
            <DayOfWeek label={"Tue"} tf={props.hop.t} />
            <DayOfWeek label={"Wed"} tf={props.hop.w} />
            <DayOfWeek label={"Thu"} tf={props.hop.th} />
            <DayOfWeek label={"Fri"} tf={props.hop.f} />
            <DayOfWeek label={"Sat"} tf={props.hop.st} />
            <DayOfWeek label={"Sun"} tf={props.hop.su} />
        </div>
    );
};

export default HoursOfOperation;
