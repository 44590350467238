import React from "react";
import { TextField } from "@material-ui/core";

const AdditionalInstr: React.FC<{
    value: string | undefined;
    onChange: (val: string | undefined) => void;
}> = props => {
    const updateText = (event: any) => {
        props.onChange(event.target.value);
    };
    return (
        <TextField
            id="addl-instr"
            label="Instructions/Additional Information"
            multiline
            rows="4"
            value={props.value || ""}
            onChange={updateText}
            style={{ width: "100%", marginTop: 10 }}
            variant="outlined"
        />
    );
};

export default AdditionalInstr;
