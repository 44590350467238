import React from "react";
import { UiEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, makeStyles } from "@material-ui/core";
import { closeBottomSheet } from "control/actions";

const useStyles = makeStyles({
    container: {
        minHeight: "20vh"
    }
});

const BottomSheet: React.FC<{
    sections?: React.ReactElement[];
}> = props => {
    const isOpen = useSelector(UiEntity.isBottomSheetOpen);
    const dispatch = useDispatch();
    const styles = useStyles();

    const dispatchClose = () => {
        dispatch(closeBottomSheet());
    };

    return (
        <Drawer
            classes={{
                paper: styles.container
            }}
            anchor={"bottom"}
            open={isOpen}
            onClose={dispatchClose}
        >
            {props.sections?.map((section, index) =>
                React.cloneElement(section, { key: index })
            )}
        </Drawer>
    );
};

export default BottomSheet;
