import { CreateProductionInput, UpdateProductionInput } from "./../API";
import { createAction } from "@reduxjs/toolkit";

export const setProductionDate = createAction(
    "SET_PRODUCTION_DATE",
    (date: Date) => ({
        payload: date
    })
);
export const setProductionSiteId = createAction(
    "SET_PRODUCTION_SITE_ID",
    (siteId: string) => ({
        payload: siteId
    })
);

export const loadProductionCount = createAction(
    "LOAD_PRODUCTION_COUNT",
    (date: Date, siteId: string) => ({
        payload: {
            date,
            siteId
        }
    })
);
export const loadProductionCountSuccess = createAction(
    "LOAD_PRODUCTION_COUNT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const loadProductionCountFail = createAction(
    "LOAD_PRODUCTION_COUNT_FAIL"
);

// Create
export const createProductionRecordOpen = createAction(
    "CREATE_PRODUCTION_RECORD_OPEN"
);
export const createProductionRecord = createAction(
    "CREATE_PRODUCTION_RECORD",
    (data: CreateProductionInput) => ({
        payload: data
    })
);
export const createProductionRecordSuccess = createAction(
    "CREATE_PRODUCTION_RECORD_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const createProductionRecordFail = createAction(
    "CREATE_PRODUCTION_RECORD_FAIL"
);
export const createProductionRecordCancel = createAction(
    "CREATE_PRODUCTION_RECORD_CANCEL"
);

// Update
export const updateProductionRecordOpen = createAction(
    "UPDATE_PRODUCTION_RECORD_OPEN",
    (item: any) => ({
        payload: item
    })
);
export const updateProductionRecord = createAction(
    "UPDATE_PRODUCTION_RECORD",
    (data: UpdateProductionInput) => ({
        payload: data
    })
);
export const updateProductionRecordSuccess = createAction(
    "UPDATE_PRODUCTION_RECORD_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const updateProductionRecordFail = createAction(
    "UPDATE_PRODUCTION_RECORD_FAIL"
);
export const updateProductionRecordCancel = createAction(
    "UPDATE_PRODUCTION_RECORD_CANCEL"
);
