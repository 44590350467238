import { ItemsStatus, ActionStatus } from "./../entity/ui.entity";
import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
    loadSites,
    loadSitesFail,
    loadSitesSuccess,
    createSiteOpen,
    createSiteInit,
    createSiteSuccess,
    createSiteFail,
    createSiteCancel,
    updateSiteOpen,
    updateSiteInit,
    updateSiteSuccess,
    updateSiteFail,
    updateSiteClose,
    userAuthenticated
} from "control/actions";
import * as _ from "lodash";
import { ISite } from "model/entity";

export interface SiteState {
    items: { [id: string]: ISite };
    status: ItemsStatus;
    selected: ISite | null;
    createStatus: ActionStatus;
    updateStatus: ActionStatus;
}

const initialState: SiteState = {
    items: {},
    status: "initial",
    selected: null,
    createStatus: "closed",
    updateStatus: "closed"
};

const getId = (site: ISite): string => site.sId;

export const siteReducer = createReducer(initialState, {
    [loadSites.toString()]: state =>
        produce(state, draft => {
            draft.items = {};
            draft.status = "loading";
        }),
    [userAuthenticated.toString()]: state =>
        produce(state, draft => {
            draft.items = {};
            draft.status = "loading";
        }),
    [loadSitesSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            _.forEach(action.payload, item => {
                draft.items[getId(item)] = item;
            });
            draft.status = "success";
        }),
    [loadSitesFail.toString()]: state =>
        produce(state, draft => {
            draft.status = "fail";
        }),

    // Create
    [createSiteOpen.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "open";
        }),
    [createSiteInit.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "in-progress";
        }),
    [createSiteSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.items[getId(item)] = item;
            draft.createStatus = "closed";
        }),
    [createSiteFail.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "fail";
        }),
    [createSiteCancel.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "closed";
        }),

    // Update
    [updateSiteOpen.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "open";
            draft.selected = action.payload;
        }),
    [updateSiteInit.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "in-progress";
        }),
    [updateSiteSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.items[getId(item)] = item;
            draft.updateStatus = "closed";
            draft.selected = null;
        }),
    [updateSiteFail.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "fail";
        }),
    [updateSiteClose.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "closed";
            draft.selected = null;
        })
});
