import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { DistrictsEntity, IDistrict } from "model/entity/districts.entity";
import { Button, useTheme, useMediaQuery, Switch } from "@material-ui/core";
import { createDistrictOpen, updateDistrictOpen, toggleDistrictStatus } from "control/actions";
import { Alert } from "@material-ui/lab";
import TableTitle from "view/components/TableTitle";
import AddIcon from "@material-ui/icons/Add";

const DistrictsTableView: React.FC = () => {
    const items = useSelector(DistrictsEntity.items);
    const status = useSelector(DistrictsEntity.status);
    const dispatch = useDispatch();

    const onCreateDistrict = () => {
        dispatch(createDistrictOpen());
    };

    const onEditDistrict = (event: any, district: any) => {
        dispatch(updateDistrictOpen(district.id));
    };

    const toggleDistrict = (district: IDistrict, event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(toggleDistrictStatus(district));
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <React.Fragment>
            {status === "fail" ? (
                <Alert severity="error">Unable to load districts</Alert>
            ) : (
                <div />
            )}
            <MaterialTable
                isLoading={status === "loading"}
                columns={[
                    { title: "District ID", field: "id" },
                    {
                        title: "Name",
                        field: "name"
                    },
                    {
                        title: "State",
                        field: "state"
                    },
                    {
                        title: "Status (Is Active?)",
                        field: "iactv",
                        render: rowData => <Switch
                            checked={!rowData.iactv}
                            onChange={toggleDistrict.bind(null, rowData)}
                            name="Inactive"
                            inputProps={{ 'aria-label': 'is active' }}
                        />
                    }
                ]}
                data={items}
                options={{
                    exportButton: true,
                    actionsColumnIndex: -1,
                    showTitle: !isMobile,
                    searchFieldAlignment: isMobile ? "left" : "right"
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <TableTitle title="Districts" isMobile={isMobile} />
                            <MTableToolbar {...props} />
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginLeft: 12 }}
                                onClick={onCreateDistrict}
                            >
                                <AddIcon /> District
                            </Button>
                        </div>
                    )
                }}
                actions={[
                    {
                        icon: "edit",
                        tooltip: "Edit District",
                        onClick: onEditDistrict
                    }
                ]}
                title="All Districts"
            />
        </React.Fragment>
    );
};

export default DistrictsTableView;
