/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fetchUserGroups = /* GraphQL */ `
  query FetchUserGroups($accountId: String!) {
    fetchUserGroups(accountId: $accountId)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($cId: String!) {
    getUser(cId: $cId) {
      cId
      dId
      email
      pH
      pending
      name
      chldr
      meals
      allCh
      algrs
      sAcl
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $cId: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      cId: $cId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cId
        dId
        email
        pH
        pending
        name
        chldr
        meals
        allCh
        algrs
        sAcl
      }
      nextToken
    }
  }
`;
export const getServing = /* GraphQL */ `
  query GetServing(
    $dId: String!
    $dt: AWSDate!
    $sId: String!
    $pH: AWSPhone!
    $ts: AWSDateTime!
  ) {
    getServing(dId: $dId, dt: $dt, sId: $sId, pH: $pH, ts: $ts) {
      dId
      sId
      dt
      pH
      ts
      nCB
      nCL
      nCS
      nCD
      nAB
      nAL
      nAS
      nAD
    }
  }
`;
export const listServings = /* GraphQL */ `
  query ListServings(
    $dId: String
    $dtSIdPHTs: ModelServingPrimaryCompositeKeyConditionInput
    $filter: ModelServingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServings(
      dId: $dId
      dtSIdPHTs: $dtSIdPHTs
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dId
        sId
        dt
        pH
        ts
        nCB
        nCL
        nCS
        nCD
        nAB
        nAL
        nAS
        nAD
      }
      nextToken
    }
  }
`;
export const getProduction = /* GraphQL */ `
  query GetProduction($dId: String!, $sId: String!, $dt: AWSDate!) {
    getProduction(dId: $dId, sId: $sId, dt: $dt) {
      dId
      sId
      dt
      pB
      pL
      pS
      pD
    }
  }
`;
export const listProductions = /* GraphQL */ `
  query ListProductions(
    $dId: String
    $sIdDt: ModelProductionPrimaryCompositeKeyConditionInput
    $filter: ModelProductionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductions(
      dId: $dId
      sIdDt: $sIdDt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dId
        sId
        dt
        pB
        pL
        pS
        pD
      }
      nextToken
    }
  }
`;
export const pendingUsers = /* GraphQL */ `
  query PendingUsers(
    $pending: UserStatus
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingUsers(
      pending: $pending
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cId
        dId
        email
        pH
        pending
        name
        chldr
        meals
        allCh
        algrs
        sAcl
      }
      nextToken
    }
  }
`;
export const servingsForPhone = /* GraphQL */ `
  query ServingsForPhone(
    $dId: String
    $pHDtTs: ModelServingByPhoneNumberCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    servingsForPhone(
      dId: $dId
      pHDtTs: $pHDtTs
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dId
        sId
        dt
        pH
        ts
        nCB
        nCL
        nCS
        nCD
        nAB
        nAL
        nAS
        nAD
      }
      nextToken
    }
  }
`;
export const getDistrict = /* GraphQL */ `
  query GetDistrict($id: ID!) {
    getDistrict(id: $id) {
      id
      name
      state
      iactv
    }
  }
`;
export const listDistricts = /* GraphQL */ `
  query ListDistricts(
    $filter: ModelDistrictFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistricts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        state
        iactv
      }
      nextToken
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($dId: String!, $sId: String!) {
    getSite(dId: $dId, sId: $sId) {
      dId
      sId
      name
      addr {
        ln1
        ln2
        zip
        cty
        st
        ctr
        addl
      }
      meals
      hop {
        m {
          s
          e
        }
        t {
          s
          e
        }
        w {
          s
          e
        }
        th {
          s
          e
        }
        f {
          s
          e
        }
        st {
          s
          e
        }
        su {
          s
          e
        }
      }
      actv
      stCd
      addl
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $dId: String
    $sId: ModelStringKeyConditionInput
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSites(
      dId: $dId
      sId: $sId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dId
        sId
        name
        addr {
          ln1
          ln2
          zip
          cty
          st
          ctr
          addl
        }
        meals
        hop {
          m {
            s
            e
          }
          t {
            s
            e
          }
          w {
            s
            e
          }
          th {
            s
            e
          }
          f {
            s
            e
          }
          st {
            s
            e
          }
          su {
            s
            e
          }
        }
        actv
        stCd
        addl
      }
      nextToken
    }
  }
`;
