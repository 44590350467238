import { IServing } from "model/entity";
import {
    CreateServingInput,
    UpdateServingInput,
    DeleteServingInput
} from "control/API";
import { createAction } from "@reduxjs/toolkit";

export const setServiceSiteId = createAction(
    "SET_SERVICE_SITE_ID",
    (siteId: string | null) => ({
        payload: siteId
    })
);
export const setServiceDate = createAction(
    "SET_SERVICE_DATE",
    (date: Date | null) => ({
        payload: date
    })
);

export const loadServings = createAction(
    "LOAD_SERVINGS",
    (siteId: string, date: Date) => ({
        payload: {
            siteId,
            date
        }
    })
);
export const loadServingsSuccess = createAction(
    "LOAD_SERVINGS_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const loadServingsFail = createAction("LOAD_ALL_SERVINGS_FAIL");

// Create
export const createServingOpen = createAction("CREATE_SERVING_OPEN");
export const createServingInit = createAction(
    "CREATE_SERVING_INIT",
    (data: CreateServingInput) => ({
        payload: data
    })
);
export const createServingSuccess = createAction(
    "CREATE_SERVING_SUCCESS",
    (data: IServing) => ({
        payload: data
    })
);
export const createServingFail = createAction("CREATE_SERVING_FAIL");
export const createServingCancel = createAction("CREATE_SERVING_CANCEL");

// Phone record search
export const loadServingsForPhoneInit = createAction(
    "LOAD_SERVINGS_FOR_PHONE_INIT",
    (date: Date, phNum: string) => ({
        payload: {
            date,
            phNum
        }
    })
);
export const loadServingsForPhoneSuccess = createAction(
    "LOAD_SERVINGS_FOR_PHONE_SUCCESS",
    (data: IServing[]) => ({
        payload: data
    })
);
export const loadServingsForPhoneFail = createAction(
    "LOAD_SERVINGS_FOR_PHONE_FAIL"
);

// Update
export const updateServingOpen = createAction(
    "UPDATE_SERVING_OPEN",
    (serving: IServing) => ({
        payload: serving
    })
);
export const updateServingInit = createAction(
    "UPDATE_SERVING_INIT",
    (data: UpdateServingInput) => ({
        payload: data
    })
);
export const updateServingSuccess = createAction(
    "UPDATE_SERVING_SUCCESS",
    (data: IServing) => ({
        payload: data
    })
);
export const updateServingFail = createAction("UPDATE_SERVING_FAIL");
export const updateServingCancel = createAction("UPDATE_SERVING_CANCEL");

// Delete Serving
export const deleteServingOpen = createAction(
    "DELETE_SERVING_OPEN",
    (serving: IServing) => ({
        payload: serving
    })
);
export const deleteServingInit = createAction(
    "DELETE_SERVING_INIT",
    (input: DeleteServingInput) => ({
        payload: input
    })
);
export const deleteServingSuccess = createAction(
    "DELETE_SERVING_SUCCESS",
    (data: IServing) => ({
        payload: data
    })
);
export const deleteServingFail = createAction("DELETE_SERVING_FAIL");
export const deleteServingCancel = createAction("DELETE_SERVING_CANCEL");
