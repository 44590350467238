import React from "react";
import { Toolbar, Paper, makeStyles, Typography } from "@material-ui/core";
import { UserEntity } from "model/entity";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    root: {
        marginBottom: 8,
        padding: "8px 0px"
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    content: {
        width: "80%",
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap"
    }
});

const BaseToolbar: React.FC<{
    title?: string;
    actions?: React.ReactElement;
}> = props => {
    const styles = useStyles();
    const isAdmin = useSelector(UserEntity.isAdmin);

    return (
        <Paper className={styles.root}>
            <Toolbar className={styles.container} variant="regular">
                <div className={styles.content}>
                    {props.title ? (
                        <Typography variant="h5">{props.title}</Typography>
                    ) : (
                        <div />
                    )}
                    {props.children}
                </div>
                {isAdmin ? <div>{props.actions}</div> : <div />}
            </Toolbar>
        </Paper>
    );
};

export default BaseToolbar;
