import React, { useState, useEffect } from "react";
import BaseDialog from "view/components/BaseDialog";
import { useSelector, useDispatch } from "react-redux";
import { ServingEntity, UserEntity } from "model/entity";
import {
    DialogActions,
    Button,
    CircularProgress,
    DialogContentText
} from "@material-ui/core";
import {
    updateServingCancel,
    updateServingInit,
    updateServingFail
} from "control/actions";
import MealEntry from "./MealEntry";
import { MealType } from "control/API";
import { Alert } from "@material-ui/lab";

const UpdateServing: React.FC = () => {
    const updateStatus = useSelector(ServingEntity.updateStatus);
    const dispatch = useDispatch();
    const serving = useSelector(ServingEntity.selectedRecord);

    const handleClose = () => {
        dispatch(updateServingCancel());
    };

    const [nCB, setNCB] = useState<number>(0);
    const [nAB, setNAB] = useState<number>(0);
    const [nCL, setNCL] = useState<number>(0);
    const [nAL, setNAL] = useState<number>(0);
    const [nCS, setNCS] = useState<number>(0);
    const [nAS, setNAS] = useState<number>(0);
    const [nCD, setNCD] = useState<number>(0);
    const [nAD, setNAD] = useState<number>(0);

    // on edit update values
    useEffect(() => {
        setNCB(serving?.nCB || 0);
        setNAB(serving?.nAB || 0);
        setNCL(serving?.nCL || 0);
        setNAL(serving?.nAL || 0);
        setNCS(serving?.nCS || 0);
        setNAS(serving?.nAS || 0);
        setNCD(serving?.nCD || 0);
        setNAD(serving?.nAD || 0);
    }, [
        serving,
        setNCB,
        setNAB,
        setNCL,
        setNAL,
        setNCS,
        setNAS,
        setNCD,
        setNAD
    ]);

    const district = useSelector(UserEntity.getDistrict);

    const confirmUpdate = () => {
        if (serving) {
            dispatch(
                updateServingInit({
                    dId: district.id,
                    sId: serving.sId,
                    pH: serving.pH,
                    dt: serving.dt,
                    ts: serving.ts,
                    nCB,
                    nAB,
                    nCL,
                    nAL,
                    nCS,
                    nAS,
                    nCD,
                    nAD
                })
            );
        } else {
            dispatch(updateServingFail());
        }
    };

    return (
        <BaseDialog
            open={updateStatus !== "closed"}
            label="update-serving"
            title="Update Serving"
            onClose={handleClose}
            actions={
                <DialogActions>
                    {updateStatus === "fail" ? (
                        <Alert severity="error">Unable to update record</Alert>
                    ) : (
                        <div />
                    )}
                    <Button onClick={handleClose}>Close</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={confirmUpdate}
                    >
                        {updateStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Update"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Use the form below to update entry
            </DialogContentText>
            <form>
                <MealEntry
                    mealType={MealType.Breakfast}
                    childValue={nCB}
                    adultValue={nAB}
                    onChangeChildValue={setNCB}
                    onChangeAdultValue={setNAB}
                />

                <MealEntry
                    mealType={MealType.Lunch}
                    childValue={nCL}
                    adultValue={nAL}
                    onChangeChildValue={setNCL}
                    onChangeAdultValue={setNAL}
                />

                <MealEntry
                    mealType={MealType.Snack}
                    childValue={nCS}
                    adultValue={nAS}
                    onChangeChildValue={setNCS}
                    onChangeAdultValue={setNAS}
                />

                <MealEntry
                    mealType={MealType.Dinner}
                    childValue={nCD}
                    adultValue={nAD}
                    onChangeChildValue={setNCD}
                    onChangeAdultValue={setNAD}
                />
            </form>
        </BaseDialog>
    );
};

export default UpdateServing;
