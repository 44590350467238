import React, { useState, useEffect } from "react";
import BaseDialog from "view/components/BaseDialog";
import { useSelector, useDispatch } from "react-redux";
import {
    createProductionRecord,
    createProductionRecordCancel
} from "control/actions";
import {
    DialogActions,
    Button,
    DialogContentText,
    makeStyles,
    CircularProgress
} from "@material-ui/core";
import InputNumber from "view/components/InputNumber";
import { formatDate } from "lib/date.lib";
import { ProductionEntity } from "model/entity/production.entity";
import { Alert } from "@material-ui/lab";
import { SiteEntity, isMealTypeEnabled, UserEntity } from "model/entity";
import _ from "lodash";
import { MealType } from "control/API";

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    }
});

const CreateProductionCount: React.FC = () => {
    const createStatus = useSelector(ProductionEntity.createStatus);
    const selectedDate = useSelector(ProductionEntity.date);
    const siteId = useSelector(ProductionEntity.siteId);
    const dispatch = useDispatch();
    const styles = useStyles();

    const siteLookup = useSelector(SiteEntity.lookupTable);
    const selectedSiteId = useSelector(ProductionEntity.siteId);
    const selectedSite = _.get(siteLookup, selectedSiteId || "", null);

    const [pB, setPB] = useState<number>(0);
    const [pL, setPL] = useState<number>(0);
    const [pS, setPS] = useState<number>(0);
    const [pD, setPD] = useState<number>(0);
    const [dataCleared, setDataCleared] = useState(false);

    const district = useSelector(UserEntity.getDistrict);

    // clear data on close
    useEffect(() => {
        if (createStatus === "closed" && !dataCleared) {
            setPB(0);
            setPL(0);
            setPS(0);
            setPD(0);
            setDataCleared(true);
        }
    }, [createStatus, dataCleared, setDataCleared, setPB, setPL, setPS, setPD]);

    const addProdCount = () => {
        if (selectedDate !== null && siteId !== null) {
            dispatch(
                createProductionRecord({
                    dId: district.id,
                    sId: siteId,
                    dt: formatDate(selectedDate),
                    pB,
                    pL,
                    pS,
                    pD
                })
            );
            setDataCleared(false);
        }
    };

    const toggleDialog = () => {
        dispatch(createProductionRecordCancel());
        setDataCleared(false);
    };

    return (
        <BaseDialog
            label="create-production-count"
            title="New Production Record"
            open={createStatus !== "closed"}
            onClose={toggleDialog}
            actions={
                <DialogActions>
                    {createStatus === "fail" ? (
                        <Alert severity="error">Failed to create record</Alert>
                    ) : (
                        <div />
                    )}
                    <Button onClick={toggleDialog}>close</Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={addProdCount}
                    >
                        {createStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Add"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Use the form below to enter a production count
            </DialogContentText>

            <Alert severity="info" style={{ maxWidth: 360, marginBottom: 18 }}>
                Please note that you will replace any production count
                previously entered for the day
            </Alert>

            <form className={styles.form}>
                <div style={{ marginBottom: 8 }}>
                    Date: {formatDate(selectedDate || new Date())}
                </div>
                <InputNumber
                    label="Breakfast Count"
                    value={pB}
                    onChange={setPB}
                    hidden={
                        !isMealTypeEnabled(selectedSite, MealType.Breakfast)
                    }
                />
                <InputNumber
                    label="Lunch Count"
                    value={pL}
                    onChange={setPL}
                    hidden={!isMealTypeEnabled(selectedSite, MealType.Lunch)}
                />
                <InputNumber
                    label="Snack Count"
                    value={pS}
                    onChange={setPS}
                    hidden={!isMealTypeEnabled(selectedSite, MealType.Snack)}
                />
                <InputNumber
                    label="Dinner Count"
                    value={pD}
                    onChange={setPD}
                    hidden={!isMealTypeEnabled(selectedSite, MealType.Dinner)}
                />
            </form>
        </BaseDialog>
    );
};

export default CreateProductionCount;
