import { ItemsStatus, IUserInfo, ActionStatus } from "model/entity";
import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
    userLoggedOut,
    userAuthenticated,
    setUserGroups,
    setUserRegIncomplete,
    setUserRegComplete,
    registerNewUser,
    registerNewUserSuccess,
    registerNewUserFail,
    loadUserDistrictSuccess,
    updateUserSuccess,
    storeUserAttributes,
    loadPendingUsers,
    loadPendingUsersSuccess,
    loadPendingUsersFail,
    approveAccountSuccess,
    deleteUserSuccess,
    loadDistrictUsers,
    loadDistrictUsersSuccess,
    loadDistrictUsersFail,
    updateUserInit,
    updateUserFail,
    updateUser,
    updateUserCancel,
    fetchUserGroup,
    fetchUserGroupSuccess,
    fetchUserGroupFail,
    fetchUserGroupStatusReset,
    addUserToGroup,
    addUserToGroupSuccess,
    addUserToGroupFail,
    removeUserFromGroup,
    removeUserFromGroupFail,
    removeUserFromGroupSuccess,
    resetUpdateUserGroupStatus,
    deleteUserInit,
    deleteUser,
    deleteUserFail,
    deleteUserCancel
} from "control/actions";
import _ from "lodash";
import { CognitoGroup } from "control/API";

export enum AuthStatus {
    Unauthenticated = "Unauthenticated",
    UserAuthenticated = "UserAuthenticated"
}

export interface UserState {
    authStatus: AuthStatus;
    attributes: any;
    userGroups: string[];
    userRegStatus: "init" | "complete" | "pending" | "in-progress" | "failed";
    updateUserStatus: "closed" | "open" | "fail" | "success" | "in-progress";
    deleteUserStatus: ActionStatus;
    accountUserGroups: {
        accountId: string;
        groups: CognitoGroup[];
    } | null;
    userGroupStatus: "init" | "in-progress" | "success" | "fail";
    updateUserGroupStatus: "init" | "in-progress" | "success" | "fail";
    selectedUser: IUserInfo | null;
    userData: any;
    district: any;
    pendingUsers: IUserInfo[];
    pendingUsersStatus: ItemsStatus;
    districtUsers: IUserInfo[];
    districtUsersStatus: ItemsStatus;
}

const initialState: UserState = {
    authStatus: AuthStatus.Unauthenticated,
    attributes: {},
    userGroups: [],
    userRegStatus: "init",
    updateUserStatus: "closed",
    deleteUserStatus: "closed",
    userGroupStatus: "init",
    updateUserGroupStatus: "init",
    accountUserGroups: null,
    selectedUser: null,
    userData: {},
    district: null,
    pendingUsers: [],
    districtUsers: [],
    pendingUsersStatus: "initial",
    districtUsersStatus: "initial"
};

export const userReducer = createReducer(initialState, {
    [userAuthenticated.toString()]: state =>
        produce(state, draft => {
            draft.authStatus = AuthStatus.UserAuthenticated;
        }),
    [storeUserAttributes.toString()]: (state, action) =>
        produce(state, draft => {
            draft.attributes = action.payload;
        }),
    [setUserGroups.toString()]: (state, action) =>
        produce(state, draft => {
            draft.userGroups = action.payload;
        }),
    [setUserRegIncomplete.toString()]: state =>
        produce(state, draft => {
            draft.userRegStatus = "pending";
        }),
    [setUserRegComplete.toString()]: (state, action) =>
        produce(state, draft => {
            draft.userRegStatus = "complete";
            draft.userData = action.payload;
        }),
    [registerNewUser.toString()]: state =>
        produce(state, draft => {
            draft.userRegStatus = "in-progress";
        }),
    [registerNewUserSuccess.toString()]: state =>
        produce(state, draft => {
            draft.userRegStatus = "complete";
        }),
    [registerNewUserFail.toString()]: state =>
        produce(state, draft => {
            draft.userRegStatus = "failed";
        }),
    [loadUserDistrictSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.district = action.payload;
        }),
    // fetch group
    [fetchUserGroup.toString()]: state =>
        produce(state, draft => {
            draft.userGroupStatus = "in-progress";
            draft.accountUserGroups = null;
        }),
    [fetchUserGroupSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.userGroupStatus = "success";
            draft.accountUserGroups = action.payload;
        }),
    [fetchUserGroupFail.toString()]: state =>
        produce(state, draft => {
            draft.userGroupStatus = "fail";
        }),
    [fetchUserGroupStatusReset.toString()]: state =>
        produce(state, draft => {
            draft.userGroupStatus = "init";
        }),
    // update group
    [addUserToGroup.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "in-progress";
        }),
    [addUserToGroupFail.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "fail";
        }),
    [addUserToGroupSuccess.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "success";
        }),
    [removeUserFromGroup.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "in-progress";
        }),
    [removeUserFromGroupFail.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "fail";
        }),
    [removeUserFromGroupSuccess.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "success";
        }),
    [resetUpdateUserGroupStatus.toString()]: state =>
        produce(state, draft => {
            draft.updateUserGroupStatus = "init";
        }),
    // update user
    [updateUserInit.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateUserStatus = "open";
            draft.selectedUser = action.payload;
        }),
    [updateUser.toString()]: state =>
        produce(state, draft => {
            draft.updateUserStatus = "in-progress";
        }),
    [updateUserFail.toString()]: state =>
        produce(state, draft => {
            draft.updateUserStatus = "fail";
        }),
    [updateUserSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const userData = action.payload;
            if (draft.userData.cId === userData.cId) {
                draft.userData = action.payload;
            }
            draft.updateUserStatus = "success";
            draft.districtUsers = _.map(
                draft.districtUsers,
                dUser => dUser.cId === userData.cId ? userData : dUser
            );
        }),
    [updateUserCancel.toString()]: state =>
        produce(state, draft => {
            draft.updateUserStatus = "closed";
            draft.selectedUser = null;
        }),
    // Delete User
    [deleteUserInit.toString()]: (state, action) =>
        produce(state, draft => {
            draft.selectedUser = action.payload;
            draft.deleteUserStatus = "open";
        }),
    [deleteUser.toString()]: (state, action) =>
        produce(state, draft => {
            draft.deleteUserStatus = "in-progress";
        }),
    [deleteUserSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.deleteUserStatus = "closed";
            draft.districtUsers = _.filter(
                draft.districtUsers,
                dUser => dUser.cId !== action.payload
            );
            draft.pendingUsers = _.filter(
                draft.pendingUsers,
                pUser => pUser.cId !== action.payload
            );
            draft.selectedUser = null;
        }),
    [deleteUserFail.toString()]: (state, action) =>
        produce(state, draft => {
            draft.deleteUserStatus = "fail";
        }),
    [deleteUserCancel.toString()]: state =>
        produce(state, draft => {
            draft.deleteUserStatus = "closed";
        }),
    // pending users
    [loadPendingUsers.toString()]: state =>
        produce(state, draft => {
            draft.pendingUsers = [];
            draft.pendingUsersStatus = "loading";
        }),
    [loadPendingUsersSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.pendingUsers = action.payload;
            draft.pendingUsersStatus = "success";
        }),
    [loadPendingUsersFail.toString()]: state =>
        produce(state, draft => {
            draft.pendingUsersStatus = "fail";
        }),
    // district users
    [loadDistrictUsers.toString()]: state =>
        produce(state, draft => {
            draft.districtUsers = [];
            draft.districtUsersStatus = "loading";
        }),
    [loadDistrictUsersSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.districtUsers = action.payload;
            draft.districtUsersStatus = "success";
        }),
    [loadDistrictUsersFail.toString()]: state =>
        produce(state, draft => {
            draft.districtUsersStatus = "fail";
        }),
    [approveAccountSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.pendingUsers = _.filter(
                draft.pendingUsers,
                user => user.cId !== action.payload
            );
        }),
    [userLoggedOut.toString()]: state => initialState
});
