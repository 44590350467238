import React, { useState } from "react";
import PageLayout from "view/components/PageLayout";
import Toolbar from "./Toolbar";
import EndOfDayTableView from "./EndOfDayTableView";
import { useSelector } from "react-redux";
import { ReportEntity } from "model/entity";
import { Alert } from "@material-ui/lab";

const EndOfDayPage: React.FC = () => {
    const status = useSelector(ReportEntity.endOfDayStatus);
    const [siteId, setSiteId] = useState<string | null>(null);

    return (
        <PageLayout
            toolbar={<Toolbar siteId={siteId} onChangeSite={setSiteId} />}
        >
            {status === "fail" ? (
                <Alert severity="error">
                    Unable to get the report at this time
                </Alert>
            ) : (
                <div />
            )}
            <EndOfDayTableView siteId={siteId} />
        </PageLayout>
    );
};

export default EndOfDayPage;
