import React from "react";
import PageLayout from "view/components/PageLayout";
import { Authenticator } from "aws-amplify-react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openPage } from "control/actions";
import LocationIcon from "@material-ui/icons/Place";

const LocationsButton: React.FC = () => {
    const dispatch = useDispatch();
    const openSites = () => {
        dispatch(openPage("/sites"));
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
                variant="outlined"
                color="secondary"
                onClick={openSites}
                aria-label="locations"
                style={{ marginTop: 8 }}
            >
                <LocationIcon />
                Locations
            </Button>
        </div>
    );
};

const LoginPage: React.FC = () => {
    return (
        <PageLayout>
            <Authenticator />

            <LocationsButton />
        </PageLayout>
    );
};

export default LoginPage;
