import { AppState } from "model/reducers";
import { createSelector } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export interface IServingReport {
    name: string;
    produced: number;
    numServChildren: number;
    numServAdult: number;
    remaining: number;
}

export interface IMealCountReport {
    sN: string;
    mT: string;
    nC: number;
    nA: number;
}

export class ReportEntity {
    public static endOfDay(appState: AppState) {
        return createSelector(
            (state: AppState) => state.report.endOfDay,
            items => cloneDeep(items)
        )(appState);
    }

    public static endOfDayStatus(appState: AppState) {
        return appState.report.endOfDayStatus;
    }

    public static mealCount(appState: AppState) {
        return createSelector(
            (state: AppState) => state.report.mealCount,
            items => cloneDeep(items)
        )(appState);
    }

    public static mealCountStatus(appState: AppState) {
        return appState.report.mealCountStatus;
    }

    public static monthlyReport(appState: AppState) {
        return createSelector(
            (state: AppState) => state.report.monthlyReport,
            items => cloneDeep(items)
        )(appState);
    }

    public static monthlyReportStatus(appState: AppState) {
        return appState.report.monthlyReportStatus;
    }

    public static districtWideReport(appState: AppState) {
        return createSelector(
            (state: AppState) => state.report.districtWideReport,
            items => cloneDeep(items)
        )(appState);
    }

    public static districtWideReportStatus(appState: AppState) {
        return appState.report.districtWideReportStatus;
    }
}
