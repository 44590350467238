import React from "react";
import DateSiteToolbar from "view/components/DateSiteToolbar";
import { useDispatch, useSelector } from "react-redux";
import {
    loadProductionCount,
    setProductionSiteId,
    setProductionDate
} from "control/actions";
import { ProductionEntity } from "model/entity/production.entity";

const ProductionToolbar: React.FC = () => {
    const siteId = useSelector(ProductionEntity.siteId);
    const currDate = useSelector(ProductionEntity.date);

    const dispatch = useDispatch();
    const setSiteId = (currId: string | null) => {
        if (currId) {
            dispatch(setProductionSiteId(currId));
        }
    };
    const setCurrDate = (updatedDate: Date | null) => {
        if (updatedDate) {
            dispatch(setProductionDate(updatedDate));
        }
    };

    const loadProduction = () => {
        if (currDate !== null && siteId !== null) {
            dispatch(loadProductionCount(currDate, siteId));
        }
    };

    return (
        <DateSiteToolbar
            date={currDate}
            siteId={siteId}
            onChangeDate={setCurrDate}
            onChangeSite={setSiteId}
            onClickApply={loadProduction}
        />
    );
};

export default ProductionToolbar;
