import React from "react";
import PageLayout from "view/components/PageLayout";
import PendingUsers from "./PendingUsers";

const UsersPage: React.FC = () => {
    return (
        <PageLayout>
            <PendingUsers />
        </PageLayout>
    );
};

export default UsersPage;
