import React from "react";
import BaseDialog from "view/components/BaseDialog";
import { useSelector, useDispatch } from "react-redux";
import { AccountEntity } from "model/entity";
import { updateUserCancel } from "control/actions";
import { Button, DialogActions, DialogContentText } from "@material-ui/core";
import UpdateRoleForm from "./UpdateRoleForm";
import UpdateAccessControlForm from "./UpdateAccessControlForm";
import UpdateDistrictForm from "./UpdateDistrictForm";

const UpdateAccount: React.FC = () => {
    const status = useSelector(AccountEntity.accountUpdateStatus);
    const selectedAccount = useSelector(AccountEntity.selectedAccount);
    const dispatch = useDispatch();

    const cancelUpdateUser = () => {
        dispatch(updateUserCancel());
    };

    return (
        <BaseDialog
            open={status !== "closed"}
            label="update-account"
            title="Update User"
            onClose={cancelUpdateUser}
            actions={
                <DialogActions>
                    <Button onClick={cancelUpdateUser}>Close</Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Use the form below to update user
                <b style={{ marginLeft: 4 }}>{selectedAccount?.email}</b>.
            </DialogContentText>

            <UpdateDistrictForm />
            <UpdateRoleForm />
            <UpdateAccessControlForm />
        </BaseDialog>
    );
};

export default UpdateAccount;
