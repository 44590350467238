import { loadSites } from "./../actions/site.actions";
import { initApp, loadDistricts } from "control/actions";
import { put, takeEvery } from "redux-saga/effects";

function* initAppSaga() {
    yield put(loadDistricts("only-active"));
    yield put(loadSites(undefined, true));
}

/**
 * Root saga is where all the sagas are merged
 */
export default function* appSagas() {
    yield takeEvery(initApp, initAppSaga);
}
