import { UserEntity } from "model/entity";
import {
    registerNewUser,
    setUserGroups,
    verifyUserReg,
    setUserRegIncomplete,
    setUserRegComplete,
    userAuthenticated,
    registerNewUserFail,
    registerNewUserSuccess,
    loadUserDistrict,
    loadUserDistrictFail,
    updateUser,
    updateUserFail,
    updateUserSuccess,
    storeUserAttributes,
    loadSites,
    openPage,
    getMonthlyReport,
    loadPendingUsers,
    loadPendingUsersSuccess,
    loadPendingUsersFail,
    deleteUser,
    deleteUserSuccess,
    deleteUserFail,
    loadDistricts,
    loadUserDistrictSuccess,
    approveAccount,
    approveAccountFail,
    approveAccountSuccess,
    loadDistrictUsers,
    loadDistrictUsersSuccess,
    loadDistrictUsersFail,
    fetchUserGroup,
    fetchUserGroupSuccess,
    fetchUserGroupFail,
    addUserToGroup,
    removeUserFromGroup,
    addUserToGroupFail,
    removeUserFromGroupFail,
    addUserToGroupSuccess,
    removeUserFromGroupSuccess
} from "control/actions";
import { Auth } from "aws-amplify";
import { put, takeEvery, select, delay } from "redux-saga/effects";
import logger from "util/logger";
import * as _ from "lodash";
import userTransport from "./transport/user.transport";
import districtTransport from "./transport/district.transport";
import { UserStatus, CognitoGroup } from "control/API";
import { PayloadAction } from "@reduxjs/toolkit";

/**
 * Cognito users must be part of the DynamoDB,
 * check if the user info exists within the app
 */
function* verifyUserRegAndLoadData(action: any) {
    try {
        const userInfo = yield userTransport.getUser(action.payload.username);
        if (_.isNull(userInfo)) {
            yield put(setUserRegIncomplete());
            yield put(openPage("/pending-approval"));
            return;
        }

        if (userInfo.pending === UserStatus.PendingApproval) {
            yield put(openPage("/pending-approval"));
        }

        yield put(setUserRegComplete(userInfo));
        yield put(loadUserDistrict(userInfo.dId));
    } catch (err) {
        logger.error(err);
    }
}

function* loadUserProfile() {
    const user = yield Auth.currentAuthenticatedUser();
    const userGroups = _.get(
        user,
        'signInUserSession.accessToken.payload["cognito:groups"]',
        []
    );
    yield put(setUserGroups(userGroups));
    yield put(storeUserAttributes(user.attributes));

    yield put(verifyUserReg(user));

    // default pages for different roles
    const isAdmin = yield select(UserEntity.isAdmin);
    const isSuperAdmin = yield select(UserEntity.isSuperAdmin);
    if (isAdmin) {
        yield put(openPage("/"));
    } else {
        yield put(openPage("/sites"));
    }

    if (isSuperAdmin) {
        yield put(loadDistricts("all"));
        yield put(loadPendingUsers());
    }
}

function* newUserSetupProcess(action: any) {
    try {
        const currUser = yield Auth.currentUserInfo();

        const regUser = yield userTransport.createUser({
            cId: currUser.username,
            dId: action.payload.districtId,
            email: currUser.attributes.email,
            pH: currUser.attributes.phone_number,
            pending: UserStatus.PendingApproval
        });
        yield put(registerNewUserSuccess(regUser.userId, regUser.districtId));
    } catch (err) {
        logger.error(err);
        yield put(registerNewUserFail());
    }
}

function* loadUserDistrictProcess(action: any) {
    try {
        const data = yield districtTransport.getDistrict({
            id: action.payload
        });

        if (_.isEmpty(data)) {
            return put(loadUserDistrictFail());
        } else {
            yield put(loadUserDistrictSuccess(data));
            const districtId = data.id;
            yield put(loadSites(districtId));
            yield put(getMonthlyReport());
        }
    } catch (err) {
        logger.error(err);
        yield put(loadUserDistrictFail());
    }
}

function* updateUserProcess(action: any) {
    try {
        const updated = yield userTransport.updateUser(action.payload);
        yield put(updateUserSuccess(updated));
    } catch (err) {
        logger.error(err);
        yield put(updateUserFail());
    }
}

function* loadPendingUsersSaga() {
    try {
        const users = yield userTransport.listPendingUsers();
        yield put(loadPendingUsersSuccess(users));
    } catch (err) {
        logger.error(err);
        yield put(loadPendingUsersFail());
    }
}

function* deleteUserSaga(action: PayloadAction<string>) {
    try {
        yield userTransport.deleteAccount({
            accountId: action.payload
        });
        yield put(deleteUserSuccess(action.payload));
    } catch (err) {
        yield put(deleteUserFail());
        logger.error(err);
    }
}

function* approveAccountSaga(action: any) {
    const accountId = action.payload;
    try {
        yield userTransport.addUserToGroup({
            accountId,
            groupName: CognitoGroup.SiteAdmin
        });
        yield userTransport.updateUser({
            cId: accountId,
            pending: null
        });
        yield put(approveAccountSuccess(accountId));
    } catch (err) {
        logger.error(err);
        yield put(approveAccountFail(accountId));
    }
}

function* loadDistrictUsersSaga(action: PayloadAction<string>) {
    try {
        const districtId = action.payload;
        const users = yield userTransport.listDistrictUsers(districtId);
        yield put(loadDistrictUsersSuccess(users));
    } catch (err) {
        logger.error(err);
        yield put(loadDistrictUsersFail());
    }
}

function* fetchUserGroupSaga(action: PayloadAction<string>) {
    try {
        const accountId = action.payload;
        const groups = yield userTransport.fetchUserGroups({
            accountId
        });
        yield put(fetchUserGroupSuccess(accountId, groups));
    } catch (err) {
        logger.error(err);
        yield put(fetchUserGroupFail());
    }
}

function* addUserToGroupSaga(action: PayloadAction<{
    accountId: string;
    groupName: CognitoGroup;
}>) {
    try {
        userTransport.addUserToGroup({
            accountId: action.payload.accountId,
            groupName: action.payload.groupName
        });
        yield delay(1000);
        yield put(fetchUserGroup(action.payload.accountId));
        yield put(addUserToGroupSuccess());
    } catch (err) {
        yield put(addUserToGroupFail());
        logger.error(err);
    }
}

function* removeUserFromGroupSaga(action: PayloadAction<{
    accountId: string;
    groupName: CognitoGroup;
}>) {
    try {
        userTransport.removeUserFromGroup({
            accountId: action.payload.accountId,
            groupName: action.payload.groupName
        });
        yield delay(1000);
        yield put(fetchUserGroup(action.payload.accountId));
        yield put(removeUserFromGroupSuccess());
    } catch (err) {
        yield put(removeUserFromGroupFail());
        logger.error(err);
    }
}

/**
 * Root saga is where all the sagas are merged
 */
export default function* authSagas() {
    yield takeEvery(registerNewUser, newUserSetupProcess);
    yield takeEvery(userAuthenticated, loadUserProfile);
    yield takeEvery(verifyUserReg, verifyUserRegAndLoadData);
    yield takeEvery(loadUserDistrict, loadUserDistrictProcess);
    yield takeEvery(updateUser, updateUserProcess);
    yield takeEvery(loadPendingUsers, loadPendingUsersSaga);
    yield takeEvery(approveAccount, approveAccountSaga);
    yield takeEvery(loadDistrictUsers, loadDistrictUsersSaga);
    yield takeEvery(fetchUserGroup, fetchUserGroupSaga);
    yield takeEvery(addUserToGroup, addUserToGroupSaga);
    yield takeEvery(removeUserFromGroup, removeUserFromGroupSaga);
    yield takeEvery(deleteUser, deleteUserSaga);
}
