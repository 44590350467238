import React from "react";
import {
    makeStyles,
    createStyles,
    AppBar,
    Toolbar,
    Fab
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { UserEntity, RouterEntity } from "model/entity";
import LogoutIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import LocationIcon from "@material-ui/icons/Place";
import { openPage, userLogoutInit, toggleNavbar } from "control/actions";
import Logo from "./Logo";

const useStyles = makeStyles(theme =>
    createStyles({
        button: {
            margin: "0px 2px"
        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between"
        },
        section: {
            margin: "12px 0px",
            display: "flex",
            alignItems: "center"
        }
    })
);

const LogoutButton: React.FC = () => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const dispatchLogoutUser = () => {
        dispatch(userLogoutInit());
    };

    return (
        <IconButton
            className={styles.button}
            edge="start"
            color="inherit"
            aria-label="logout"
            onClick={dispatchLogoutUser}
        >
            <LogoutIcon />
        </IconButton>
    );
};

const MenuButton: React.FC = () => {
    const dispatch = useDispatch();
    const dispatchOpen = () => {
        dispatch(toggleNavbar());
    };
    const isAuthenticated = useSelector(UserEntity.isAuthenticated);
    const isPendingApproval = useSelector(UserEntity.isPendingApproval);

    if (!isAuthenticated || isPendingApproval) {
        return <div />;
    }

    return (
        <Fab
            onClick={dispatchOpen}
            size="medium"
            color="primary"
            aria-label="menu"
            style={{ marginRight: 8 }}
        >
            <MenuIcon />
        </Fab>
    );
};

const LocationsButton: React.FC = () => {
    const dispatch = useDispatch();
    const openSites = () => {
        dispatch(openPage("/sites"));
    };

    return (
        <IconButton
            onClick={openSites}
            edge="start"
            color="inherit"
            aria-label="locations"
        >
            <LocationIcon />
        </IconButton>
    );
};

const MAppBar: React.FC = () => {
    const styles = useStyles();
    const isAuthenticated = useSelector(UserEntity.isAuthenticated);
    const pathName = useSelector(RouterEntity.pathName);

    // Hide the app bar because it overlaps with messages from Amplify Authenticator
    // component
    const isLoginPage = pathName === "/login";
    if (isLoginPage) {
        return <div />
    }

    return (
        <AppBar position={isAuthenticated ? "fixed" : "absolute"}>
            <Toolbar className={styles.toolbar}>
                {/* left section */}
                <div className={styles.section}>
                    <MenuButton />
                    <Logo />
                </div>

                {/* right section */}
                {isAuthenticated ? (
                    <div className={styles.section}>
                        <LogoutButton />
                    </div>
                ) : (
                    <div className={styles.section}>
                        <LocationsButton />
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default MAppBar;
