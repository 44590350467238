import { ActionStatus } from "model/entity";
import { IProduction } from "./../entity/production.entity";
import { ItemsStatus } from "./../entity/ui.entity";
import {
    setProductionSiteId,
    setProductionDate,
    loadProductionCount,
    loadProductionCountFail,
    loadProductionCountSuccess,
    createProductionRecordOpen,
    createProductionRecord,
    createProductionRecordSuccess,
    createProductionRecordFail,
    createProductionRecordCancel,
    updateProductionRecordOpen,
    updateProductionRecord,
    updateProductionRecordSuccess,
    updateProductionRecordFail,
    updateProductionRecordCancel
} from "control/actions";
import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { map } from "lodash";

export interface ProductionState {
    siteId: string | null;
    date: Date | null;
    items: { [sId: string]: any };
    status: ItemsStatus;
    createStatus: ActionStatus;
    updateStatus: ActionStatus;
    editingItem: IProduction | null;
}

const initialState: ProductionState = {
    siteId: null,
    date: new Date(),
    items: {},
    status: "initial",
    createStatus: "closed",
    updateStatus: "closed",
    editingItem: null
};

const getId = (item: IProduction): string => `${item.sId}#${item.dt}`;

const productionReducer = createReducer(initialState, {
    [setProductionDate.toString()]: (state, action) =>
        produce(state, draft => {
            draft.date = action.payload;
        }),
    [setProductionSiteId.toString()]: (state, action) =>
        produce(state, draft => {
            draft.siteId = action.payload;
        }),

    // Items
    [loadProductionCount.toString()]: state =>
        produce(state, draft => {
            draft.status = "loading";
            draft.items = {};
        }),
    [loadProductionCountSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            map(action.payload, item => {
                draft.items[getId(item)] = item;
            });
            draft.status = "success";
        }),
    [loadProductionCountFail.toString()]: state =>
        produce(state, draft => {
            draft.status = "fail";
        }),

    // Create
    [createProductionRecordOpen.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "open";
        }),
    [createProductionRecord.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "in-progress";
        }),
    [createProductionRecordSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.items[getId(item)] = item;
            draft.createStatus = "closed";
        }),
    [createProductionRecordFail.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "fail";
        }),
    [createProductionRecordCancel.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "closed";
        }),

    // Update
    [updateProductionRecordOpen.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "open";
            draft.editingItem = action.payload;
        }),
    [updateProductionRecord.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "in-progress";
        }),
    [updateProductionRecordSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.items[getId(item)] = item;
            draft.updateStatus = "closed";
        }),
    [updateProductionRecordFail.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "fail";
        }),
    [updateProductionRecordCancel.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "closed";
        })
});

export default productionReducer;
