import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector } from "react-redux";
import { ReportEntity } from "model/entity";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const EndOfDayTableView: React.FC<{
    siteId: string | null;
}> = props => {
    const items = useSelector(ReportEntity.endOfDay);
    const status = useSelector(ReportEntity.endOfDayStatus);

    const [includeAdultMealCount, setIncAdultMeals] = useState(true);

    const updateIncludeAdultMealCount = (event: any, checked: boolean) => {
        setIncAdultMeals(checked);
    }

    return (
        <MaterialTable
            isLoading={status === "loading"}
            columns={[
                {
                    title: "Meal Type",
                    field: "name",
                    cellStyle: (value: any) => {
                        return value === "Total"
                            ? {
                                  fontWeight: "bold"
                              }
                            : {};
                    }
                },
                {
                    title: "Produced",
                    field: "produced",
                    type: "numeric",
                    hidden: !includeAdultMealCount
                },
                {
                    title: "Served 18 and under",
                    field: "numServChildren",
                    type: "numeric"
                },
                {
                    title: "Adult",
                    field: "numServAdult",
                    type: "numeric",
                    hidden: !includeAdultMealCount
                },
                {
                    title: "Remaining",
                    field: "remaining",
                    type: "numeric",
                    hidden: !includeAdultMealCount
                }
            ]}
            data={items}
            options={{
                exportButton: true,
                search: false,
                paging: false,
                sorting: false,
                rowStyle: rowData =>
                    rowData.name === "Total"
                        ? {
                              background: "rgba(0,0,0,0.05)"
                          }
                        : {}
            }}
            title="End of Day Report"
            components={{
                Toolbar: tProps => (
                    <div>
                        <MTableToolbar {...tProps} />
                        <FormControlLabel
                            style={{ marginLeft: 8 }}
                            control={
                                <Checkbox
                                    checked={includeAdultMealCount}
                                    onChange={updateIncludeAdultMealCount}
                                    name="child meal count"
                                />
                            }
                            label="Include Adult Meals"
                        />
                    </div>
                )
            }}
        />
    );
};

export default EndOfDayTableView;
