import React from "react";
import PageLayout from "view/components/PageLayout";
import DistrictsTableView from "./DistrictsTableView";
import CreateDistrict from "./CreateDistrict";
import EditDistrict from "./UpdateDistrict";
import { useSelector } from "react-redux";
import { DistrictsEntity } from "model/entity/districts.entity";
import { Alert } from "@material-ui/lab";

const ErrorMessage: React.FC = () => {
    const status = useSelector(DistrictsEntity.status);
    return status === "fail" ? (
        <Alert severity="error">Error loading districts, try reloading</Alert>
    ) : (
        <div />
    );
};

const ListDistricts: React.FC = () => {
    return (
        <PageLayout>
            <ErrorMessage />
            <DistrictsTableView />
            <CreateDistrict />
            <EditDistrict />
        </PageLayout>
    );
};

export default ListDistricts;
