import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import { Paper } from "@material-ui/core";
import BottomSheet from "./BottomSheet";
import NavBar from "view/components/NavBar";

interface IPageLayoutProps {
    toolbar?: React.ReactElement;
    sections?: React.ReactElement[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            background: theme.palette.background.default,
            padding: 12,
            marginTop: 12,
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
                marginTop: 0
            }
        },
        page: {
            height: "inherit",
            width: 1024,
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                width: "96vw"
            }
        },
        mainContent: {
            height: "inherit",
            width: (props: IPageLayoutProps) =>
                isEmpty(props.sections) ? "100%" : 750,
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        sideSection: {
            width: (props: IPageLayoutProps) =>
                isEmpty(props.sections) ? 0 : 274,
            marginLeft: 8,
            [theme.breakpoints.down("sm")]: {
                display: "none"
            },
            height: "fit-content"
        }
    })
);

const PageLayout: React.FC<IPageLayoutProps> = props => {
    const styles = useStyles(props);

    return (
        <div className={styles.container}>
            <NavBar />
            <div className={styles.page}>
                <div className={styles.mainContent}>
                    {props.toolbar}
                    {props.children}
                </div>

                <Paper className={styles.sideSection}>
                    {props.sections?.map((section, index) =>
                        React.cloneElement(section, { key: index })
                    )}
                </Paper>
            </div>
            <BottomSheet sections={props.sections} />
        </div>
    );
};

export default PageLayout;
