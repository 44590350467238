import React, { useState } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isFuture } from "date-fns";

const SelectDate: React.FC<{
    id: string;
    value: Date | null;
    label?: string;
    onChange: (date: Date | null) => void;
    readOnly?: boolean;
    disableFutureDates?: boolean;
}> = props => {

    const [isErr, setErr] = useState(false);

    const onChange = (value: any) => {
        if (isFuture(value)) {
            setErr(true);
            return;
        }
        props.onChange(value);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar={true}
                variant="dialog"
                autoOk={true}
                format="MM/dd/yyyy"
                margin="normal"
                id={props.id}
                label={props.label || "Select Date"}
                value={props.value}
                onChange={onChange}
                error={isErr}
                KeyboardButtonProps={{
                    "aria-label": props.label || "select date"
                }}
                InputProps={{
                    readOnly: props.readOnly || false
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default SelectDate;
