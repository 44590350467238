import { UserEntity } from "model/entity";
import {
    loadServings,
    loadServingsSuccess,
    loadServingsFail,
    createServingInit,
    createServingSuccess,
    createServingFail,
    updateServingInit,
    updateServingSuccess,
    updateServingFail,
    loadServingsForPhoneInit,
    loadServingsForPhoneSuccess,
    loadServingsForPhoneFail,
    deleteServingInit,
    deleteServingSuccess,
    deleteServingFail
} from "control/actions";
import { takeEvery, put, select } from "redux-saga/effects";
import logger from "util/logger";
import servingTransport from "./transport/serving.transport";

function* loadServingsSaga(action: any) {
    try {
        const payload = action.payload;
        const district = yield select(UserEntity.getDistrict);
        const items = yield servingTransport.listServingsForSite({
            districtId: district.id,
            siteId: payload.siteId,
            date: payload.date
        });
        yield put(loadServingsSuccess(items));
    } catch (err) {
        yield put(loadServingsFail());
        logger.error(err);
    }
}

function* createServingSaga(action: any) {
    try {
        const resp: any = yield servingTransport.createServing(action.payload);
        yield put(createServingSuccess(resp));
    } catch (err) {
        yield put(createServingFail());
        logger.error(err);
    }
}

function* updateServingSaga(action: any) {
    try {
        const resp: any = yield servingTransport.updateServing(action.payload);
        yield put(updateServingSuccess(resp));
    } catch (err) {
        logger.error(err);
        yield put(updateServingFail());
    }
}

function* loadServingsForPhoneSaga(action: any) {
    try {
        const district = yield select(UserEntity.getDistrict);
        const items = yield servingTransport.listServingsForPhone({
            districtId: district.id,
            date: action.payload.date,
            phNum: action.payload.phNum
        });
        yield put(loadServingsForPhoneSuccess(items));
    } catch (err) {
        logger.error(err);
        yield put(loadServingsForPhoneFail());
    }
}

function* deleteServingSaga(action: any) {
    try {
        const item = yield servingTransport.deleteServing(action.payload);
        yield put(deleteServingSuccess(item));
    } catch (err) {
        logger.error(err);
        yield put(deleteServingFail());
    }
}

// Interactions with the
export default function* servingSagas() {
    yield takeEvery(loadServings, loadServingsSaga);
    yield takeEvery(createServingInit, createServingSaga);
    yield takeEvery(updateServingInit, updateServingSaga);
    yield takeEvery(deleteServingInit, deleteServingSaga);
    yield takeEvery(loadServingsForPhoneInit, loadServingsForPhoneSaga);
}
