import React from "react";
import { TextField } from "@material-ui/core";

const SiteName: React.FC<{
    value: string;
    onChange: (name: string) => void;
}> = props => {
    const onChange = (event: any) => {
        props.onChange(event.target.value);
    };
    return (
        <TextField
            id="site-name"
            style={{ width: "100%" }}
            value={props.value}
            label="Location Name"
            variant="outlined"
            onChange={onChange}
        />
    );
};

export default SiteName;
