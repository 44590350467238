import React from "react";
import { RouteProps, Route, Redirect } from "react-router";
import { useSelector } from "react-redux";
import { UserEntity } from "model/entity";
import { CognitoGroup } from "control/API";

export interface IRouteWithSubRoutesProps extends RouteProps {
    // child routes
    routes?: RouteProps[];

    // for role authorization
    accessGroup?: CognitoGroup[];
}

// TODO: The router props must be adjusted for child routes
// `any` type here is used for ignoring the type errors
const RouteWithSubRoutes: React.FC<RouteProps> = (route: any) => {
    const isAuthenticated = useSelector(UserEntity.isAuthenticated);
    const isUserPending = useSelector(UserEntity.isPendingApproval);
    const isAuthorized = useSelector(UserEntity.isAuthorized(route.accessGroup));


    // authentication checks
    if (route.accessGroup && route.accessGroup.length !== 0 && !isAuthenticated) {
        return <Redirect to="/login" />;
    }

    // authorization checks
    if (route.accessGroup && route.accessGroup.length !== 0 && !isAuthorized) {
        return <Redirect to="/not-authorized" />;
    }

    // pending accounts check
    if (isUserPending) {
        return <Redirect to="/pending-approval" />;
    }

    const renderChildRoute = (props: any) => (
        <route.component {...props} routes={route.routes} />
    );

    return <Route path={route.path} render={renderChildRoute} />;
};

export default RouteWithSubRoutes;
