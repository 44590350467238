import { AppState } from "model/reducers";

export enum Page {
    // user
    SignUp = "sign-up",
    MyProfile = "my-profile",
    Login = "login",

    // district
    CreateDistrict = "new-district"
}

const toPageName = (pathName: string) => {
    switch (pathName) {
        case "/sign-up":
            return "Sign Up";

        case "/my-profile":
            return "My Profile";

        case "/":
            return "Dashboard";

        default:
            return "";
    }
};

export class RouterEntity {
    public static pathName(state: AppState): string {
        return state.router.location.pathname;
    }

    public static pageName(state: AppState): string {
        return toPageName(state.router.location.pathname);
    }
}
