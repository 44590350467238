import React, { useState, useEffect } from "react";
import { IHoursOfOperation, ITimeFrame } from "model/entity/hop.entity";
import {
    FormControlLabel,
    Checkbox,
    makeStyles,
    FormControl
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { isNull } from "lodash";
import SelectTime from "./SelectTime";

const useStyles = makeStyles({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        margin: 8
    },
    hopContainer: {
        display: "flex",
        flexDirection: "column"
    },
    timeFrameContainer: {
        display: "flex",
        alignItems: "center"
    },
    timePicker: {}
});

const stringToDate = (input: string) => {
    const utils = new DateFnsUtils();
    return utils.parse(input, "HH:mm");
};

const dateToString = (input: Date) => {
    const utils = new DateFnsUtils();
    return utils.format(input, "HH:mm");
};

const DayAndTime: React.FC<{
    label: string;
    value: ITimeFrame | null;
    onChange: (tf: ITimeFrame | null) => void;
}> = props => {
    const styles = useStyles();
    const [isChecked, setIsChecked] = useState(!isNull(props.value));
    const [startTime, setStartTime] = useState<Date | null>(
        props.value ? stringToDate(props.value.s) : null
    );
    const [endTime, setEndTime] = useState<Date | null>(
        props.value ? stringToDate(props.value.e) : null
    );

    useEffect(() => {
        setStartTime(props.value ? stringToDate(props.value.s) : null);
        setEndTime(props.value ? stringToDate(props.value.e) : null);
    }, [props.value, setStartTime, setEndTime]);

    const enableTimeFrame = (event: any, checked: boolean) => {
        setIsChecked(checked);

        // if unchecked disable the day
        if (!checked) {
            props.onChange(null);
        } else {
            props.onChange({
                s: "09:00",
                e: "17:00"
            });
        }
    };

    const updateStartTime = (input: Date | null) => {
        setStartTime(input);

        if (input && props.value) {
            props.onChange({
                s: dateToString(input),
                e: props.value.e
            });
        }
    };

    const updateEndTime = (input: Date | null) => {
        setEndTime(input);

        if (input && props.value) {
            props.onChange({
                s: props.value.s,
                e: dateToString(input)
            });
        }
    };

    return (
        <FormControl variant="outlined" className={styles.container}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        name={props.label}
                        onChange={enableTimeFrame}
                        color="primary"
                    />
                }
                style={{ width: 130 }}
                label={props.label}
            />

            <SelectTime
                id={`${props.label}-select-time`}
                label="Start Time"
                value={startTime}
                onChange={updateStartTime}
                disabled={!isChecked}
            />

            <SelectTime
                id={`${props.label}-end-time`}
                label="End Time"
                value={endTime}
                onChange={updateEndTime}
                disabled={!isChecked && !props.value}
            />
        </FormControl>
    );
};

const HoursOfOperation: React.FC<{
    value: IHoursOfOperation;
    onChange: (hop: IHoursOfOperation) => void;
}> = ({ value, onChange }) => {
    const styles = useStyles();

    const setMonday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            m: tf
        });
    };

    const setTuesday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            t: tf
        });
    };

    const setWednesday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            w: tf
        });
    };

    const setThursday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            th: tf
        });
    };

    const setFriday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            f: tf
        });
    };

    const setSaturday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            st: tf
        });
    };

    const setSunday = (tf: ITimeFrame | null) => {
        onChange({
            ...value,
            su: tf
        });
    };

    return (
        <div className={styles.hopContainer}>
            <DayAndTime label="Monday" value={value.m} onChange={setMonday} />
            <DayAndTime label="Tuesday" value={value.t} onChange={setTuesday} />
            <DayAndTime
                label="Wednesday"
                value={value.w}
                onChange={setWednesday}
            />
            <DayAndTime
                label="Thursday"
                value={value.th}
                onChange={setThursday}
            />
            <DayAndTime label="Friday" value={value.f} onChange={setFriday} />
            <DayAndTime
                label="Saturday"
                value={value.st}
                onChange={setSaturday}
            />
            <DayAndTime label="Sunday" value={value.su} onChange={setSunday} />
        </div>
    );
};

export default HoursOfOperation;
