import { includes } from "lodash";
import { MealType } from "control/API";
import { ISite } from ".";

export const isMealTypeEnabled = (
    site: ISite | null,
    mealType: MealType
): boolean => {
    return includes(site ? site.meals : {}, mealType);
};
