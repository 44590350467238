import { CreateSiteInput, UpdateSiteInput, DeleteSiteInput } from "control/API";
import { createAction } from "@reduxjs/toolkit";
import { ISite } from "model/entity";

// Items
export const loadSites = createAction(
    "LOAD_SITES",
    (districtId?: string, onlyActive?: boolean) => ({
        payload: {
            districtId,
            onlyActive
        }
    })
);
export const loadSitesSuccess = createAction(
    "LOAD_SITES_SUCCESS",
    (items: ISite[]) => ({
        payload: items
    })
);
export const loadSitesFail = createAction("LOAD_SITES_FAIL");

// Create
export const createSiteOpen = createAction("CREATE_SITE_OPEN");
export const createSiteInit = createAction(
    "CREATE_SITE_INIT",
    (input: CreateSiteInput) => ({
        payload: input
    })
);
export const createSiteSuccess = createAction(
    "ADD_SITE_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const createSiteFail = createAction("ADD_SITE_FAIL");
export const createSiteCancel = createAction("CREATE_SITE_CANCEL");

// Update
export const updateSiteOpen = createAction(
    "UPDATE_SITE_OPEN",
    (site: ISite) => ({
        payload: site
    })
);
export const updateSiteInit = createAction(
    "UPDATE_SITE_INIT",
    (input: UpdateSiteInput) => ({
        payload: input
    })
);
export const updateSiteSuccess = createAction(
    "UPDATE_SITE_SUCCESS",
    (data: ISite) => ({
        payload: data
    })
);
export const updateSiteFail = createAction("UPDATE_SITE_FAIL");
export const updateSiteClose = createAction("UPDATE_SITE_CLOSE");

// Delete
export const deleteSite = createAction(
    "DELETE_SITE",
    (input: DeleteSiteInput) => ({
        payload: input
    })
);
export const deleteSiteSuccess = createAction(
    "DELETE_SITE_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const deleteSiteFail = createAction("DELETE_SITE_FAIL");
