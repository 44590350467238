import { takeEvery, put } from "redux-saga/effects";
import siteTransport from "./transport/site.transport";
import logger from "util/logger";
import {
    loadSites,
    loadSitesSuccess,
    loadSitesFail,
    createSiteInit,
    createSiteSuccess,
    createSiteFail,
    updateSiteInit,
    updateSiteSuccess,
    updateSiteFail
} from "control/actions";

function* loadSitesSaga(action: any) {
    try {
        const data = yield siteTransport.listSites(
            action.payload.districtId,
            action.payload.onlyActive
        );
        yield put(loadSitesSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(loadSitesFail());
    }
}

function* createSiteSaga(action: any) {
    try {
        const site = yield siteTransport.createSite(action.payload);
        yield put(createSiteSuccess(site));
    } catch (err) {
        logger.error(err);
        yield put(createSiteFail());
    }
}

function* updateSiteSaga(action: any) {
    try {
        const updated = yield siteTransport.updateSite(action.payload);
        yield put(updateSiteSuccess(updated));
    } catch (err) {
        logger.error(err);
        yield put(updateSiteFail());
    }
}

export default function* siteSaga() {
    yield takeEvery(loadSites, loadSitesSaga);
    yield takeEvery(createSiteInit, createSiteSaga);
    yield takeEvery(updateSiteInit, updateSiteSaga);
}
