import React from "react";
import { makeStyles, Typography, Link } from "@material-ui/core";
import { ISite, addrToUrl } from "model/entity";

const useStyles = makeStyles({
    container: {
        minWidth: 200
    },
    address: {
        paddingTop: 6
    }
});

const LocationName: React.FC<{
    site: ISite;
}> = props => {
    const site = props.site;
    const styles = useStyles();
    const url = addrToUrl(site.addr);

    return (
        <div className={styles.container}>
            <b>{site.name}</b>
            <address className={styles.address}>
                {site.addr.ln1},<br />
                {site.addr.ln2 ? (
                    <span>
                        {site.addr.ln2},
                        <br />
                    </span>
                ) : (
                    <span />
                )}
                {site.addr.cty}, {site.addr.st} - {site.addr.zip}
            </address>

            <span hidden={!site.stCd}>
                <b>Site Code:</b> - {site.stCd}
            </span>

            <Typography>
                <Link href={url} target="_blank">
                    Open in Maps
                </Link>
            </Typography>
        </div>
    );
};

export default LocationName;
