import React from "react";
import SeparatorLabel from "view/components/SeparatorLabel";
import InputNumber from "view/components/InputNumber";
import { makeStyles } from "@material-ui/core";
import { MealType } from "control/API";
import { useSelector } from "react-redux";
import { SiteEntity, isMealTypeEnabled, ServingEntity } from "model/entity";
import _ from "lodash";

const useStyles = makeStyles({
    field: {
        margin: "8px 0px"
    }
});

const MealEntry: React.FC<{
    mealType: MealType;
    childValue: number;
    adultValue: number;
    onChangeChildValue: (value: number) => void;
    onChangeAdultValue: (value: number) => void;
}> = props => {
    const styles = useStyles();
    const siteLookup = useSelector(SiteEntity.lookupTable);
    const selectedSiteId = useSelector(ServingEntity.selectedSiteId);
    const selectedSite = _.get(siteLookup, selectedSiteId || "", null);
    const isEnabled = isMealTypeEnabled(selectedSite, props.mealType);

    if (!isEnabled) {
        return <div />;
    }

    return (
        <React.Fragment>
            <SeparatorLabel dense={true} label={props.mealType} />
            <div className={styles.field}>
                <InputNumber
                    value={props.childValue}
                    onChange={props.onChangeChildValue}
                    label="18 and under"
                />
                <InputNumber
                    value={props.adultValue}
                    onChange={props.onChangeAdultValue}
                    label="Adult"
                />
            </div>
        </React.Fragment>
    );
};

export default MealEntry;
