import { MealType } from "control/API";
import { AppState } from "model/reducers";
import _ from "lodash";
import { IAddress } from "./addr.entity";
import { createSelector } from "@reduxjs/toolkit";
import { UserEntity } from "./user.entity";

export interface ITF {
    s: string;
    e: string;
}

export interface ISite {
    dId: string;
    sId: string;
    name: string;
    addr: IAddress;
    meals: MealType[];
    hop: {
        m: ITF | null;
        t: ITF | null;
        w: ITF | null;
        th: ITF | null;
        f: ITF | null;
        st: ITF | null;
        su: ITF | null;
    };
    actv: boolean;
    stCd?: string;
    addl?: string;
}

export class SiteEntity {
    public static unfilteredItems(appState: AppState) {
        return createSelector(
            (state: AppState) => _.values(state.sites.items),
            items => _.cloneDeep(items)
        )(appState);
    }

    public static filteredItems(appState: AppState): ISite[] {
        return createSelector(
            (state: AppState) => state.sites.items,
            UserEntity.siteAccessControlList,
            UserEntity.isAdmin,
            (items, controlList, skipSiteFilters) =>
                _.flow([
                    _.values,
                    _.cloneDeep,
                    // filter based on access control list
                    _.partialRight(
                        _.filter,
                        (item: ISite) =>
                            skipSiteFilters || _.includes(controlList, item.sId)
                    ),
                    // filter inactive sites
                    _.partialRight(_.filter, (item: ISite) => item.actv),
                ])(items)
        )(appState);
    }

    public static status(appState: AppState) {
        return appState.sites.status;
    }

    public static lookupTable(appState: AppState) {
        return appState.sites.items;
    }

    public static getSelectedItem(appState: AppState) {
        return appState.sites.selected;
    }

    public static createStatus(appState: AppState) {
        return appState.sites.createStatus;
    }

    public static updateStatus(appState: AppState) {
        return appState.sites.updateStatus;
    }

    public static listInactiveSites(appState: AppState) {
        return _.flow([
            _.values,
            _.partialRight(_.filter, (item: ISite) => !item.actv)
        ])(appState.sites.items);
    }
}
