import { ItemsStatus, ActionStatus } from "./../entity/ui.entity";
import { IDistrict } from "model/entity";
import {
    loadDistrictsSuccess,
    loadDistrictsFail,
    loadDistricts,
    createDistrictOpen,
    createDistrictInit,
    createDistrictSuccess,
    createDistrictFail,
    createDistrictCancel,
    updateDistrictOpen,
    updateDistrictInit,
    updateDistrictSuccess,
    updateDistrictFail,
    updateDistrictCancel
} from "control/actions";
import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { forEach } from "lodash";

export interface DistrictsState {
    items: { [id: string]: IDistrict };
    status: ItemsStatus;
    selected: string | null;
    createStatus: ActionStatus;
    updateStatus: ActionStatus;
}

const initialState: DistrictsState = {
    items: {},
    selected: null,
    status: "initial",
    createStatus: "closed",
    updateStatus: "closed"
};

export const districtsReducer = createReducer(initialState, {
    // items
    [loadDistricts.toString()]: state =>
        produce(state, draft => {
            draft.status = "loading";
        }),
    [loadDistrictsSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            forEach(action.payload, item => {
                draft.items[item.id] = item;
            });
            draft.status = "success";
        }),
    [loadDistrictsFail.toString()]: (state, action) =>
        produce(state, draft => {
            draft.status = "fail";
        }),

    // create
    [createDistrictOpen.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "open";
        }),
    [createDistrictInit.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "in-progress";
        }),
    [createDistrictSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.items[item.id] = item;
            draft.createStatus = "closed";
        }),
    [createDistrictFail.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "fail";
        }),
    [createDistrictCancel.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "closed";
        }),

    // Update
    [updateDistrictOpen.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "open";
            draft.selected = action.payload;
        }),
    [updateDistrictInit.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "in-progress";
        }),
    [updateDistrictSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.updateStatus = "closed";
            draft.items[item.id] = item;
            draft.selected = null;
        }),
    [updateDistrictFail.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "fail";
            draft.selected = null;
        }),
    [updateDistrictCancel.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "closed";
            draft.selected = null;
        })
});
