import React from "react";
import PageLayout from "view/components/PageLayout";
import ProductionToolbar from "./Toolbar";
import ProductionTableView from "./ProductionTableView";
import CreateProductionCount from "./CreateProductionCount";
import UpdateProductionCount from "./UpdateProductionCount";

const ProductionPage: React.FC = () => {
    return (
        <React.Fragment>
            <PageLayout toolbar={<ProductionToolbar />}>
                <ProductionTableView />
            </PageLayout>
            <CreateProductionCount />
            <UpdateProductionCount />
        </React.Fragment>
    );
};

export default ProductionPage;
