import React from "react";
import BaseDialog from "view/components/BaseDialog";
import { DialogActions, Button, DialogContentText } from "@material-ui/core";
import { IUserInfo } from "model/entity";

const ConfirmApprove: React.FC<{
    open: boolean;
    user: IUserInfo | null;
    onClose: () => void;
    onConfirm: () => void;
}> = props => {
    return (
        <BaseDialog
            open={props.open}
            label="confirm-user-approve"
            title="Confirm Approve"
            onClose={props.onClose}
            actions={
                <DialogActions>
                    <Button onClick={props.onClose}>Close</Button>
                    <Button color="primary" onClick={props.onConfirm}>
                        Approve
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Do you want to approve <b>{props.user?.email}</b>?
            </DialogContentText>
        </BaseDialog>
    );
};

export default ConfirmApprove;
