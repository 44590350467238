import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { ProductionEntity } from "model/entity/production.entity";
import {
    createProductionRecordOpen,
    updateProductionRecordOpen
} from "control/actions";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const ProductionTableView: React.FC = () => {
    const dispatch = useDispatch();
    const items = useSelector(ProductionEntity.items);
    const status = useSelector(ProductionEntity.status);
    const selectedDate = useSelector(ProductionEntity.date);
    const selectedSiteId = useSelector(ProductionEntity.siteId);

    const onEditRecord = (event: any, rowData: any) => {
        dispatch(updateProductionRecordOpen(rowData));
    };

    const openDialog = () => {
        dispatch(createProductionRecordOpen());
    };

    return (
        <MaterialTable
            isLoading={status === "loading"}
            columns={[
                {
                    title: "Breakfast Count",
                    field: "pB",
                    type: "numeric"
                },
                {
                    title: "Lunch Count",
                    field: "pL",
                    type: "numeric"
                },
                {
                    title: "Snack Count",
                    field: "pS",
                    type: "numeric"
                },
                {
                    title: "Dinner Count",
                    field: "pD",
                    type: "numeric"
                }
            ]}
            data={items}
            options={{
                search: false,
                exportButton: true,
                actionsColumnIndex: -1,
                paging: false
            }}
            actions={[
                {
                    icon: "edit",
                    tooltip: "Edit Record",
                    onClick: onEditRecord
                }
            ]}
            components={{
                Toolbar: toolbarProps => (
                    <div>
                        <MTableToolbar {...toolbarProps} />
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={!selectedDate || !selectedSiteId}
                            style={{
                                marginLeft: 12
                            }}
                            onClick={openDialog}
                        >
                            <AddIcon /> Record
                        </Button>
                    </div>
                )
            }}
            title="Production Count"
        />
    );
};

export default ProductionTableView;
