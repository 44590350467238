import React from "react";
import BaseDialog from "view/components/BaseDialog";
import {
    DialogActions,
    DialogContentText,
    Button,
    CircularProgress
} from "@material-ui/core";
import { AccountEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import { deleteUserCancel, deleteUser } from "control/actions";
import { AlertTitle, Alert } from "@material-ui/lab";

const DeleteAccountForm: React.FC = props => {
    const status = useSelector(AccountEntity.deleteUserStatus);
    const selectedAccount = useSelector(AccountEntity.selectedAccount);

    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(deleteUserCancel());
    };
    const onConfirmDelete = () => {
        if (selectedAccount) {
            dispatch(deleteUser(selectedAccount.cId));
        }
    };

    return (
        <BaseDialog
            open={status !== "closed"}
            label="confirm-delete-user"
            title="Confirm Delete User"
            actions={
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onConfirmDelete}
                    >
                        {status === "in-progress" && <CircularProgress />}
                        Confirm
                    </Button>
                </DialogActions>
            }
            onClose={onClose}
        >
            {status === "fail" && (
                <Alert severity="error">
                    <AlertTitle>Failed to delete</AlertTitle>
                    Error occurred during the delete operation. Try later.
                </Alert>
            )}
            <DialogContentText>
                Are you sure you want to delete {selectedAccount?.email}?
            </DialogContentText>
        </BaseDialog>
    );
};

export default DeleteAccountForm;
