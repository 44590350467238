import { parse, format, formatISO } from "date-fns";

export const milTimeToAmPm = (milInput: string) => {
    const stDate = parse(milInput, "HH:mm", new Date());
    return format(stDate, "hh:mm a");
};

export const formatDate = (date: Date) => {
    return formatISO(date, { representation: "date" });
};

export const todayISO = () => {
    return formatISO(new Date());
};
