import React, { useState, useEffect } from "react";
import { DistrictsEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import {
    TextField,
    DialogActions,
    Button,
    DialogContentText,
    CircularProgress
} from "@material-ui/core";
import { updateDistrictCancel, updateDistrictInit } from "control/actions";
import BaseDialog from "view/components/BaseDialog";
import SelectState from "view/components/SelectState";
import { Alert } from "@material-ui/lab";

const UpdateDistrict: React.FC = () => {
    const updateStatus = useSelector(DistrictsEntity.updateStatus);
    const district = useSelector(DistrictsEntity.selected);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(updateDistrictCancel());
    };

    const [name, setName] = useState(district.name);
    const [state, setState] = useState(district.state);

    const onUpdateName = (event: any) => {
        setName(event.target.value);
    };

    useEffect(() => {
        setName(district.name);
        setState(district.state);
    }, [district, setName, setState]);

    const dispatchUpdate = () => {
        dispatch(
            updateDistrictInit({
                id: district.id,
                name,
                state
            })
        );
    };

    return (
        <BaseDialog
            open={updateStatus !== "closed"}
            onClose={handleClose}
            label="edit-district"
            title="Update District"
            actions={
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="primary" onClick={dispatchUpdate}>
                        {updateStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Update"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Update the district using form below
            </DialogContentText>
            <form>
                <TextField
                    fullWidth={true}
                    id="district-name"
                    label="District Name"
                    variant="outlined"
                    value={name}
                    onChange={onUpdateName}
                />
                <SelectState value={state} onChange={setState} />

                {updateStatus === "fail" ? (
                    <Alert severity="error">
                        Failed to update the district
                    </Alert>
                ) : (
                    <div />
                )}
            </form>
        </BaseDialog>
    );
};

export default UpdateDistrict;
