import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AccountEntity, SiteEntity, ISite } from "model/entity";
import {
    Paper,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Button,
    CircularProgress
} from "@material-ui/core";
import { updateUser } from "control/actions";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { CognitoGroup } from "control/API";
import _ from "lodash";

const useStyles = makeStyles({
    paper: {
        margin: "12px 0px",
        padding: 8
    },
    formControl: {
        width: "100%"
    },
    chips: {},
    chip: {
        margin: 2
    },
    saveButton: {
        marginTop: 6,
        alignSelf: "flex-end",
        width: "fit-content"
    }
});

const UpdateAccessControlForm: React.FC = props => {
    const selectedAccount = useSelector(AccountEntity.selectedAccount);
    const updateStatus = useSelector(AccountEntity.accountUpdateStatus);
    const accountGroups = useSelector(AccountEntity.accountUserGroups);
    const sites = useSelector(SiteEntity.unfilteredItems);
    const styles = useStyles();
    const dispatch = useDispatch();
    const isUserAdmin = !_.flow([
        _.partialRight(_.intersection, [
            CognitoGroup.DistrictAdmin,
            CognitoGroup.SuperAdmin
        ]),
        _.isEmpty
    ])(accountGroups ? accountGroups.groups : [])

    const [sAcl, setAcl] = useState<string[]>(
        selectedAccount ? selectedAccount.sAcl || [] : []
    );

    const handleChange = (event: any) => {
        setAcl(event.target.value);
    };

    const confirmChange = () => {
        if (selectedAccount) {
            dispatch(
                updateUser({
                    cId: selectedAccount.cId,
                    sAcl
                })
            );
        }
    };

    return (
        <Paper className={styles.paper} variant="outlined">
            <Alert severity="info" style={{ marginBottom: 6 }}>
                <AlertTitle>Applies to Location Admin</AlertTitle>
                Use the following list to limit access to selected locations.
            </Alert>
            <FormControl className={styles.formControl}>
                <InputLabel id="access-control-list-label">
                    Access Control List
                </InputLabel>
                <Select
                    labelId="access-control-list-label"
                    id="access-control-list-id"
                    multiple
                    value={sAcl}
                    onChange={handleChange}
                    disabled={isUserAdmin}
                    input={<Input id="select-multiple-chip" />}
                >
                    {sites.map((site: ISite) => (
                        <MenuItem key={site.sId} value={site.sId}>
                            {site.name}
                        </MenuItem>
                    ))}
                </Select>

                {updateStatus === "fail" ? (
                    <Alert severity="error">Failed to update</Alert>
                ) : (
                    <div />
                )}

                <Button
                    className={styles.saveButton}
                    variant="outlined"
                    onClick={confirmChange}
                >
                    {updateStatus === "in-progress" ? (
                        <CircularProgress />
                    ) : (
                        <div />
                    )}
                    Save
                </Button>
            </FormControl>
        </Paper>
    );
};

export default UpdateAccessControlForm;
