import { AWSAppSyncClient, AUTH_TYPE } from "aws-appsync";
import { Auth } from "aws-amplify";
import awsConfig from "aws-exports";
import logger from "util/logger";

async function getToken() {
    try {
        const currentSession = await Auth.currentSession();
        return currentSession.getIdToken().getJwtToken();
    } catch (e) {
        logger.error("Getting JWT Token failed", e);
        return "";
    }
}

export const apiClient = new AWSAppSyncClient({
    url: awsConfig.aws_appsync_graphqlEndpoint,
    region: awsConfig.aws_appsync_region,
    auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => getToken()
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
    disableOffline: true
});

export const publicClient = new AWSAppSyncClient({
    url: awsConfig.aws_appsync_graphqlEndpoint,
    region: awsConfig.aws_appsync_region,
    auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: awsConfig.aws_appsync_apiKey
    },
    disableOffline: true
});
