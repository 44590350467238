import React from "react";
import PageLayout from "view/components/PageLayout";
import ServiceToolbar from "./Toolbar";
import ServiceTableView from "./ServiceTableView";
import CreateServing from "./CreateServing";
import UpdateServing from "./UpdateServing";
import DeleteServing from "./DeleteServing";

const ServicePage: React.FC = () => {
    return (
        <React.Fragment>
            <PageLayout toolbar={<ServiceToolbar />}>
                <ServiceTableView />
            </PageLayout>
            <CreateServing />
            <UpdateServing />
            <DeleteServing />
        </React.Fragment>
    );
};

export default ServicePage;
