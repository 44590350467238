import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    footer: {
        bottom: 0,
        textAlign: "center",
        width: "100%"
    },
    container: {
        padding: 12,
        borderRadius: 0
    }
});

const Footer: React.FC = () => {
    const styles = useStyles();
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                Copyright © 2020 Cybersoft PrimeroEdge. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
