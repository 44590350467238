import React, { useState, useEffect } from "react";
import BaseDialog from "view/components/BaseDialog";
import { ServingEntity, UserEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import {
    createServingInit,
    createServingCancel,
    createServingFail
} from "control/actions";
import {
    Button,
    DialogActions,
    DialogContentText,
    makeStyles,
    CircularProgress
} from "@material-ui/core";
import { formatDate, todayISO } from "lib/date.lib";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Alert } from "@material-ui/lab";
import { MealType } from "control/API";
import MealEntry from "./MealEntry";
import PhoneEntry from "./PhoneEntry";

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column"
    },
    field: {
        display: "flex",
        alignItems: "center",
        margin: "8px 0px"
    }
});

const CreateServing: React.FC = () => {
    const createStatus = useSelector(ServingEntity.createStatus);
    const dispatch = useDispatch();
    const styles = useStyles();

    const selectedSiteId = useSelector(ServingEntity.selectedSiteId);
    const selectedDate = useSelector(ServingEntity.selectedDate);
    const [isDataCleared, setDataCleared] = useState<boolean>(false);

    const handleClose = () => {
        dispatch(createServingCancel());
        setDataCleared(false);
    };

    const [pH, setPh] = useState<string>("");
    const [nCB, setNCB] = useState<number>(0);
    const [nAB, setNAB] = useState<number>(0);
    const [nCL, setNCL] = useState<number>(0);
    const [nAL, setNAL] = useState<number>(0);
    const [nCS, setNCS] = useState<number>(0);
    const [nAS, setNAS] = useState<number>(0);
    const [nCD, setNCD] = useState<number>(0);
    const [nAD, setNAD] = useState<number>(0);

    const district = useSelector(UserEntity.getDistrict);

    // clear fields on success
    useEffect(() => {
        if (createStatus === "closed" && !isDataCleared) {
            setPh("");
            setNCB(0);
            setNAB(0);
            setNCL(0);
            setNAL(0);
            setNCS(0);
            setNAS(0);
            setNCD(0);
            setNAD(0);
            setDataCleared(true);
        }
    }, [
        createStatus,
        isDataCleared,
        setNCB,
        setNAB,
        setNCL,
        setNAL,
        setNCS,
        setNAS,
        setNCD,
        setNAD,
        setDataCleared
    ]);

    const confirmAdd = () => {
        if (isValidPhoneNumber(pH) && selectedSiteId && selectedDate) {
            dispatch(
                createServingInit({
                    dId: district.id,
                    sId: selectedSiteId,
                    dt: formatDate(selectedDate),
                    ts: todayISO(),
                    pH,
                    nCB,
                    nCL,
                    nCS,
                    nCD,
                    nAB,
                    nAL,
                    nAS,
                    nAD
                })
            );
            setDataCleared(false);
        } else {
            dispatch(createServingFail());
        }
    };

    return (
        <BaseDialog
            open={createStatus !== "closed"}
            label="new-serving-dialog"
            title={"New Serving"}
            onClose={handleClose}
            actions={
                <DialogActions>
                    {createStatus === "fail" ? (
                        <Alert severity="error">Unable to add the entry</Alert>
                    ) : (
                        <div />
                    )}
                    <Button onClick={handleClose}>close</Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={confirmAdd}
                    >
                        {createStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Add"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Use the form below to add serving record
            </DialogContentText>

            <form className={styles.form}>
                <PhoneEntry value={pH} onChange={setPh} />

                <MealEntry
                    mealType={MealType.Breakfast}
                    childValue={nCB}
                    adultValue={nAB}
                    onChangeChildValue={setNCB}
                    onChangeAdultValue={setNAB}
                />

                <MealEntry
                    mealType={MealType.Lunch}
                    childValue={nCL}
                    adultValue={nAL}
                    onChangeChildValue={setNCL}
                    onChangeAdultValue={setNAL}
                />

                <MealEntry
                    mealType={MealType.Snack}
                    childValue={nCS}
                    adultValue={nAS}
                    onChangeChildValue={setNCS}
                    onChangeAdultValue={setNAS}
                />

                <MealEntry
                    mealType={MealType.Dinner}
                    childValue={nCD}
                    adultValue={nAD}
                    onChangeChildValue={setNCD}
                    onChangeAdultValue={setNAD}
                />
            </form>
        </BaseDialog>
    );
};

export default CreateServing;
