import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouterEntity, UserEntity } from "model/entity";
import {
    useTheme,
    useMediaQuery,
    ListItem,
    ListItemText
} from "@material-ui/core";
import { openPage, toggleNavbar } from "control/actions";
import { CognitoGroup } from "control/API";

const NavListItem: React.FC<{
    labelText: string;
    pathName: string;
    accessGroup?: CognitoGroup[];
}> = props => {
    const currPathName = useSelector(RouterEntity.pathName);
    const isAuthorized = useSelector(UserEntity.isAuthorized(props.accessGroup));

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const dispatch = useDispatch();
    const dispatchOpenPage = () => {
        dispatch(openPage(props.pathName));

        if (isMobile) {
            dispatch(toggleNavbar());
        }
    };

    if (props.accessGroup && props.accessGroup.length !== 0 && !isAuthorized) {
        return <div />
    }

    return (
        <ListItem
            onClick={dispatchOpenPage}
            selected={currPathName === props.pathName}
            button={true}
        >
            <ListItemText primary={props.labelText} />
        </ListItem>
    );
};

export default NavListItem;
