import React from "react";
import PageLayout from "view/components/PageLayout";
import { Card, Avatar, CardHeader, CardContent } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

const PageNotAuthorized: React.FC = () => {
    return (
        <PageLayout>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar aria-label="404-avatar">
                            <SentimentVeryDissatisfiedIcon />
                        </Avatar>
                    }
                    title="400 Not Authorized"
                    subheader="Unfortunately higher access level is required"
                />
                <CardContent>
                    <p>Not Authorized to view page</p>
                </CardContent>
            </Card>
        </PageLayout>
    );
};

export default PageNotAuthorized;
