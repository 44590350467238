import React from "react";
import { ISite } from "model/entity";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        width: 100
    },
    mealChip: {
        margin: "0px 2px 2px 0px"
    }
});

const MealsServed: React.FC<{
    site: ISite;
}> = props => {
    const site = props.site;
    const styles = useStyles();

    return (
        <div className={styles.container}>
            {site.meals.map(meal => (
                <Chip
                    key={meal}
                    size={"small"}
                    color="primary"
                    className={styles.mealChip}
                    label={meal}
                />
            ))}
        </div>
    );
};

export default MealsServed;
