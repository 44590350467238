import React from "react";
import PageLayout from "view/components/PageLayout";
import SummaryTable from "./SummaryTable";
import Toolbar from "./Toolbar";
import InactiveSitesAlert from "view/components/InactiveSitesAlert";

const DistrictWideSummary: React.FC = () => {
    return (
        <PageLayout>
            <Toolbar />
            <InactiveSitesAlert />
            <SummaryTable />
        </PageLayout>
    );
};

export default DistrictWideSummary;
