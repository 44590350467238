import {
    ListServingsQueryVariables,
    CreateServingInput,
    UpdateServingInput,
    ServingsForPhoneQueryVariables,
    DeleteServingInput
} from "control/API";
import { apiClient } from "./api.client";
import gql from "graphql-tag";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import { formatDate } from "lib/date.lib";
import * as _ from "lodash";
import { addDays } from "date-fns";

class ServingTransport {
    public async listServingsForSite(params: {
        districtId: string;
        siteId: string;
        date: Date;
    }) {
        const getVariables = (
            nToken: string | null
        ): ListServingsQueryVariables => ({
            nextToken: nToken,
            limit: 5000,
            dId: params.districtId,
            dtSIdPHTs: {
                // use begins with to find with date
                beginsWith: {
                    dt: formatDate(params.date),
                    sId: params.siteId
                }
            }
        });

        return this.getServings(getVariables);
    }

    public async listServingsForRange(params: {
        districtId: string;
        stDate: Date;
        endDate: Date;
    }) {
        const getVariables = (
            nToken: string | null
        ): ListServingsQueryVariables => ({
            nextToken: nToken,
            limit: 5000,
            dId: params.districtId,
            dtSIdPHTs: {
                // add 1 day to end date because default query is [)
                // - stDate included, endDate excluded
                between: [
                    { dt: formatDate(params.stDate) },
                    { dt: formatDate(addDays(params.endDate, 1)) }
                ]
            }
        });

        return this.getServings(getVariables);
    }

    public async listServingsForPhone(params: {
        districtId: string;
        date: Date;
        phNum: string;
    }) {
        const getVariables = (
            nToken: string | null
        ): ServingsForPhoneQueryVariables => ({
            nextToken: nToken,
            limit: 5000,
            dId: params.districtId,
            pHDtTs: {
                beginsWith: {
                    pH: params.phNum,
                    dt: formatDate(params.date)
                }
            }
        });

        let nextToken: string | null = null;
        let allServings: any[] = [];

        do {
            const resp: any = await apiClient.query({
                query: gql(queries.servingsForPhone),
                variables: getVariables(nextToken),
                fetchPolicy: "network-only"
            });
            allServings = _.concat(
                allServings,
                resp.data.servingsForPhone.items
            );
            nextToken = resp.data.servingsForPhone.nextToken;
        } while (nextToken);

        return allServings;
    }

    public async createServing(input: CreateServingInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.createServing),
            variables: {
                input
            }
        });

        return resp.data.createServing;
    }

    public async updateServing(input: UpdateServingInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.updateServing),
            variables: {
                input
            }
        });

        return resp.data.updateServing;
    }

    public async deleteServing(input: DeleteServingInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.deleteServing),
            variables: {
                input
            }
        });

        return resp.data.deleteServing;
    }

    private async getServings(
        getVariables: (nToken: string | null) => ListServingsQueryVariables
    ) {
        let nextToken: string | null = null;
        let allServings: any[] = [];

        do {
            const resp: any = await apiClient.query({
                query: gql(queries.listServings),
                variables: getVariables(nextToken),
                fetchPolicy: "network-only"
            });
            allServings = _.concat(allServings, resp.data.listServings.items);
            nextToken = resp.data.listServings.nextToken;
        } while (nextToken);

        return allServings;
    }
}

export default new ServingTransport();
