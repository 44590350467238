/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://74pp3ewyond3fhdkucw3jykghi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gdkt5iaowrc7xhpsfvormz3dra",
    "aws_content_delivery_bucket": "mjolnir-20200326174023-hostingbucket-demo",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d22qgnd8y9854w.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:c69f8047-c702-4809-9c3f-3d575b3db50f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Pi6cWrzeX",
    "aws_user_pools_web_client_id": "m2fih9j0fqb4l24mtkn09vgtm",
    "oauth": {}
};


export default awsmobile;
