import React, { useState, useEffect } from "react";
import {
    DialogActions,
    Button,
    CircularProgress
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { UserEntity, SiteEntity } from "model/entity";
import { createSiteInit, createSiteCancel } from "control/actions";
import SiteName from "../../components/SiteName";
import Address, { IAddress } from "../../components/Address";
import { MealType } from "control/API";
import SelectMealType from "../../components/SelectMealType";
import { IHoursOfOperation } from "model/entity/hop.entity";
import HoursOfOperation from "../../components/HoursOfOperation";
import SiteCode from "../../components/SiteCode";
import AdditionalInstr from "../../components/AdditionalInstr";
import SeparatorLabel from "../../components/SeparatorLabel";
import BaseDialog from "view/components/BaseDialog";
import { v4 as uuid } from "uuid";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import DataRequiredAlert from "./DataRequiredAlert";

const NewSite: React.FC = () => {
    const createStatus = useSelector(SiteEntity.createStatus);
    const dispatch = useDispatch();
    const district = useSelector(UserEntity.getDistrict);

    // data
    const [name, setName] = useState("");
    const [addr, setAddr] = useState<IAddress>({
        ln1: "",
        ln2: null,
        cty: "",
        st: "",
        zip: ""
    });
    const [meals, setMeals] = useState<MealType[]>([]);
    const [hop, setHop] = useState<IHoursOfOperation>({
        m: null,
        t: null,
        w: null,
        th: null,
        f: null,
        st: null,
        su: null
    });
    const [stCode, setStCode] = useState<string | undefined>(undefined);
    const [addlInst, setAddlInst] = useState<string | undefined>(undefined);
    const [dataCleared, setDataCleared] = useState(false);
    const [requiredData, setRequiredData] = useState(false);

    // clear on success
    useEffect(() => {
        if (createStatus === "closed" && !dataCleared) {
            setName("");
            setAddr({
                ln1: "",
                ln2: null,
                cty: "",
                st: "",
                zip: ""
            });
            setMeals([]);
            setHop({
                m: null,
                t: null,
                w: null,
                th: null,
                f: null,
                st: null,
                su: null
            });
            setStCode(undefined);
            setAddlInst(undefined);
            setDataCleared(true);
        }
    }, [
        createStatus,
        dataCleared,
        setName,
        setAddr,
        setMeals,
        setHop,
        setStCode,
        setAddlInst,
        setDataCleared
    ]);

    const isNameValid = !_.isEmpty(name);
    const isAddrValid =
        !_.isEmpty(addr.ln1) &&
        !_.isEmpty(addr.st) &&
        !_.isEmpty(addr.cty) &&
        !_.isEmpty(addr.zip);
    const isMealsValid = !_.isEmpty(meals);

    const dispatchAdd = () => {
        if (isNameValid && isAddrValid && isMealsValid) {
            dispatch(
                createSiteInit({
                    dId: district.id,
                    sId: uuid(),
                    name,
                    addr,
                    meals,
                    hop,
                    actv: true,
                    stCd: _.isEmpty(stCode) ? undefined : stCode,
                    addl: _.isEmpty(addlInst) ? undefined : addlInst
                })
            );
        } else {
            setRequiredData(true);
        }

        setDataCleared(false);
    };

    const handleClose = () => {
        dispatch(createSiteCancel());
        setDataCleared(false);
    };

    const closeRequiredDataAlert = () => {
        setRequiredData(false);
    };

    return (
        <BaseDialog
            open={createStatus !== "closed"}
            onClose={handleClose}
            label="new-site-dialog"
            title="Create Location"
            actions={
                <DialogActions>
                    {createStatus === "fail" ? (
                        <Alert severity="error">
                            Unable to create the location
                        </Alert>
                    ) : (
                        <div />
                    )}
                    <Button onClick={handleClose}>Close</Button>
                    <Button color="primary" onClick={dispatchAdd}>
                        {createStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Create"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <form>
                {requiredData && <DataRequiredAlert onClose={closeRequiredDataAlert} />}
                <SiteName value={name} onChange={setName} />

                <SeparatorLabel label={"Address"} />
                <Address value={addr} onChange={setAddr} />

                <SeparatorLabel label={"Meals served"} />
                <SelectMealType value={meals} onChange={setMeals} />

                <SeparatorLabel label={"Hours of Operation"} />
                <HoursOfOperation value={hop} onChange={setHop} />

                <SeparatorLabel label={"Additional Information"} />
                <SiteCode value={stCode} onChange={setStCode} />
                <AdditionalInstr value={addlInst} onChange={setAddlInst} />
            </form>
        </BaseDialog>
    );
};

export default NewSite;
