import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./login";
import Dashboard from "./dashboard";
import { history } from "model/store";
import Page404 from "./Page404";
import RouteWithSubRoutes, {
    IRouteWithSubRoutesProps
} from "../components/RouteWithSubRoutes";
import { ConnectedRouter } from "connected-react-router";
import DistrictsPage from "./districts";
import ServicePage from "./service";
import ListSites from "./sites";
import Page400 from "./Page400";
import ProductionPage from "./production";
import EndOfDayPage from "./end-of-day";
import MealCountReportPage from "./meal-count";
import PendingUsersPage from "./pending-users";
import AccountsPage from "./accounts";
import PendingApprovalPage from "./registration/PendingApprovalPage";
import DistrictWideSummary from "./district-wide-summary";
import { CognitoGroup } from "control/API";

// Pages are components that are directly connected to the routing module
// All top level pages should be declared here
// Lazy loading can be an option in future updates
const routes: IRouteWithSubRoutesProps[] = [
    {
        path: "/",
        exact: true,
        accessGroup: [],
        component: Dashboard
    },
    {
        path: "/accounts",
        exact: true,
        accessGroup: [CognitoGroup.DistrictAdmin, CognitoGroup.SuperAdmin],
        component: AccountsPage
    },
    {
        path: "/pending-users",
        exact: true,
        accessGroup: [CognitoGroup.SuperAdmin],
        component: PendingUsersPage
    },
    {
        path: "/pending-approval",
        exact: true,
        accessGroup: [],
        component: PendingApprovalPage
    },
    {
        path: "/districts",
        exact: true,
        component: DistrictsPage,
        accessGroup: [CognitoGroup.SuperAdmin]
    },
    {
        path: "/sites",
        exact: true,
        component: ListSites,
        accessGroup: []
    },
    {
        path: "/service",
        exact: true,
        component: ServicePage,
        accessGroup: [CognitoGroup.DistrictAdmin, CognitoGroup.SiteAdmin]
    },
    {
        path: "/production",
        exact: true,
        component: ProductionPage,
        accessGroup: [CognitoGroup.DistrictAdmin, CognitoGroup.SiteAdmin]
    },
    {
        path: "/end-of-day",
        exact: true,
        component: EndOfDayPage,
        accessGroup: [CognitoGroup.DistrictAdmin, CognitoGroup.SiteAdmin]
    },
    {
        path: "/meal-count-report",
        exact: true,
        component: MealCountReportPage,
        accessGroup: [CognitoGroup.DistrictAdmin]
    },
    {
        path: "/district-wide-summary",
        exact: true,
        component: DistrictWideSummary,
        accessGroup: [CognitoGroup.DistrictAdmin]
    },
    {
        path: "/not-authorized",
        exact: true,
        component: Page400
    },
    // if it does not match any of the above routes
    // the user is redirected to the following page
    // this is similar to 404 error in multi page app
    {
        component: Page404
    }
];

const RouteConfig: React.FC = (props: any) => {
    return (
        <ConnectedRouter history={history}>
            {props.children}
            <Switch>
                <Route path="/login" component={LoginPage} />
                {routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))}
            </Switch>
        </ConnectedRouter>
    );
};

export default RouteConfig;
