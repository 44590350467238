import React from "react";
import PageLayout from "view/components/PageLayout";
import TableView from "./TableView";
import CreateSite from "./CreateSite";
import UpdateSite from "./UpdateSite";
import SiteToolbar from "./Toolbar";

const SitesPage: React.FC = () => {
    return (
        <PageLayout toolbar={<SiteToolbar />}>
            <TableView />
            <CreateSite />
            <UpdateSite />
        </PageLayout>
    );
};

export default SitesPage;
