import React, { useState, useEffect } from "react";
import { DistrictsEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import {
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    CircularProgress
} from "@material-ui/core";
import { createDistrictInit, createDistrictCancel } from "control/actions";
import { isEmpty, some } from "lodash";
import SelectState from "view/components/SelectState";
import BaseDialog from "view/components/BaseDialog";
import { Alert } from "@material-ui/lab";

const NewDistrictDialog: React.FC = () => {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(createDistrictCancel());
    };

    const [districtName, setDistrictName] = useState("");
    const [state, setState] = useState("");
    const [clearData, setClearData] = useState<boolean>(false);
    const [sameName, setSameName] = useState<boolean>(false);
    const createStatus = useSelector(DistrictsEntity.createStatus);
    const lookupTable = useSelector(DistrictsEntity.lookupTable);

    const updateName = (event: any) => {
        setDistrictName(event.target.value);

        // check if same name exists
        const found = some(lookupTable, district => district.name === event.target.value);
        setSameName(found);
    };

    // clear previously entered data on close
    useEffect(() => {
        if (createStatus === "closed" && !clearData) {
            setClearData(true);
        }
    }, [clearData, createStatus, setClearData]);
   

    if (clearData) {
        setDistrictName("");
        setState("");
        setClearData(false);
    }

    const dispatchCreate = () => {
        if (!isEmpty(districtName) && !isEmpty(state)) {
            dispatch(
                createDistrictInit({
                    name: districtName,
                    state
                })
            );
        }
    };

    return (
        <BaseDialog
            open={createStatus !== "closed"}
            label="create-district"
            title="Create District"
            onClose={handleClose}
            actions={
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button color="primary" onClick={dispatchCreate}>
                        {createStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Create"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Create a new district using the form below
            </DialogContentText>

            <form>
                <TextField
                    id="district-name"
                    fullWidth={true}
                    label="District Name"
                    variant="outlined"
                    value={districtName}
                    onChange={updateName}
                    required={true}
                    error={sameName}
                    helperText={sameName ? "Found district with same name" : ""}
                />
                <SelectState value={state} onChange={setState} />

                {createStatus === "fail" ? (
                    <Alert severity="error">
                        Failed to add a new district, try again later
                    </Alert>
                ) : (
                    <div />
                )}
            </form>
        </BaseDialog>
    );
};

export default NewDistrictDialog;
