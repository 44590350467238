export interface IAddress {
    ln1: string;
    ln2?: string;
    cty: string;
    st: string;
    zip: string;
}

export const addrToUrl = (addr: IAddress) => {
    const placeAddr = `${addr.ln1} ${addr.cty} ${addr.st} ${addr.zip}`;
    const url = encodeURI(`https://www.google.com/maps/place/${placeAddr}`);
    return url;
};
