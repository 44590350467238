import React from "react";
import PageLayout from "view/components/PageLayout";
import { Card, Avatar, CardHeader, CardContent } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

const PageNotFound: React.FC = () => {
    const location = useLocation();
    return (
        <PageLayout>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar aria-label="404-avatar">
                            <SentimentVeryDissatisfiedIcon />
                        </Avatar>
                    }
                    title="404 Page Not Found"
                    subheader="Does not match any valid paths"
                />
                <CardContent>
                    <p>
                        Unable to find page - <b>{location.pathname}</b>
                    </p>
                </CardContent>
            </Card>
        </PageLayout>
    );
};

export default PageNotFound;
