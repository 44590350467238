import React from "react";
import { TextField, CircularProgress, makeStyles } from "@material-ui/core";
import {
    isValidPhoneNumber,
    formatPhoneNumber
} from "react-phone-number-input";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { ServingEntity, IServing } from "model/entity";
import { loadServingsForPhoneInit } from "control/actions";
import { Alert } from "@material-ui/lab";
import { formatDate } from "lib/date.lib";

const useStyles = makeStyles({
    alert: {
        width: 358,
        marginTop: 12
    },
    phNumContainer: {
        display: "flex",
        alignItems: "center"
    }
});

const PhoneEntry: React.FC<{
    value: string;
    onChange: (value: string) => void;
}> = props => {
    const selectedDate = useSelector(ServingEntity.selectedDate);
    const selectedSiteId = useSelector(ServingEntity.selectedSiteId);
    const records = useSelector(ServingEntity.phoneRecords);
    const status = useSelector(ServingEntity.phoneRecordsStatus);
    const styles = useStyles();

    // update if a record for the site is found
    const phRecords = useSelector(ServingEntity.phoneRecords);
    const siteRecords = _.filter(
        phRecords,
        (phSrv: IServing) => phSrv.sId === selectedSiteId
    );

    const dispatch = useDispatch();
    const onEnterValidNumber = (value: string) => {
        if (selectedDate) {
            dispatch(loadServingsForPhoneInit(selectedDate, value));
        }
    };

    const onChangePhoneNumber = (event: any) => {
        const currVal = _.replace(event.target.value, /[ ()-]/g, "");
        const phNum = `+1${currVal}`;

        if (isValidPhoneNumber(phNum)) {
            props.onChange(phNum);
            onEnterValidNumber(phNum);
        } else {
            props.onChange(currVal);
        }
    };

    return (
        <React.Fragment>
            <TextField
                error={!isValidPhoneNumber(props.value)}
                helperText={
                    !isValidPhoneNumber(props.value)
                        ? "Please enter a valid phone number"
                        : ""
                }
                autoFocus={true}
                variant="outlined"
                label="US phone Number"
                placeholder="Enter the number"
                value={
                    isValidPhoneNumber(props.value)
                        ? formatPhoneNumber(props.value)
                        : props.value
                }
                onChange={onChangePhoneNumber}
            />

            {status === "loading" ? (
                <Alert severity="info" className={styles.alert}>
                    <CircularProgress />
                    Looking for existing record with the number for the given
                    date
                </Alert>
            ) : (
                <div />
            )}

            {status === "success" && records.length !== 0 ? (
                <Alert severity="warning" className={styles.alert}>
                    Found {records.length} record(s) for{" "}
                    {formatPhoneNumber(props.value)} on <br />
                    {formatDate(selectedDate || new Date())} (District-wide)
                </Alert>
            ) : (
                <div />
            )}

            {status === "success" && siteRecords.length !== 0 ? (
                <Alert severity="info" className={styles.alert}>
                    {siteRecords.length} record(s) found for the selected
                    location
                </Alert>
            ) : (
                <div />
            )}

            {status === "fail" ? (
                <Alert severity="error" className={styles.alert}>
                    Unable to fetch records for given phone number
                </Alert>
            ) : (
                <div />
            )}
        </React.Fragment>
    );
};

export default PhoneEntry;
