import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { UserEntity, DistrictsEntity, IUserInfo } from "model/entity";
import _ from "lodash";
import ConfirmApprove from "./ConfirmApprove";
import ConfirmDeny from "./ConfirmDeny";
import { loadPendingUsers, deleteUser, approveAccount } from "control/actions";
import { useMediaQuery, useTheme, Button } from "@material-ui/core";
import TableTitle from "view/components/TableTitle";
import RefreshIcon from "@material-ui/icons/Refresh";

const PendingUsers: React.FC = () => {
    const status = useSelector(UserEntity.pendingUsersStatus);
    const items = useSelector(UserEntity.pendingUsers);
    const districtLookup = useSelector(DistrictsEntity.lookupTable);
    const dispatch = useDispatch();

    const [selected, setSelected] = useState<IUserInfo | null>(null);
    const [confirmApprove, setConfirmApprove] = useState<boolean>(false);
    const [confirmDeny, setConfirmDeny] = useState<boolean>(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const closeApprove = () => {
        setConfirmApprove(false);
    };

    const closeDeny = () => {
        setConfirmDeny(false);
    };

    const onApprove = (event: any, rowData: any) => {
        setSelected(rowData);
        setConfirmApprove(true);
    };

    const onDeny = (event: any, rowData: any) => {
        setSelected(rowData);
        setConfirmDeny(true);
    };

    const onRefreshData = () => {
        dispatch(loadPendingUsers());
    };

    const onConfirmApprove = () => {
        if (selected) {
            dispatch(approveAccount(selected.cId));
            setConfirmApprove(false);
        }
    };

    const onConfirmDeny = () => {
        if (selected) {
            dispatch(deleteUser(selected.cId));
            setConfirmDeny(false);
        }
    };

    return (
        <React.Fragment>
            <TableTitle title="Pending Users" isMobile={isMobile} />
            {status === "fail" ? (
                <Alert severity="error">
                    Failed to load locations at the moment
                </Alert>
            ) : (
                <div />
            )}
            <ConfirmApprove
                open={confirmApprove}
                onClose={closeApprove}
                user={selected}
                onConfirm={onConfirmApprove}
            />
            <ConfirmDeny
                open={confirmDeny}
                onClose={closeDeny}
                user={selected}
                onDeny={onConfirmDeny}
            />
            <MaterialTable
                isLoading={status === "loading"}
                columns={[
                    {
                        title: "Email",
                        field: "email"
                    },
                    {
                        title: "Phone Number",
                        field: "pH",
                        emptyValue: "NA"
                    },
                    {
                        title: "District",
                        field: "dId",
                        render: rowData => {
                            const district = _.get(districtLookup, rowData.dId);
                            return district ? district.name : null;
                        },
                        emptyValue: "Unknown"
                    },
                    {
                        title: "Cognito ID",
                        field: "cId"
                    }
                ]}
                data={items}
                options={{
                    exportButton: true,
                    actionsColumnIndex: -1,
                    showTitle: !isMobile
                }}
                actions={[
                    {
                        icon: "check_circle",
                        tooltip: "Approve User",
                        onClick: onApprove
                    },
                    {
                        icon: "cancel",
                        tooltip: "Deny User",
                        onClick: onDeny
                    }
                ]}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <Button
                                style={{ marginLeft: 16 }}
                                color="primary"
                                variant="outlined"
                                onClick={onRefreshData}
                            >
                                <RefreshIcon />
                                Refresh
                            </Button>
                        </div>
                    )
                }}
                title="Pending Users"
            />
        </React.Fragment>
    );
};

export default PendingUsers;
