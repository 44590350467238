import React from "react";
import PageLayout from "view/components/PageLayout";
import { Card, Avatar, CardHeader, CardContent } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import UserRegistrationDialog from "./UserRegistrationDialog";

const PendingApprovalPage: React.FC = () => {
    return (
        <PageLayout>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar aria-label="404-avatar">
                            <SentimentVeryDissatisfiedIcon />
                        </Avatar>
                    }
                    title="Pending approval"
                    subheader="Account is pending approval by an Admin"
                />
                <CardContent>
                    <p>Please contact the Admin to approve access</p>
                </CardContent>
            </Card>
            <UserRegistrationDialog />
        </PageLayout>
    );
};

export default PendingApprovalPage;
