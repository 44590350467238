import React, { useState, useEffect } from "react";
import BaseDialog from "view/components/BaseDialog";
import { useSelector, useDispatch } from "react-redux";
import {
    updateProductionRecord,
    updateProductionRecordCancel
} from "control/actions";
import { ProductionEntity } from "model/entity/production.entity";
import { Alert } from "@material-ui/lab";
import {
    DialogContentText,
    makeStyles,
    DialogActions,
    Button,
    CircularProgress
} from "@material-ui/core";
import InputNumber from "view/components/InputNumber";
import { SiteEntity, isMealTypeEnabled, UserEntity } from "model/entity";
import _ from "lodash";
import { MealType } from "control/API";

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    }
});

const UpdateProductionCount: React.FC = () => {
    const updateStatus = useSelector(ProductionEntity.updateStatus);

    const item = useSelector(ProductionEntity.editingItem);
    const styles = useStyles();

    const onClose = () => {
        dispatch(updateProductionRecordCancel());
    };

    const [pB, setPB] = useState<number>(item.pB || 0);
    const [pL, setPL] = useState<number>(item.pL || 0);
    const [pS, setPS] = useState<number>(item.pS || 0);
    const [pD, setPD] = useState<number>(item.pD || 0);

    useEffect(() => {
        setPB(item.pB || 0);
        setPL(item.pL || 0);
        setPS(item.pS || 0);
        setPD(item.pD || 0);
    }, [setPB, setPL, setPS, setPD, item]);

    const dispatch = useDispatch();
    const district = useSelector(UserEntity.getDistrict);
    const onEditConfirm = () => {
        dispatch(
            updateProductionRecord({
                dId: district.id,
                sId: item.sId,
                dt: item.dt,
                pB,
                pL,
                pS,
                pD
            })
        );
    };

    const siteLookup = useSelector(SiteEntity.lookupTable);
    const selectedSiteId = useSelector(ProductionEntity.siteId);
    const selectedSite = _.get(siteLookup, selectedSiteId || "", null);

    return (
        <BaseDialog
            open={updateStatus !== "closed"}
            onClose={onClose}
            label="edit-production-count"
            title="Edit Production Count"
            actions={
                <DialogActions>
                    {updateStatus === "fail" ? (
                        <Alert severity="error">Unable to update record</Alert>
                    ) : (
                        <div />
                    )}
                    <Button onClick={onClose}>close</Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={onEditConfirm}
                    >
                        {updateStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Update"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>
                Use the form below to edit a production count
            </DialogContentText>

            {item === null ? (
                <Alert severity="warning">
                    Unable to find the item you want to edit!
                </Alert>
            ) : (
                <form className={styles.form}>
                    <div style={{ marginBottom: 8 }}>Date: {item.dt}</div>
                    <InputNumber
                        label="Breakfast Count"
                        value={pB}
                        onChange={setPB}
                        hidden={
                            !isMealTypeEnabled(selectedSite, MealType.Breakfast)
                        }
                    />
                    <InputNumber
                        label="Lunch Count"
                        value={pL}
                        onChange={setPL}
                        hidden={
                            !isMealTypeEnabled(selectedSite, MealType.Lunch)
                        }
                    />
                    <InputNumber
                        label="Snack Count"
                        value={pS}
                        onChange={setPS}
                        hidden={
                            !isMealTypeEnabled(selectedSite, MealType.Snack)
                        }
                    />
                    <InputNumber
                        label="Dinner Count"
                        value={pD}
                        onChange={setPD}
                        hidden={
                            !isMealTypeEnabled(selectedSite, MealType.Dinner)
                        }
                    />
                </form>
            )}
        </BaseDialog>
    );
};

export default UpdateProductionCount;
