import React, { useState } from "react";
import BaseToolbar from "view/components/BaseToolbar";
import SelectDate from "view/components/SelectDate";
import { Button, makeStyles } from "@material-ui/core";
import { subDays } from "date-fns";

const useStyles = makeStyles({
    form: {
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        flexWrap: "wrap"
    }
});

const DateRangeToolbar: React.FC<{
    onClickApply: (stDate: Date, endDate: Date) => void;
}> = props => {
    const [stDate, setStDate] = useState<Date | null>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const styles = useStyles();

    const onClickApply = () => {
        if (stDate && endDate) {
            props.onClickApply(stDate, endDate);
        }
    };

    return (
        <BaseToolbar>
            <form className={styles.form}>
                <div style={{ marginRight: 8 }}>
                    <SelectDate
                        label="Start Date"
                        id="start-date"
                        value={stDate}
                        onChange={setStDate}
                    />
                </div>
                <div style={{ marginRight: 8 }}>
                    <SelectDate
                        id="end-date"
                        label="End Date"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </div>
                <Button
                    style={{ marginBottom: 8 }}
                    color="primary"
                    variant="outlined"
                    onClick={onClickApply}
                >
                    Apply
                </Button>
            </form>
        </BaseToolbar>
    );
};

export default DateRangeToolbar;
