import { AppState } from "model/reducers";
import { cloneDeep, values, sortBy } from "lodash";
import { createSelector } from "@reduxjs/toolkit";

export interface IDistrict {
    id: string;
    name: string;
    state: string;
    iactv: boolean;
}

export class DistrictsEntity {
    public static items(appState: AppState) {
        return createSelector(
            (state: AppState) => state.districts.items,
            items => cloneDeep(sortBy(values(items), item => item.state))
        )(appState);
    }

    public static lookupTable(appState: AppState) {
        return appState.districts.items;
    }

    public static status(appState: AppState) {
        return appState.districts.status;
    }

    public static selected(appState: AppState): IDistrict {
        const items = appState.districts.items;
        const selected = appState.districts.selected;
        return selected
            ? items[selected]
            : {
                id: "",
                name: "",
                state: "",
                iactv: false
              };
    }

    public static createStatus(appState: AppState) {
        return appState.districts.createStatus;
    }

    public static updateStatus(appState: AppState) {
        return appState.districts.updateStatus;
    }
}
