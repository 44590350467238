import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ServingEntity } from "model/entity/serving.entity";
import {
    setServiceSiteId,
    setServiceDate,
    loadServings
} from "control/actions";
import DateSiteToolbar from "view/components/DateSiteToolbar";

const ServiceToolbar: React.FC = () => {
    const serviceDate = useSelector(ServingEntity.selectedDate);
    const serviceSiteId = useSelector(ServingEntity.selectedSiteId);

    const dispatch = useDispatch();

    const onChangeDate = (date: Date | null) => {
        dispatch(setServiceDate(date));
    };

    const onChangeSite = (siteId: string | null) => {
        dispatch(setServiceSiteId(siteId));
    };

    const dispatchLoad = () => {
        if (serviceDate !== null && serviceSiteId !== null) {
            dispatch(loadServings(serviceSiteId, serviceDate));
        }
    };

    return (
        <DateSiteToolbar
            date={serviceDate}
            siteId={serviceSiteId}
            onChangeDate={onChangeDate}
            onChangeSite={onChangeSite}
            onClickApply={dispatchLoad}
            disableFutureDate={true}
        />
    );
};

export default ServiceToolbar;
