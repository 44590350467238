import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { SiteEntity } from "model/entity";
import { map } from "lodash";

const useStyles = makeStyles({
    root: {
        width: 200
    }
});

const SelectLocation: React.FC<{
    value: string | null;
    onChange: (value: string | null) => void;
}> = props => {
    const sites = useSelector(SiteEntity.filteredItems);
    const styles = useStyles();

    const updateSiteId = (event: any) => {
        props.onChange(event.target.value);
    };

    return (
        <FormControl className={styles.root}>
            <InputLabel id="select-location-label">Select Location</InputLabel>
            <Select
                labelId="select-location-label"
                id="select-location-id"
                value={props.value || ""}
                disabled={sites.length === 0}
                onChange={updateSiteId}
            >
                {map(sites, site => (
                    <MenuItem key={site.sId} value={site.sId}>
                        {site.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectLocation;
