import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

export interface IAddress {
    ln1: string;
    ln2?: string | null;
    zip: string;
    cty: string;
    st: string;
}

const useStyles = makeStyles({
    field: {
        width: "100%",
        marginTop: 10
    },
    horizontal: {
        marginTop: 10,
        display: "flex",
        alignItems: "flex-end"
    }
});

const Address: React.FC<{
    value: IAddress;
    onChange: (addr: IAddress) => void;
}> = ({ value, onChange }) => {
    const styles = useStyles();

    const updateLn1 = (event: any) => {
        onChange({
            ...value,
            ln1: event.target.value
        });
    };

    const updateLn2 = (event: any) => {
        onChange({
            ...value,
            ln2: event.target.value
        });
    };

    const updateZip = (event: any) => {
        onChange({
            ...value,
            zip: event.target.value
        });
    };

    const updateCty = (event: any) => {
        onChange({
            ...value,
            cty: event.target.value
        });
    };

    const updateSt = (event: any) => {
        onChange({
            ...value,
            st: event.target.value
        });
    };

    return (
        <React.Fragment>
            <TextField
                id="addr-line1"
                className={styles.field}
                value={value.ln1}
                label="Address Line 1"
                variant="outlined"
                onChange={updateLn1}
            />

            <TextField
                id="addr-line2"
                className={styles.field}
                value={value.ln2 || ""}
                label="Address Line 2"
                variant="outlined"
                onChange={updateLn2}
            />

            <TextField
                id="addr-city"
                className={styles.field}
                value={value.cty}
                label="City"
                variant="outlined"
                onChange={updateCty}
            />

            <div className={styles.horizontal}>
                <TextField
                    id="addr-state"
                    value={value.st}
                    label="State"
                    style={{ width: "100%" }}
                    variant="outlined"
                    onChange={updateSt}
                />

                <TextField
                    id="addr-zipcode"
                    value={value.zip}
                    style={{ marginLeft: 4 }}
                    label="Zip-code"
                    variant="outlined"
                    onChange={updateZip}
                />
            </div>
        </React.Fragment>
    );
};

export default Address;
