import {
    UserEntity,
    IServingReport,
    IServing,
    IAccReport,
    SiteEntity,
    ISite
} from "model/entity";
import {
    getMonthlyReport,
    getMonthlyReportFail,
    getMonthlyReportSuccess,
    getEndOfDayReport,
    getEndOfDayReportFail,
    getEndOfDayReportSuccess,
    getMealCountReport,
    getMealCountReportSuccess,
    getMealCountReportFail,
    getDistrictWideSummaryReport,
    getDistrictWideSummaryReportSuccess,
    getDistrictWideSummaryReportFail
} from "control/actions";
import { takeEvery, put, select } from "redux-saga/effects";
import servingTransport from "./transport/serving.transport";
import * as _ from "lodash";
import productionTransport from "./transport/production.transport";
import logger from "util/logger";
import {
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    startOfWeek,
    startOfMonth
} from "date-fns";

const accumulateServing = (items: IServing[]): IAccReport =>
    _.reduce(
        items,
        (result: IAccReport, item: IServing) => ({
            nCB: result.nCB + item.nCB,
            nCL: result.nCL + item.nCL,
            nCS: result.nCS + item.nCS,
            nCD: result.nCD + item.nCD,
            nAB: result.nAB + item.nAB,
            nAL: result.nAL + item.nAL,
            nAS: result.nAS + item.nAS,
            nAD: result.nAD + item.nAD
        }),
        {
            nCB: 0,
            nCL: 0,
            nCS: 0,
            nCD: 0,
            nAB: 0,
            nAL: 0,
            nAS: 0,
            nAD: 0
        }
    );

function* endOfDayReportSaga(action: any) {
    try {
        const district = yield select(UserEntity.getDistrict);
        const servedItems = yield servingTransport.listServingsForSite({
            districtId: district.id,
            siteId: action.payload.siteId,
            date: action.payload.date
        });
        const prodItems = yield productionTransport.listProductions({
            districtId: district.id,
            siteId: action.payload.siteId,
            date: action.payload.date
        });

        const servingData = accumulateServing(servedItems);
        const prodRecord = prodItems[0] || {
            pB: 0,
            pL: 0,
            pS: 0,
            pD: 0
        };

        const prodTotal =
            prodRecord.pB + prodRecord.pL + prodRecord.pS + prodRecord.pD;

        const totServChildren =
            servingData.nCB +
            servingData.nCL +
            servingData.nCS +
            servingData.nCD;

        const totServAdult =
            servingData.nAB +
            servingData.nAL +
            servingData.nAS +
            servingData.nAD;

        const report: IServingReport[] = [
            {
                name: "Breakfast",
                produced: prodRecord.pB,
                numServChildren: servingData.nCB,
                numServAdult: servingData.nAB,
                remaining: prodRecord.pB - (servingData.nCB + servingData.nAB)
            },
            {
                name: "Lunch",
                produced: prodRecord.pL,
                numServChildren: servingData.nCL,
                numServAdult: servingData.nAL,
                remaining: prodRecord.pL - (servingData.nCL + servingData.nAL)
            },
            {
                name: "Snack",
                produced: prodRecord.pS,
                numServChildren: servingData.nCS,
                numServAdult: servingData.nAS,
                remaining: prodRecord.pS - (servingData.nCS + servingData.nAS)
            },
            {
                name: "Dinner",
                produced: prodRecord.pD,
                numServChildren: servingData.nCD,
                numServAdult: servingData.nAD,
                remaining: prodRecord.pD - (servingData.nCD + servingData.nAD)
            },
            {
                name: "Total",
                produced: prodTotal,
                numServChildren: totServChildren,
                numServAdult: totServAdult,
                remaining: prodTotal - (totServAdult + totServChildren)
            }
        ];

        yield put(getEndOfDayReportSuccess(report));
    } catch (err) {
        logger.error(err);
        yield put(getEndOfDayReportFail());
    }
}

function* getMealCountSaga(action: any) {
    try {
        const district = yield select(UserEntity.getDistrict);
        const servedItems = yield servingTransport.listServingsForRange({
            districtId: district.id,
            stDate: action.payload.stDate,
            endDate: action.payload.endDate
        });
        const siteLookupTable = yield select(SiteEntity.lookupTable);
        const getSiteName = (id: string) => {
            const site = _.get(siteLookupTable, id);
            return site ? site.name : "Unknown";
        };
        const isSiteActv = (item: IServing) => {
            const site = _.get(siteLookupTable, item.sId)
            return site && site.actv;
        }

        const processedData = _.flow([
            // filter inactive sites
            _.partialRight(_.filter, isSiteActv),
            _.partialRight(_.groupBy, (item: IServing) => item.sId),
            _.partialRight(_.mapValues, (servings: IServing[]) =>
                accumulateServing(servings)
            ),
            _.partialRight(
                _.map,
                (servingReport: IServingReport, siteId: string) => ({
                    ...servingReport,
                    sId: siteId
                })
            ),
            _.partialRight(_.map, (item: any) => [
                {
                    mT: "Breakfast",
                    sN: getSiteName(item.sId),
                    nC: item.nCB,
                    nA: item.nAB,
                    nT: item.nCB + item.nAB
                },
                {
                    mT: "Lunch",
                    sN: getSiteName(item.sId),
                    nC: item.nCL,
                    nA: item.nAL,
                    nT: item.nCL + item.nAL
                },
                {
                    mT: "Snack",
                    sN: getSiteName(item.sId),
                    nC: item.nCS,
                    nA: item.nAS,
                    nT: item.nCS + item.nAS
                },
                {
                    mT: "Dinner",
                    sN: getSiteName(item.sId),
                    nC: item.nCD,
                    nA: item.nAD,
                    nT: item.nCD + item.nAD
                }
            ]),
            _.flatten
        ])(servedItems);

        yield put(getMealCountReportSuccess(processedData));
    } catch (err) {
        logger.error(err);
        yield put(getMealCountReportFail());
    }
}

const accumulateServingPerSite = _.flow([
    _.partialRight(_.groupBy, (item: IServing) => item.sId),
    _.partialRight(_.mapValues, (servings: IServing[]) =>
        _.reduce(
            servings,
            (result: number, record: IServing) =>
                result +
                record.nCB +
                record.nCL +
                record.nCS +
                record.nCD +
                record.nAB +
                record.nAL +
                record.nAS +
                record.nAD,
            0
        )
    )
]);

function* getMonthlyReportSaga() {
    try {
        const today = new Date();
        const district = yield select(UserEntity.getDistrict);
        const todayServings = yield servingTransport.listServingsForRange({
            districtId: district.id,
            stDate: startOfToday(),
            endDate: endOfToday()
        });
        const yesterdayServings = yield servingTransport.listServingsForRange({
            districtId: district.id,
            stDate: startOfYesterday(),
            endDate: endOfYesterday()
        });
        const thisWeekServings = yield servingTransport.listServingsForRange({
            districtId: district.id,
            stDate: startOfWeek(today),
            endDate: today
        });
        const thisMonthServings = yield servingTransport.listServingsForRange({
            districtId: district.id,
            stDate: startOfMonth(today),
            endDate: today
        });

        const allSites = yield select(SiteEntity.filteredItems);
        const todayLookup = accumulateServingPerSite(todayServings);
        const yesterdayLookup = accumulateServingPerSite(yesterdayServings);
        const thisWeekLookup = accumulateServingPerSite(thisWeekServings);
        const thisMonthLookup = accumulateServingPerSite(thisMonthServings);

        const report = _.map(allSites, (site: ISite) => ({
            siteName: site.name,
            today: _.get(todayLookup, site.sId, 0),
            yesterday: _.get(yesterdayLookup, site.sId, 0),
            thisWeek: _.get(thisWeekLookup, site.sId, 0),
            thisMonth: _.get(thisMonthLookup, site.sId, 0)
        }));

        const total = _.reduce(
            report,
            (result, site) => ({
                siteName: result.siteName,
                today: result.today + site.today,
                yesterday: result.yesterday + site.yesterday,
                thisWeek: result.thisWeek + site.thisWeek,
                thisMonth: result.thisMonth + site.thisMonth
            }),
            {
                siteName: "Total",
                today: 0,
                yesterday: 0,
                thisWeek: 0,
                thisMonth: 0
            }
        );

        yield put(getMonthlyReportSuccess(_.concat(report, total)));
    } catch (err) {
        logger.error(err);
        yield put(getMonthlyReportFail());
    }
}

function* getDistrictWideSummaryReportSaga(action: any) {
    try {
        const district = yield select(UserEntity.getDistrict);
        const siteLookupTable = yield select(SiteEntity.lookupTable);
        const servedItems = yield servingTransport.listServingsForRange({
            districtId: district.id,
            stDate: action.payload.stDate,
            endDate: action.payload.endDate
        });
        const isSiteActv = (item: IServing) => {
            const site = _.get(siteLookupTable, item.sId)
            return site && site.actv;
        }

        // filter inactive sites
        const processed = accumulateServing(_.filter(servedItems, isSiteActv));

        yield put(
            getDistrictWideSummaryReportSuccess([
                {
                    mT: "Breakfast",
                    nC: processed.nCB,
                    nA: processed.nAB,
                    nT: processed.nCB + processed.nAB
                },
                {
                    mT: "Lunch",
                    nC: processed.nCL,
                    nA: processed.nAL,
                    nT: processed.nCL + processed.nAL
                },
                {
                    mT: "Snack",
                    nC: processed.nCS,
                    nA: processed.nAS,
                    nT: processed.nCS + processed.nAS
                },
                {
                    mT: "Dinner",
                    nC: processed.nCD,
                    nA: processed.nAD,
                    nT: processed.nCD + processed.nAD
                },
                {
                    mT: "Total",
                    nC:
                        processed.nCB +
                        processed.nCL +
                        processed.nCS +
                        processed.nCD,
                    nA:
                        processed.nAB +
                        processed.nAL +
                        processed.nAS +
                        processed.nAD,
                    nT:
                        processed.nCB +
                        processed.nCL +
                        processed.nCS +
                        processed.nCD +
                        processed.nAB +
                        processed.nAL +
                        processed.nAS +
                        processed.nAD
                }
            ])
        );
    } catch (err) {
        yield put(getDistrictWideSummaryReportFail());
    }
}

export default function* reportSagas() {
    yield takeEvery(getEndOfDayReport, endOfDayReportSaga);
    yield takeEvery(getMealCountReport, getMealCountSaga);
    yield takeEvery(getMonthlyReport, getMonthlyReportSaga);
    yield takeEvery(
        getDistrictWideSummaryReport,
        getDistrictWideSummaryReportSaga
    );
}
