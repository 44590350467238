import React from "react";
import DateRangeToolbar from "view/components/DateRangeToolbar";
import { useDispatch } from "react-redux";
import { getDistrictWideSummaryReport } from "control/actions";

const DistrictWideSummaryToolbar: React.FC = () => {
    const dispatch = useDispatch();
    const onClickApply = (stDate: Date, endDate: Date) => {
        dispatch(getDistrictWideSummaryReport(stDate, endDate));
    };
    return <DateRangeToolbar onClickApply={onClickApply} />;
};

export default DistrictWideSummaryToolbar;
