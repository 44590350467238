import React, { useState, useEffect } from "react";
import {
    FormControl,
    CircularProgress,
    makeStyles,
    Button,
    FormLabel,
    FormControlLabel,
    Paper,
    Checkbox,
    ButtonGroup
} from "@material-ui/core";
import { CognitoGroup } from "control/API";
import { AccountEntity, UserEntity } from "model/entity";
import { useSelector, useDispatch } from "react-redux";
import {
    addUserToGroup,
    removeUserFromGroup,
    resetUpdateUserGroupStatus
} from "control/actions";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: 12
    },
    container: {
        width: "100%"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    saveButton: {
        marginTop: 8,
        width: "fit-content",
        alignSelf: "flex-end"
    },
    divider: {
        margin: "8px 0px"
    },
    alert: {
        marginBottom: 8
    }
});

const RoleCheckbox: React.FC<{
    label: string;
    disabled: boolean;
    value: CognitoGroup[];
    group: CognitoGroup;
    onRemove: (group: CognitoGroup) => void;
    onAdd: (group: CognitoGroup) => void;
}> = props => {
    const handleChange = (event: any, checked: boolean) => {
        if (checked) {
            props.onAdd(props.group);
        } else {
            props.onRemove(props.group);
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    disabled={props.disabled}
                    checked={props.value.includes(props.group)}
                    onChange={handleChange}
                    name={props.group}
                />
            }
            label={props.label}
        />
    );
};

const SelectRole: React.FC<{
    value: CognitoGroup[];
    disabled: boolean;
    onAddGroup: (group: CognitoGroup) => void;
    onRemoveGroup: (group: CognitoGroup) => void;
}> = props => {
    const isSuperAdmin = useSelector(UserEntity.isSuperAdmin);

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">User Role(s)</FormLabel>

            <RoleCheckbox
                label={"Location Admin"}
                disabled={props.disabled}
                group={CognitoGroup.SiteAdmin}
                value={props.value}
                onAdd={props.onAddGroup}
                onRemove={props.onRemoveGroup}
            />

            <RoleCheckbox
                label={"District Admin"}
                disabled={props.disabled}
                group={CognitoGroup.DistrictAdmin}
                value={props.value}
                onAdd={props.onAddGroup}
                onRemove={props.onRemoveGroup}
            />

            {isSuperAdmin ? (
                <RoleCheckbox
                    label={"Super Admin"}
                    disabled={props.disabled}
                    group={CognitoGroup.SuperAdmin}
                    value={props.value}
                    onAdd={props.onAddGroup}
                    onRemove={props.onRemoveGroup}
                />
            ) : (
                <div />
            )}
        </FormControl>
    );
};

const UpdateRoleForm: React.FC = () => {
    const styles = useStyles();

    const status = useSelector(AccountEntity.accountUserGroupStatus);
    const updateStatus = useSelector(
        AccountEntity.accountUserGroupUpdateStatus
    );
    const currUserData = useSelector(UserEntity.userData);
    const currAccountGroups = useSelector(AccountEntity.accountUserGroups);

    const dispatch = useDispatch();

    const [addGroup, setAddGroup] = useState<CognitoGroup | null>(null);
    const [removeGroup, setRemoveGroup] = useState<CognitoGroup | null>(null);

    // reset on success
    useEffect(() => {
        if (updateStatus === "success") {
            setAddGroup(null);
            setRemoveGroup(null);
            dispatch(resetUpdateUserGroupStatus());
        }
    }, [updateStatus, setAddGroup, setRemoveGroup, dispatch]);

    if (status === "in-progress" || updateStatus === "in-progress") {
        return <CircularProgress />;
    }

    if (status === "fail") {
        return <Alert severity="error">Unable to fetch the user's roles</Alert>;
    }

    const cancelChange = () => {
        setAddGroup(null);
        setRemoveGroup(null);
    }

    const confirmChange = () => {
        if (currAccountGroups && addGroup) {
            dispatch(addUserToGroup(currAccountGroups.accountId, addGroup));
        } else if (currAccountGroups && removeGroup) {
            dispatch(
                removeUserFromGroup(currAccountGroups.accountId, removeGroup)
            );
        } else {
            // invalid
        }
    };

    return (
        <Paper variant="outlined">
            <form className={styles.form}>
                {currUserData.cId === currAccountGroups?.accountId ? (
                    <Alert severity="warning" className={styles.alert}>
                        Editing your own role(s) can have unintended side
                        effects
                    </Alert>
                ) : (
                    <div />
                )}

                <Alert className={styles.alert} severity="info">
                    User can be assigned multiple roles.
                    <br /> Role with higher precedence takes over.
                </Alert>

                <SelectRole
                    disabled={!_.isNull(addGroup) || !_.isNull(removeGroup)}
                    value={currAccountGroups ? currAccountGroups.groups : []}
                    onAddGroup={setAddGroup}
                    onRemoveGroup={setRemoveGroup}
                />

                {!_.isNull(addGroup) ? (
                    <Alert severity="warning">
                        You are about add role, continue?
                    </Alert>
                ) : (
                    <div />
                )}

                {!_.isNull(removeGroup) ? (
                    <Alert severity="warning">
                        You are about remove role, continue?
                    </Alert>
                ) : (
                    <div />
                )}

                {updateStatus === "fail" ? (
                    <Alert severity="error">Failed to update</Alert>
                ) : (
                    <div />
                )}

                {!_.isNull(addGroup) || !_.isNull(removeGroup) ? (
                    <ButtonGroup variant="outlined" className={styles.saveButton}>
                        <Button onClick={cancelChange}>
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmChange}
                            color="secondary"
                        >
                            Confirm
                        </Button>
                    </ButtonGroup>

                ) : (
                    <div />
                )}
            </form>
        </Paper>
    );
};

export default UpdateRoleForm;
