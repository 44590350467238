import { UpdateUserInput, CognitoGroup } from "./../API";
import { createAction } from "@reduxjs/toolkit";
import { IUserInfo } from "model/entity";

export const setUserGroups = createAction(
    "USER_GROUPS",
    (groups: string[]) => ({
        payload: groups
    })
);

export const verifyUserReg = createAction(
    "IS_USER_REG_COMPLETE",
    (user: any) => ({
        payload: user
    })
);
export const setUserRegComplete = createAction(
    "USER_REG_COMPLETE",
    (data: any) => ({
        payload: data
    })
);
export const setUserRegIncomplete = createAction("USER_REG_INCOMPLETE");

// New User
export const registerNewUser = createAction(
    "REGISTER_NEW_USER",
    (districtId: string) => ({
        payload: {
            districtId
        }
    })
);
export const registerNewUserSuccess = createAction(
    "REGISTER_NEW_USER_SUCCESS",
    (userId: string, districtId: string) => ({
        payload: {
            userId,
            districtId
        }
    })
);
export const registerNewUserFail = createAction("REGISTER_NEW_USER_FAILED");

// User District
export const loadUserDistrict = createAction(
    "LOAD_USER_DISTRICT",
    (districtId: string) => ({
        payload: districtId
    })
);
export const loadUserDistrictSuccess = createAction(
    "LOAD_USER_DISTRICT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const loadUserDistrictFail = createAction("LOAD_USER_DISTRICT_FAIL");

// fetch user groups
export const fetchUserGroup = createAction(
    "FETCH_USER_GROUP",
    (accountId: string) => ({
        payload: accountId
    })
);
export const fetchUserGroupSuccess = createAction(
    "FETCH_USER_GROUP_SUCCESS",
    (accountId: string, groups: CognitoGroup[]) => ({
        payload: {
            accountId,
            groups
        }
    })
);
export const fetchUserGroupFail = createAction("FETCH_USER_GROUP_FAIL");
export const fetchUserGroupStatusReset = createAction(
    "FETCH_USER_GROUP_STATUS_RESET"
);

// update user groups
export const addUserToGroup = createAction(
    "ADD_USER_TO_GROUP",
    (accountId: string, groupName: CognitoGroup) => ({
        payload: {
            accountId,
            groupName
        }
    })
);
export const addUserToGroupSuccess = createAction("ADD_USER_TO_GROUP_SUCCESS");
export const addUserToGroupFail = createAction("ADD_USER_TO_GROUP_FAIL");
export const removeUserFromGroup = createAction(
    "REMOVE_USER_FROM_GROUP",
    (accountId: string, groupName: CognitoGroup) => ({
        payload: {
            accountId,
            groupName
        }
    })
);
export const removeUserFromGroupSuccess = createAction("REMOVE_USER_FROM_GROUP_SUCCESS");
export const removeUserFromGroupFail = createAction("REMOVE_USER_FROM_GROUP_FAIL");
export const resetUpdateUserGroupStatus = createAction("RESET_UPDATE_USER_GROUP_STATUS");

// Update User
export const updateUserInit = createAction(
    "UPDATE_USER_INIT",
    (data: IUserInfo) => ({
        payload: data
    })
);
export const updateUser = createAction(
    "UPDATE_USER",
    (input: UpdateUserInput) => ({
        payload: input
    })
);
export const updateUserSuccess = createAction(
    "UPDATE_USER_SUCCESS",
    (user: IUserInfo) => ({
        payload: user
    })
);
export const updateUserFail = createAction("UPDATE_USER_FAIL");
export const updateUserCancel = createAction("UPDATE_USER_CANCEL");

export const storeUserAttributes = createAction(
    "STORE_AUTH_USER_ATTR",
    (data: IUserInfo) => ({
        payload: data
    })
);

// District Users
export const loadDistrictUsers = createAction(
    "LOAD_DISTRICT_USERS",
    (districtId: string) => ({
        payload: districtId
    })
);
export const loadDistrictUsersSuccess = createAction(
    "LOAD_DISTRICT_USERS_SUCCESS",
    (users: IUserInfo[]) => ({
        payload: users
    })
);
export const loadDistrictUsersFail = createAction("LOAD_DISTRICT_USERS_FAIL");

// Pending Users
export const loadPendingUsers = createAction("LOAD_PENDING_USERS");
export const loadPendingUsersSuccess = createAction(
    "LOAD_PENDING_USERS_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const loadPendingUsersFail = createAction("LOAD_PENDING_USERS_FAIL");

// Delete User
export const deleteUserInit = createAction(
    "DELETE_USER_INIT",
    (user: IUserInfo) => ({
        payload: user
    })
);
export const deleteUser = createAction(
    "DELETE_USER",
    (accountId: string) => ({
        payload: accountId
    })
);
export const deleteUserSuccess = createAction(
    "DELETE_USER_SUCCESS",
    (accountId: string) => ({
        payload: accountId
    })
);
export const deleteUserFail = createAction("DELETE_USER_FAIL");
export const deleteUserCancel = createAction("DELETE_USER_CANCEL");

export const approveAccount = createAction(
    "APPROVE_ACCOUNT",
    (accountId: string) => ({
        payload: accountId
    })
);
export const approveAccountSuccess = createAction(
    "APPROVE_ACCOUNT_SUCCESS",
    (accountId: string) => ({
        payload: accountId
    })
);
export const approveAccountFail = createAction(
    "APPROVE_ACCOUNT_FAIL",
    (accountId: string) => ({
        payload: accountId
    })
);
