import {
    ListProductionsQueryVariables,
    CreateProductionInput,
    UpdateProductionInput
} from "control/API";
import { apiClient } from "./api.client";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import gql from "graphql-tag";
import { formatDate } from "lib/date.lib";

class ProductionTransport {
    public async listProductions(params: {
        districtId: string;
        siteId: string;
        date: Date;
    }) {
        const variables: ListProductionsQueryVariables = {
            limit: 3000,
            dId: params.districtId,
            sIdDt: {
                eq: {
                    sId: params.siteId,
                    dt: formatDate(params.date)
                }
            }
        };

        const resp: any = await apiClient.query({
            query: gql(queries.listProductions),
            variables,
            fetchPolicy: "no-cache"
        });

        return resp.data.listProductions.items;
    }

    public async createProduction(input: CreateProductionInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.createProduction),
            variables: {
                input
            }
        });

        return resp.data.createProduction;
    }

    public async updateProduction(input: UpdateProductionInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.updateProduction),
            variables: {
                input
            }
        });

        return resp.data.updateProduction;
    }
}

export default new ProductionTransport();
