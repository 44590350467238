import Just from "./Just";
import Nothing from "./Nothing";

type MaybeType<T> = Just<T> | Nothing;

/**
 * Maybe provides a guard against the flexible usage of null
 * Maybe ensures that a given value is really
 * a value and not really "null" because of an some unknown condition.
 * The primary use case of this package is to avoid using `null`
 */
// tslint:disable-next-line: max-classes-per-file
export class Maybe<T> {
    // ------------------- Static members -----------------------
    /**
     * Creates a maybe and sets a value
     */
    public static just = (value: any): Maybe<any> => new Maybe(new Just(value));

    /**
     * Creates a maybe and sets to nothing (represents that there is no data)
     */
    public static nothing = (errorStr: string = ""): Maybe<any> =>
        new Maybe(new Nothing(errorStr));

    /**
     * Checks if the data is null and sets data as nothing
     */
    public static fromMaybeNull = (val: any | null): Maybe<any> =>
        val ? Maybe.just(val) : Maybe.nothing();

    // ------------------- Instance members -----------------------

    private data: MaybeType<T>;

    constructor(data: MaybeType<T>) {
        this.data = data;
    }

    /**
     * Check and verify if the value is really value
     */
    public isJust(): boolean {
        return this.data instanceof Just;
    }

    /**
     * Convert a maybe to promise
     */
    public toPromise(): Promise<T> {
        if (this.isJust()) {
            return Promise.resolve(this.getValue());
        } else {
            return Promise.reject();
        }
    }

    /**
     * Convert a maybe to nullable
     */
    public toNullable(): T | null {
        if (this.isJust()) {
            return this.getValue();
        } else {
            return null;
        }
    }

    /**
     * If value does not exist, returns default value
     */
    public withDefault(val: T): T {
        if (this.isJust()) {
            return this.getValue();
        } else {
            return val;
        }
    }

    /**
     * Check and verify if the value is nothing (error condition)
     */
    public isNothing(): boolean {
        return this.data instanceof Nothing;
    }

    public getValue(): T {
        const valObj = this.data as Just<T>;
        return valObj.value;
    }

    /**
     * Optionally API's can set an error string to know why the error happened
     */
    public getErrorStr(): string {
        const errObj = this.data as Nothing;
        if (typeof errObj.errorStr === "string") {
            return errObj.errorStr;
        } else {
            return "";
        }
    }
}

export default Maybe;
