import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import {
    ServingEntity,
    UserEntity,
    SiteEntity,
    isMealTypeEnabled
} from "model/entity";
import { Button, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import {
    createServingOpen,
    updateServingOpen,
    deleteServingOpen
} from "control/actions";
import { formatPhoneNumber } from "react-phone-number-input";
import _ from "lodash";
import { MealType, CognitoGroup } from "control/API";
import AddIcon from "@material-ui/icons/Add";
import TableTitle from "view/components/TableTitle";

const useStyles = makeStyles({
    phoneNumber: {
        width: 100
    }
});

const Toolbar: React.FC = toolbarProps => {
    const isAuthorized = useSelector(
        UserEntity.isAuthorized([
            CognitoGroup.DistrictAdmin,
            CognitoGroup.SiteAdmin
        ])
    );
    const dispatch = useDispatch();
    const dispatchCreateSite = () => {
        dispatch(createServingOpen());
    };

    const date = useSelector(ServingEntity.selectedDate);
    const siteId = useSelector(ServingEntity.selectedSiteId);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div>
            <TableTitle title="Service" isMobile={isMobile} />
            <MTableToolbar {...toolbarProps} />
            <Button
                variant="outlined"
                color="primary"
                disabled={!date || !siteId}
                style={{
                    marginLeft: 12,
                    display: !isAuthorized ? "none" : "flex",
                    alignItems: "center"
                }}
                onClick={dispatchCreateSite}
            >
                <AddIcon /> Serve
            </Button>
        </div>
    );
};

const ServiceTableView: React.FC = () => {
    const items = useSelector(ServingEntity.items);
    const status = useSelector(ServingEntity.itemStatus);
    const styles = useStyles();
    const dispatch = useDispatch();

    const dispatchUpdateServing = (event: any, serving: any) => {
        dispatch(updateServingOpen(serving));
    };

    const dispatchDeleteServing = (event: any, serving: any) => {
        dispatch(deleteServingOpen(serving));
    };

    const siteId = useSelector(ServingEntity.selectedSiteId);
    const lookupTable = useSelector(SiteEntity.lookupTable);
    const site = _.get(lookupTable, siteId || "");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <MaterialTable
            isLoading={status === "loading"}
            columns={[
                {
                    title: "Phone Number",
                    field: "pH",
                    render: rowData => (
                        <div className={styles.phoneNumber}>
                            {formatPhoneNumber(rowData.pH)}
                        </div>
                    ),
                    emptyValue: "NA"
                },
                {
                    title: "Breakfast served (18 and under)",
                    field: "nCB",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Breakfast)
                },
                {
                    title: "Breakfast Served (Adult)",
                    field: "nAB",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Breakfast)
                },
                {
                    title: "Lunch served (18 and under)",
                    field: "nCL",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Lunch)
                },
                {
                    title: "Lunch Served (Adult)",
                    field: "nAL",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Lunch)
                },
                {
                    title: "Snack served (18 and under)",
                    field: "nCS",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Snack)
                },
                {
                    title: "Snack Served (Adult)",
                    field: "nAS",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Snack)
                },
                {
                    title: "Dinner served (18 and under)",
                    field: "nCD",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Dinner)
                },
                {
                    title: "Dinner Served (Adult)",
                    field: "nAD",
                    type: "numeric",
                    hidden: !isMealTypeEnabled(site, MealType.Dinner)
                }
            ]}
            data={items}
            options={{
                exportButton: true,
                actionsColumnIndex: -1,
                tableLayout: isMobile || items.length === 0 ? "auto" : "fixed",
                showTitle: !isMobile,
                searchFieldAlignment: isMobile ? "left" : "right"
            }}
            components={{
                Toolbar
            }}
            actions={[
                {
                    icon: "edit",
                    tooltip: "Edit Entry",
                    onClick: dispatchUpdateServing
                },
                {
                    icon: "delete",
                    tooltip: "Delete Entry",
                    onClick: dispatchDeleteServing
                }
            ]}
            title="Service"
        />
    );
};

export default ServiceTableView;
