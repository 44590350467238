import React from "react";
import PageLayout from "view/components/PageLayout";
import ReportsToolbar from "./Toolbar";
import MealCountReportTable from "./MealCountReportTable";
import { useSelector } from "react-redux";
import { ReportEntity } from "model/entity";
import { Alert } from "@material-ui/lab";
import InactiveSitesAlert from "view/components/InactiveSitesAlert";

const ReportsPage: React.FC = () => {
    const status = useSelector(ReportEntity.mealCountStatus);
    return (
        <PageLayout toolbar={<ReportsToolbar />}>
            {status === "fail" && <Alert severity="error">Unable to load the meal count</Alert>}
            <InactiveSitesAlert />
            <MealCountReportTable />
        </PageLayout>
    );
};

export default ReportsPage;
