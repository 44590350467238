import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { ReportEntity } from "model/entity";
import { useSelector } from "react-redux";

const SummaryTable: React.FC = () => {
    const [includeAdultMealCount, setIncludeAdultMealCount] = useState(true);
    const updateIncludeAdultMealCount = (event: any, checked: boolean) => {
        setIncludeAdultMealCount(checked);
    };

    const items = useSelector(ReportEntity.districtWideReport);
    const status = useSelector(ReportEntity.districtWideReportStatus);

    return (
        <MaterialTable
            isLoading={status === "loading"}
            columns={[
                {
                    title: "Meal Type",
                    field: "mT",
                    cellStyle: (value: any) => {
                        return value === "Total"
                            ? {
                                  fontWeight: "bold"
                              }
                            : {};
                    }
                },
                {
                    title: includeAdultMealCount
                        ? "Meal Count (18 and under)"
                        : "Meal Count",
                    field: "nC",
                    type: "numeric"
                },
                {
                    title: "Meal Count (Adult)",
                    field: "nA",
                    type: "numeric",
                    hidden: !includeAdultMealCount
                },
                {
                    title: "Meal Count (Total)",
                    type: "numeric",
                    field: "nT",
                    grouping: false,
                    hidden: !includeAdultMealCount,
                }
            ]}
            data={items}
            options={{
                exportButton: true,
                sorting: false,
                paging: false,
                search: false,
                rowStyle: rowData =>
                    rowData.mT === "Total"
                        ? {
                              background: "rgba(0,0,0,0.05)"
                          }
                        : {}
            }}
            components={{
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <FormControlLabel
                            style={{ marginLeft: 8 }}
                            control={
                                <Checkbox
                                    checked={includeAdultMealCount}
                                    onChange={updateIncludeAdultMealCount}
                                    name="child meal count"
                                />
                            }
                            label="Include Adult Meals"
                        />
                    </div>
                )
            }}
            title="District-Wide Summary"
        />
    );
};

export default SummaryTable;
