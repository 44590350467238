import { createAction } from "@reduxjs/toolkit";

export const getEndOfDayReport = createAction(
    "END_OF_DAY_REPORT",
    (siteId: string, date: Date | null) => ({
        payload: {
            siteId,
            date
        }
    })
);
export const getEndOfDayReportSuccess = createAction(
    "END_OF_DAY_REPORT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const getEndOfDayReportFail = createAction("END_OF_DAY_REPORT_FAIL");

export const getMealCountReport = createAction(
    "GET_SERVINGS_FOR_REPORT",
    (stDate: Date, endDate: Date) => ({
        payload: {
            stDate,
            endDate
        }
    })
);
export const getMealCountReportSuccess = createAction(
    "GET_SERVINGS_FOR_REPORT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const getMealCountReportFail = createAction(
    "GET_SERVINGS_FOR_REPORT_FAIL"
);

export const getMonthlyReport = createAction("GET_MONTHLY_REPORT");
export const getMonthlyReportSuccess = createAction(
    "GET_MONTHLY_REPORT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const getMonthlyReportFail = createAction("GET_MONTHLY_REPORT_FAIL");

export const getDistrictWideSummaryReport = createAction(
    "GET_DISTRICT_WIDE_SUMMARY_REPORT",
    (stDate: Date, endDate: Date) => ({
        payload: {
            stDate,
            endDate
        }
    })
);
export const getDistrictWideSummaryReportSuccess = createAction(
    "GET_DISTRICT_WIDE_SUMMARY_REPORT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const getDistrictWideSummaryReportFail = createAction(
    "GET_DISTRICT_WIDE_SUMMARY_REPORT_FAIL"
);
