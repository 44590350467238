import { AppState } from "../reducers";

export type ItemsStatus = "initial" | "loading" | "success" | "fail";
export type ActionStatus = "closed" | "open" | "in-progress" | "fail";

export class UiEntity {
    public static isNavOpen(state: AppState) {
        return state.ui.navigationPanel;
    }

    public static isBottomSheetOpen(state: AppState) {
        return state.ui.bottomSheet;
    }
}
