import React from "react";
import { SiteEntity, UserEntity, ISite } from "model/entity";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { createSiteOpen, updateSiteOpen } from "control/actions";
import LocationName from "./LocationName";
import HoursOfOperation from "./HoursOfOperation";
import StatusSwitch from "./StatusSwitch";
import MealsServed from "./MealsServed";
import { Alert } from "@material-ui/lab";
import TableTitle from "view/components/TableTitle";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";

const TableView: React.FC = () => {
    const filteredItems = useSelector(SiteEntity.filteredItems);
    const unfilteredItems = useSelector(SiteEntity.unfilteredItems);
    const status = useSelector(SiteEntity.status);
    const isAuthenticated = useSelector(UserEntity.isAuthenticated);
    const isDistrictAdmin = useSelector(UserEntity.isDistrictAdmin);
    const isAdmin = useSelector(UserEntity.isAdmin);
    const dispatch = useDispatch();
    const siteACL = useSelector(UserEntity.siteAccessControlList);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatchCreateSite = () => {
        dispatch(createSiteOpen());
    };

    const dispatchUpdateSite = (event: any, rowData: ISite | ISite[]) => {
        if (typeof rowData === "object") {
            const site = rowData as ISite;
            dispatch(updateSiteOpen(site));
        }
    };

    return (
        <React.Fragment>
            {status === "fail" ? (
                <Alert severity="error">
                    Failed to load locations at the moment
                </Alert>
            ) : (
                <div />
            )}
            <MaterialTable
                isLoading={status === "loading"}
                columns={[
                    {
                        title: "Location",
                        field: "name",
                        render: site => <LocationName site={site} />
                    },
                    {
                        title: "Special Instructions",
                        field: "addl",
                        emptyValue: "NA"
                    },
                    {
                        title: "Meal Served",
                        field: "meals",
                        render: site => <MealsServed site={site} />
                    },
                    {
                        title: "Hours of Operation",
                        render: site => <HoursOfOperation hop={site.hop} />
                    },
                    {
                        title: "Status",
                        field: "actv",
                        hidden: !isDistrictAdmin,
                        render: site => <StatusSwitch site={site} />
                    }
                ]}
                data={
                    !isAuthenticated || isAdmin
                        ? unfilteredItems
                        : filteredItems
                }
                options={{
                    exportButton: true,
                    actionsColumnIndex: -1,
                    showTitle: !isMobile,
                    searchFieldAlignment: isMobile ? "left" : "right"
                }}
                components={{
                    Toolbar: toolbarProps => (
                        <div>
                            <TableTitle title="Locations" isMobile={isMobile} />
                            <MTableToolbar {...toolbarProps} />
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    marginLeft: 12,
                                    display: isDistrictAdmin ? "flex" : "none"
                                }}
                                onClick={dispatchCreateSite}
                            >
                                <AddIcon /> Location
                            </Button>
                        </div>
                    )
                }}
                actions={
                    isAuthenticated
                        ? [
                              site => ({
                                  icon: "edit",
                                  tooltip: "Edit Location",
                                  onClick: dispatchUpdateSite,
                                  hidden: isDistrictAdmin
                                      ? false
                                      : !_.includes(siteACL, site.sId)
                              })
                          ]
                        : []
                }
                title="Locations"
            />
        </React.Fragment>
    );
};

export default TableView;
