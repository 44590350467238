import createSagaMiddleware from "redux-saga";
import userSaga from "./user.saga";
import appSaga from "./app.saga";
import authSaga from "./auth.saga";
import districtSaga from "./district.saga";
import siteSaga from "./site.saga";
import browserSaga from "./browser.saga";
import servingSaga from "./serving.saga";
import productionSaga from "./production.saga";
import reportsSaga from "./report.saga";
import { all } from "redux-saga/effects";

export const sagaMiddleware = createSagaMiddleware();

export function* rootSaga() {
    yield all([
        appSaga(),
        authSaga(),
        userSaga(),
        districtSaga(),
        siteSaga(),
        browserSaga(),
        servingSaga(),
        productionSaga(),
        reportsSaga()
    ]);
}
