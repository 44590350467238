import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DistrictsEntity, IDistrict } from "model/entity/districts.entity";
import { useSelector } from "react-redux";

const SelectDistrict: React.FC<{
    value: string;
    onChange: (districtId: string) => void;
    variant?: "outlined" | "filled" | "standard";
}> = props => {
    const items = useSelector(DistrictsEntity.items);

    const onDistrictChange = (event: any, district: IDistrict | null) => {
        if (district) {
            props.onChange(district.id);
        }
    };

    return (
        <Autocomplete
            id="select-district"
            options={items as IDistrict[]}
            getOptionLabel={option => option.name}
            style={{ width: 300, marginRight: 8 }}
            onChange={onDistrictChange}
            getOptionSelected={(opt: IDistrict) => opt.id === props.value}
            // items have to be in the same sorted order based on state
            // for now this is handled in the entity file
            groupBy={(opt: IDistrict) => opt.state}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Select District"
                    variant="standard"
                />
            )}
        />
    );
};

export default SelectDistrict;
