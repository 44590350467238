import React, { useState, useEffect } from "react";
import { DialogActions, Button, CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { SiteEntity, ISite } from "model/entity";
import { updateSiteInit, updateSiteClose } from "control/actions";
import SiteName from "../../components/SiteName";
import Address, { IAddress } from "../../components/Address";
import { MealType } from "control/API";
import SelectMealType from "../../components/SelectMealType";
import { IHoursOfOperation } from "model/entity/hop.entity";
import HoursOfOperation from "../../components/HoursOfOperation";
import SiteCode from "../../components/SiteCode";
import AdditionalInstr from "../../components/AdditionalInstr";
import SeparatorLabel from "../../components/SeparatorLabel";
import BaseDialog from "view/components/BaseDialog";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import DataRequiredAlert from "./DataRequiredAlert";

const UpdateSite: React.FC = () => {
    const updateStatus = useSelector(SiteEntity.updateStatus);
    const dispatch = useDispatch();
    const site: ISite | null = useSelector(SiteEntity.getSelectedItem);

    const handleClose = () => {
        dispatch(updateSiteClose());
    };

    // data
    const [name, setName] = useState("");
    const [addr, setAddr] = useState<IAddress>({
        ln1: "",
        ln2: "",
        cty: "",
        st: "",
        zip: ""
    });
    const [meals, setMeals] = useState<MealType[]>([]);
    const [hop, setHop] = useState<IHoursOfOperation>({
        m: null,
        t: null,
        w: null,
        th: null,
        f: null,
        st: null,
        su: null
    });
    const [stCode, setStCode] = useState<string | undefined>();
    const [addlInst, setAddlInst] = useState<string | undefined>();
    const [dataRequired, setDataRequired] = useState(false);

    const closeDataRequiredAlert = () => {
        setDataRequired(false);
    }

    useEffect(() => {
        if (site) {
            setName(site.name);
            setAddr(site.addr);
            setMeals(site.meals);
            setHop(site.hop);
            setStCode(site.stCd);
            setAddlInst(site.addl);
        }
    }, [site, setName, setAddr, setMeals, setHop, setStCode, setAddlInst]);

    const isNameValid = !_.isEmpty(name);
    const isAddrValid =
        !_.isEmpty(addr.ln1) &&
        !_.isEmpty(addr.st) &&
        !_.isEmpty(addr.cty) &&
        !_.isEmpty(addr.zip);
    const isMealsValid = !_.isEmpty(meals);

    const dispatchEdit = () => {
        if (site && isNameValid && isAddrValid && isMealsValid) {
            dispatch(
                updateSiteInit({
                    dId: site.dId,
                    sId: site.sId,
                    actv: site.actv,
                    name,
                    addr: {
                        ln1: addr.ln1,
                        ln2: addr.ln2,
                        cty: addr.cty,
                        st: addr.st,
                        zip: addr.zip
                    },
                    meals,
                    hop: {
                        m: hop.m
                            ? {
                                  s: hop.m.s,
                                  e: hop.m.e
                              }
                            : null,
                        t: hop.t
                            ? {
                                  s: hop.t.s,
                                  e: hop.t.e
                              }
                            : null,
                        w: hop.w
                            ? {
                                  s: hop.w.s,
                                  e: hop.w.e
                              }
                            : null,
                        th: hop.th
                            ? {
                                  s: hop.th.s,
                                  e: hop.th.e
                              }
                            : null,
                        f: hop.f
                            ? {
                                  s: hop.f.s,
                                  e: hop.f.e
                              }
                            : null,
                        st: hop.st
                            ? {
                                  s: hop.st.s,
                                  e: hop.st.e
                              }
                            : null,
                        su: hop.su
                            ? {
                                  s: hop.su.s,
                                  e: hop.su.e
                              }
                            : null
                    },
                    stCd: stCode,
                    addl: addlInst
                })
            );
        } else {
            setDataRequired(true);
        }
    };

    return (
        <BaseDialog
            open={updateStatus !== "closed" && site !== null}
            onClose={handleClose}
            label="edit-site-dialog"
            title="Update Location"
            actions={
                <DialogActions>
                    {updateStatus === "fail" ? (
                        <Alert severity="error">
                            Unable to update the location
                        </Alert>
                    ) : (
                        <div />
                    )}
                    <Button onClick={handleClose}>Close</Button>
                    <Button color="primary" onClick={dispatchEdit}>
                        {updateStatus === "in-progress" ? (
                            <CircularProgress />
                        ) : (
                            "Update"
                        )}
                    </Button>
                </DialogActions>
            }
        >
            <form>
                {dataRequired && <DataRequiredAlert onClose={closeDataRequiredAlert} />}
                <SiteName value={name} onChange={setName} />

                <SeparatorLabel label={"Address"} />
                <Address value={addr} onChange={setAddr} />

                <SeparatorLabel label={"Meals served"} />
                <SelectMealType value={meals} onChange={setMeals} />

                <SeparatorLabel label={"Hours of Operation"} />
                <HoursOfOperation value={hop} onChange={setHop} />

                <SeparatorLabel label={"Additional Information"} />
                <SiteCode value={stCode} onChange={setStCode} />
                <AdditionalInstr value={addlInst} onChange={setAddlInst} />
            </form>
        </BaseDialog>
    );
};

export default UpdateSite;
