import React from "react";
import PageLayout from "view/components/PageLayout";
import AccountsTable from "./AccountsTable";
import UpdateAccount from "./UpdateAccount";

const AccountsPage: React.FC = () => {
    return (
        <PageLayout>
            <UpdateAccount />
            <AccountsTable />
        </PageLayout>
    );
};

export default AccountsPage;
