import {
    loadDistricts,
    loadDistrictsFail,
    loadDistrictsSuccess,
    createDistrictInit,
    createDistrictSuccess,
    createDistrictFail,
    updateDistrictInit,
    updateDistrictSuccess,
    updateDistrictFail,
    toggleDistrictStatus,
    updateSiteInit
} from "control/actions";
import { takeEvery, put, all } from "redux-saga/effects";
import districtTransport from "./transport/district.transport";
import logger from "util/logger";
import { PayloadAction } from "@reduxjs/toolkit";
import { IDistrict, ISite } from "model/entity";
import siteTransport from "./transport/site.transport";
import { map } from "lodash";

function* loadDistrictsSaga(action: PayloadAction<"only-active" | "all">) {
    try {
        const data = yield districtTransport.listDistricts(action.payload !== "all");
        yield put(loadDistrictsSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(loadDistrictsFail());
    }
}

function* createDistrictSaga(action: any) {
    try {
        const data = yield districtTransport.createDistrict(action.payload);
        yield put(createDistrictSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(createDistrictFail());
    }
}

function* updateDistrictSaga(action: any) {
    try {
        const data = yield districtTransport.updateDistrict(action.payload);
        yield put(updateDistrictSuccess(data));
    } catch (err) {
        logger.error(err);
        yield put(updateDistrictFail());
    }
}

function* toggleDistrictSaga(action: PayloadAction<IDistrict>) {
    try {
        const district = action.payload;

        yield put(updateDistrictInit({
            id: district.id,
            iactv: !district.iactv
        }));

        const sites = yield siteTransport.listSites(district.id);

        // all sites within the district will be marked in-active
        yield all(map(sites, (site: ISite) =>
            put(updateSiteInit({
                dId: site.dId,
                sId: site.sId,
                actv: district.iactv
            }))
        ));
    } catch (err) {
        logger.error(err);
    }
}

/**
 * Root saga is where all the sagas are merged
 */
export default function* districtSagas() {
    // load districts
    yield takeEvery(loadDistricts, loadDistrictsSaga);
    yield takeEvery(createDistrictInit, createDistrictSaga);
    yield takeEvery(updateDistrictInit, updateDistrictSaga);
    yield takeEvery(toggleDistrictStatus, toggleDistrictSaga);
}
