import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

export interface ISelectTimeProps {
    id: string;
    label: string;
    value: Date | null;
    onChange: (val: Date | null) => void;
    disabled?: boolean;
}

const SelectTime: React.FC<ISelectTimeProps> = props => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
                margin="none"
                style={{ margin: "0 4px" }}
                id={props.id}
                label={props.label}
                value={props.value}
                disabled={props.disabled}
                onChange={props.onChange}
            />
        </MuiPickersUtilsProvider>
    );
};

export default SelectTime;
