import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector } from "react-redux";
import { ReportEntity } from "model/entity";
import {
    FormControlLabel,
    Checkbox,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import TableTitle from "view/components/TableTitle";
import _ from "lodash";
import { CsvBuilder } from "filefy";

const MealCountReportTableView: React.FC = () => {
    const items = useSelector(ReportEntity.mealCount);
    const status = useSelector(ReportEntity.mealCountStatus);
    const [includeAdultMealCount, setIncludeAdultMealCount] = useState(true);
    const [enableExport, setEnableExport] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const updateIncludeAdultMealCount = (event: any, checked: boolean) => {
        setIncludeAdultMealCount(checked);
    };

    const updateEnableExport = (event: any, checked: boolean) => {
        setEnableExport(checked);
    }

    // The following function is a placeholder because material-table
    // does not export data properly when it is grouped
    // override this and export data normally
    const exportCsv = (allColumns: any, allData: any) => {
        const columns = _.flow([
            _.cloneDeep,
            _.partialRight(_.filter, (col: any) => !col.hidden),
            _.partialRight(_.map, (col: any) => ({
                title: col.title,
                field: col.field
            }))
        ])(allColumns);

        // use name map to map fieldName to title
        const nameMap = _.flow([
            _.partialRight(_.keyBy, "field"),
            _.partialRight(_.mapValues, (col: any) => col.title)
        ])(columns);

        const getRowContent = _.flow([
            _.partialRight(_.mapKeys, (val: any, key: any) => nameMap[key]),
            // pick only the visible content and ignore other fields
            _.partialRight(
                _.pick,
                columns.map((col: any) => col.title)
            ),
            _.values
        ]);

        const rows = _.flow([
            _.cloneDeep,
            _.partialRight(_.map, (rowData: any) => rowData.data),
            _.flatten,
            _.partialRight(_.map, (rowData: any) => getRowContent(rowData)),
        ])(allData);

        new CsvBuilder("Meal-Count-Report.csv")
            .setColumns(columns.map((col: any) => col.title))
            .addRows(rows)
            .exportFile();
    };

    return (
        <MaterialTable
            isLoading={status === "loading"}
            columns={[
                {
                    title: "Location Name",
                    field: "sN",
                    defaultGroupOrder: 0
                },
                { title: "Meal Type", field: "mT" },
                {
                    title: includeAdultMealCount
                        ? "Meal Count (18 and under)"
                        : "Meal Count",
                    field: "nC",
                    type: "numeric"
                },
                {
                    title: "Meal Count (Adult)",
                    field: "nA",
                    type: "numeric",
                    hidden: !includeAdultMealCount
                },
                {
                    title: "Meal Count (Total)",
                    type: "numeric",
                    field: "nT",
                    hidden: !includeAdultMealCount
                }
            ]}
            data={items}
            options={{
                exportButton: enableExport,
                exportCsv,
                grouping: !enableExport,
                showTitle: !isMobile,
                searchFieldAlignment: isMobile ? "left" : "right"
            }}
            components={{
                Toolbar: props => (
                    <div>
                        <TableTitle
                            title={"Meal Count Report"}
                            isMobile={isMobile}
                        />
                        <MTableToolbar {...props} />
                        <FormControlLabel
                            style={{ marginLeft: 8 }}
                            control={
                                <Checkbox
                                    checked={includeAdultMealCount}
                                    onChange={updateIncludeAdultMealCount}
                                    name="child meal count"
                                />
                            }
                            label="Include Adult Meals"
                        />
                        <FormControlLabel
                            style={{ marginLeft: 8 }}
                            control={
                                <Checkbox
                                    checked={enableExport}
                                    onChange={updateEnableExport}
                                    name="Show Grouping"
                                />
                            }
                            label="Enable Export"
                        />
                    </div>
                )
            }}
            title="Meal Counts"
        />
    );
};

export default MealCountReportTableView;
