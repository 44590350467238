import React from "react";
import { Redirect } from "react-router";
import PageLayout from "view/components/PageLayout";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import MealsServed from "./MealsServed";
import { useSelector } from "react-redux";
import { UserEntity } from "model/entity";
import InactiveSitesAlert from "view/components/InactiveSitesAlert";

const useStyles = makeStyles({
    welcome: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        marginBottom: 8
    }
});

const AdminDashboard: React.FC = () => {
    const styles = useStyles();
    const isAdmin = useSelector(UserEntity.isAdmin);
    const isPendingApproval = useSelector(UserEntity.isPendingApproval);

    const district = useSelector(UserEntity.getDistrict);

    if (isPendingApproval) {
        return <Redirect to="/pending-approval" />;
    }

    // page is only available to admins, other users need to be redirected
    if (!isAdmin) {
        return <Redirect to="/sites" />;
    }

    return (
        <PageLayout>
            <Paper className={styles.welcome}>
                <Typography variant="h6">{district?.name}</Typography>
            </Paper>
            <InactiveSitesAlert />
            <MealsServed />
        </PageLayout>
    );
};

export default AdminDashboard;
