/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum CognitoGroup {
  SuperAdmin = "SuperAdmin",
  DistrictAdmin = "DistrictAdmin",
  SiteAdmin = "SiteAdmin",
}


export type CreateDistrictInput = {
  id?: string | null,
  name: string,
  state: string,
  iactv?: boolean | null,
};

export type ModelDistrictConditionInput = {
  name?: ModelStringInput | null,
  state?: ModelStringInput | null,
  iactv?: ModelBooleanInput | null,
  and?: Array< ModelDistrictConditionInput | null > | null,
  or?: Array< ModelDistrictConditionInput | null > | null,
  not?: ModelDistrictConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateDistrictInput = {
  id: string,
  name?: string | null,
  state?: string | null,
  iactv?: boolean | null,
};

export type DeleteDistrictInput = {
  id?: string | null,
};

export type CreateUserInput = {
  cId: string,
  dId: string,
  email: string,
  pH: string,
  pending?: UserStatus | null,
  name?: string | null,
  chldr?: Array< string | null > | null,
  meals?: Array< MealType | null > | null,
  allCh?: string | null,
  algrs?: Array< string | null > | null,
  sAcl?: Array< string | null > | null,
};

export enum UserStatus {
  PendingApproval = "PendingApproval",
}


export enum MealType {
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  Snack = "Snack",
  Dinner = "Dinner",
}


export type ModelUserConditionInput = {
  dId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  pH?: ModelStringInput | null,
  pending?: ModelUserStatusInput | null,
  name?: ModelStringInput | null,
  chldr?: ModelStringInput | null,
  meals?: ModelMealTypeListInput | null,
  allCh?: ModelStringInput | null,
  algrs?: ModelStringInput | null,
  sAcl?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type ModelMealTypeListInput = {
  eq?: Array< MealType | null > | null,
  ne?: Array< MealType | null > | null,
  contains?: MealType | null,
  notContains?: MealType | null,
};

export type UpdateUserInput = {
  cId: string,
  dId?: string | null,
  email?: string | null,
  pH?: string | null,
  pending?: UserStatus | null,
  name?: string | null,
  chldr?: Array< string | null > | null,
  meals?: Array< MealType | null > | null,
  allCh?: string | null,
  algrs?: Array< string | null > | null,
  sAcl?: Array< string | null > | null,
};

export type DeleteUserInput = {
  cId: string,
};

export type CreateSiteInput = {
  dId: string,
  sId: string,
  name: string,
  addr: AddressInput,
  meals?: Array< MealType > | null,
  hop: HoursOfOperationInput,
  actv: boolean,
  stCd?: string | null,
  addl?: string | null,
};

export type AddressInput = {
  ln1: string,
  ln2?: string | null,
  zip: string,
  cty: string,
  st: string,
  ctr?: string | null,
  addl?: string | null,
};

export type HoursOfOperationInput = {
  m?: TimeFrameInput | null,
  t?: TimeFrameInput | null,
  w?: TimeFrameInput | null,
  th?: TimeFrameInput | null,
  f?: TimeFrameInput | null,
  st?: TimeFrameInput | null,
  su?: TimeFrameInput | null,
};

export type TimeFrameInput = {
  s: string,
  e: string,
};

export type ModelSiteConditionInput = {
  name?: ModelStringInput | null,
  meals?: ModelMealTypeListInput | null,
  actv?: ModelBooleanInput | null,
  stCd?: ModelStringInput | null,
  addl?: ModelStringInput | null,
  and?: Array< ModelSiteConditionInput | null > | null,
  or?: Array< ModelSiteConditionInput | null > | null,
  not?: ModelSiteConditionInput | null,
};

export type UpdateSiteInput = {
  dId: string,
  sId: string,
  name?: string | null,
  addr?: AddressInput | null,
  meals?: Array< MealType > | null,
  hop?: HoursOfOperationInput | null,
  actv?: boolean | null,
  stCd?: string | null,
  addl?: string | null,
};

export type DeleteSiteInput = {
  dId: string,
  sId: string,
};

export type CreateServingInput = {
  dId: string,
  sId: string,
  dt: string,
  pH: string,
  ts: string,
  nCB: number,
  nCL: number,
  nCS: number,
  nCD: number,
  nAB: number,
  nAL: number,
  nAS: number,
  nAD: number,
};

export type ModelServingConditionInput = {
  nCB?: ModelIntInput | null,
  nCL?: ModelIntInput | null,
  nCS?: ModelIntInput | null,
  nCD?: ModelIntInput | null,
  nAB?: ModelIntInput | null,
  nAL?: ModelIntInput | null,
  nAS?: ModelIntInput | null,
  nAD?: ModelIntInput | null,
  and?: Array< ModelServingConditionInput | null > | null,
  or?: Array< ModelServingConditionInput | null > | null,
  not?: ModelServingConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateServingInput = {
  dId: string,
  sId: string,
  dt: string,
  pH: string,
  ts: string,
  nCB?: number | null,
  nCL?: number | null,
  nCS?: number | null,
  nCD?: number | null,
  nAB?: number | null,
  nAL?: number | null,
  nAS?: number | null,
  nAD?: number | null,
};

export type DeleteServingInput = {
  dId: string,
  dt: string,
  sId: string,
  pH: string,
  ts: string,
};

export type CreateProductionInput = {
  dId: string,
  sId: string,
  dt: string,
  pB: number,
  pL: number,
  pS: number,
  pD: number,
};

export type ModelProductionConditionInput = {
  pB?: ModelIntInput | null,
  pL?: ModelIntInput | null,
  pS?: ModelIntInput | null,
  pD?: ModelIntInput | null,
  and?: Array< ModelProductionConditionInput | null > | null,
  or?: Array< ModelProductionConditionInput | null > | null,
  not?: ModelProductionConditionInput | null,
};

export type UpdateProductionInput = {
  dId: string,
  sId: string,
  dt: string,
  pB?: number | null,
  pL?: number | null,
  pS?: number | null,
  pD?: number | null,
};

export type DeleteProductionInput = {
  dId: string,
  sId: string,
  dt: string,
};

export type ModelUserFilterInput = {
  cId?: ModelStringInput | null,
  dId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  pH?: ModelStringInput | null,
  pending?: ModelUserStatusInput | null,
  name?: ModelStringInput | null,
  chldr?: ModelStringInput | null,
  meals?: ModelMealTypeListInput | null,
  allCh?: ModelStringInput | null,
  algrs?: ModelStringInput | null,
  sAcl?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelServingPrimaryCompositeKeyConditionInput = {
  eq?: ModelServingPrimaryCompositeKeyInput | null,
  le?: ModelServingPrimaryCompositeKeyInput | null,
  lt?: ModelServingPrimaryCompositeKeyInput | null,
  ge?: ModelServingPrimaryCompositeKeyInput | null,
  gt?: ModelServingPrimaryCompositeKeyInput | null,
  between?: Array< ModelServingPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelServingPrimaryCompositeKeyInput | null,
};

export type ModelServingPrimaryCompositeKeyInput = {
  dt?: string | null,
  sId?: string | null,
  pH?: string | null,
  ts?: string | null,
};

export type ModelServingFilterInput = {
  dId?: ModelStringInput | null,
  sId?: ModelStringInput | null,
  dt?: ModelStringInput | null,
  pH?: ModelStringInput | null,
  ts?: ModelStringInput | null,
  nCB?: ModelIntInput | null,
  nCL?: ModelIntInput | null,
  nCS?: ModelIntInput | null,
  nCD?: ModelIntInput | null,
  nAB?: ModelIntInput | null,
  nAL?: ModelIntInput | null,
  nAS?: ModelIntInput | null,
  nAD?: ModelIntInput | null,
  and?: Array< ModelServingFilterInput | null > | null,
  or?: Array< ModelServingFilterInput | null > | null,
  not?: ModelServingFilterInput | null,
};

export type ModelProductionPrimaryCompositeKeyConditionInput = {
  eq?: ModelProductionPrimaryCompositeKeyInput | null,
  le?: ModelProductionPrimaryCompositeKeyInput | null,
  lt?: ModelProductionPrimaryCompositeKeyInput | null,
  ge?: ModelProductionPrimaryCompositeKeyInput | null,
  gt?: ModelProductionPrimaryCompositeKeyInput | null,
  between?: Array< ModelProductionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelProductionPrimaryCompositeKeyInput | null,
};

export type ModelProductionPrimaryCompositeKeyInput = {
  sId?: string | null,
  dt?: string | null,
};

export type ModelProductionFilterInput = {
  dId?: ModelStringInput | null,
  sId?: ModelStringInput | null,
  dt?: ModelStringInput | null,
  pB?: ModelIntInput | null,
  pL?: ModelIntInput | null,
  pS?: ModelIntInput | null,
  pD?: ModelIntInput | null,
  and?: Array< ModelProductionFilterInput | null > | null,
  or?: Array< ModelProductionFilterInput | null > | null,
  not?: ModelProductionFilterInput | null,
};

export type ModelServingByPhoneNumberCompositeKeyConditionInput = {
  eq?: ModelServingByPhoneNumberCompositeKeyInput | null,
  le?: ModelServingByPhoneNumberCompositeKeyInput | null,
  lt?: ModelServingByPhoneNumberCompositeKeyInput | null,
  ge?: ModelServingByPhoneNumberCompositeKeyInput | null,
  gt?: ModelServingByPhoneNumberCompositeKeyInput | null,
  between?: Array< ModelServingByPhoneNumberCompositeKeyInput | null > | null,
  beginsWith?: ModelServingByPhoneNumberCompositeKeyInput | null,
};

export type ModelServingByPhoneNumberCompositeKeyInput = {
  pH?: string | null,
  dt?: string | null,
  ts?: string | null,
};

export type ModelDistrictFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  state?: ModelStringInput | null,
  iactv?: ModelBooleanInput | null,
  and?: Array< ModelDistrictFilterInput | null > | null,
  or?: Array< ModelDistrictFilterInput | null > | null,
  not?: ModelDistrictFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSiteFilterInput = {
  dId?: ModelStringInput | null,
  sId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  meals?: ModelMealTypeListInput | null,
  actv?: ModelBooleanInput | null,
  stCd?: ModelStringInput | null,
  addl?: ModelStringInput | null,
  and?: Array< ModelSiteFilterInput | null > | null,
  or?: Array< ModelSiteFilterInput | null > | null,
  not?: ModelSiteFilterInput | null,
};

export type AddUserToGroupMutationVariables = {
  accountId: string,
  groupName: CognitoGroup,
};

export type AddUserToGroupMutation = {
  addUserToGroup: boolean | null,
};

export type RemoveUserFromGroupMutationVariables = {
  accountId: string,
  groupName: CognitoGroup,
};

export type RemoveUserFromGroupMutation = {
  removeUserFromGroup: boolean | null,
};

export type DeleteAccountMutationVariables = {
  accountId: string,
};

export type DeleteAccountMutation = {
  deleteAccount: boolean | null,
};

export type CreateDistrictMutationVariables = {
  input: CreateDistrictInput,
  condition?: ModelDistrictConditionInput | null,
};

export type CreateDistrictMutation = {
  createDistrict:  {
    __typename: "District",
    id: string,
    name: string,
    state: string,
    iactv: boolean | null,
  } | null,
};

export type UpdateDistrictMutationVariables = {
  input: UpdateDistrictInput,
  condition?: ModelDistrictConditionInput | null,
};

export type UpdateDistrictMutation = {
  updateDistrict:  {
    __typename: "District",
    id: string,
    name: string,
    state: string,
    iactv: boolean | null,
  } | null,
};

export type DeleteDistrictMutationVariables = {
  input: DeleteDistrictInput,
  condition?: ModelDistrictConditionInput | null,
};

export type DeleteDistrictMutation = {
  deleteDistrict:  {
    __typename: "District",
    id: string,
    name: string,
    state: string,
    iactv: boolean | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "User",
    cId: string,
    dId: string,
    email: string,
    pH: string,
    pending: UserStatus | null,
    name: string | null,
    chldr: Array< string | null > | null,
    meals: Array< MealType | null > | null,
    allCh: string | null,
    algrs: Array< string | null > | null,
    sAcl: Array< string | null > | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    cId: string,
    dId: string,
    email: string,
    pH: string,
    pending: UserStatus | null,
    name: string | null,
    chldr: Array< string | null > | null,
    meals: Array< MealType | null > | null,
    allCh: string | null,
    algrs: Array< string | null > | null,
    sAcl: Array< string | null > | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    cId: string,
    dId: string,
    email: string,
    pH: string,
    pending: UserStatus | null,
    name: string | null,
    chldr: Array< string | null > | null,
    meals: Array< MealType | null > | null,
    allCh: string | null,
    algrs: Array< string | null > | null,
    sAcl: Array< string | null > | null,
  } | null,
};

export type CreateSiteMutationVariables = {
  input: CreateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type CreateSiteMutation = {
  createSite:  {
    __typename: "Site",
    dId: string,
    sId: string,
    name: string,
    addr:  {
      __typename: "Address",
      ln1: string,
      ln2: string | null,
      zip: string,
      cty: string,
      st: string,
      ctr: string | null,
      addl: string | null,
    },
    meals: Array< MealType > | null,
    hop:  {
      __typename: "HoursOfOperation",
      m:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      t:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      w:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      th:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      f:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      st:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      su:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
    },
    actv: boolean,
    stCd: string | null,
    addl: string | null,
  } | null,
};

export type UpdateSiteMutationVariables = {
  input: UpdateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type UpdateSiteMutation = {
  updateSite:  {
    __typename: "Site",
    dId: string,
    sId: string,
    name: string,
    addr:  {
      __typename: "Address",
      ln1: string,
      ln2: string | null,
      zip: string,
      cty: string,
      st: string,
      ctr: string | null,
      addl: string | null,
    },
    meals: Array< MealType > | null,
    hop:  {
      __typename: "HoursOfOperation",
      m:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      t:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      w:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      th:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      f:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      st:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      su:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
    },
    actv: boolean,
    stCd: string | null,
    addl: string | null,
  } | null,
};

export type DeleteSiteMutationVariables = {
  input: DeleteSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type DeleteSiteMutation = {
  deleteSite:  {
    __typename: "Site",
    dId: string,
    sId: string,
    name: string,
    addr:  {
      __typename: "Address",
      ln1: string,
      ln2: string | null,
      zip: string,
      cty: string,
      st: string,
      ctr: string | null,
      addl: string | null,
    },
    meals: Array< MealType > | null,
    hop:  {
      __typename: "HoursOfOperation",
      m:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      t:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      w:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      th:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      f:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      st:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      su:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
    },
    actv: boolean,
    stCd: string | null,
    addl: string | null,
  } | null,
};

export type CreateServingMutationVariables = {
  input: CreateServingInput,
  condition?: ModelServingConditionInput | null,
};

export type CreateServingMutation = {
  createServing:  {
    __typename: "Serving",
    dId: string,
    sId: string,
    dt: string,
    pH: string,
    ts: string,
    nCB: number,
    nCL: number,
    nCS: number,
    nCD: number,
    nAB: number,
    nAL: number,
    nAS: number,
    nAD: number,
  } | null,
};

export type UpdateServingMutationVariables = {
  input: UpdateServingInput,
  condition?: ModelServingConditionInput | null,
};

export type UpdateServingMutation = {
  updateServing:  {
    __typename: "Serving",
    dId: string,
    sId: string,
    dt: string,
    pH: string,
    ts: string,
    nCB: number,
    nCL: number,
    nCS: number,
    nCD: number,
    nAB: number,
    nAL: number,
    nAS: number,
    nAD: number,
  } | null,
};

export type DeleteServingMutationVariables = {
  input: DeleteServingInput,
  condition?: ModelServingConditionInput | null,
};

export type DeleteServingMutation = {
  deleteServing:  {
    __typename: "Serving",
    dId: string,
    sId: string,
    dt: string,
    pH: string,
    ts: string,
    nCB: number,
    nCL: number,
    nCS: number,
    nCD: number,
    nAB: number,
    nAL: number,
    nAS: number,
    nAD: number,
  } | null,
};

export type CreateProductionMutationVariables = {
  input: CreateProductionInput,
  condition?: ModelProductionConditionInput | null,
};

export type CreateProductionMutation = {
  createProduction:  {
    __typename: "Production",
    dId: string,
    sId: string,
    dt: string,
    pB: number,
    pL: number,
    pS: number,
    pD: number,
  } | null,
};

export type UpdateProductionMutationVariables = {
  input: UpdateProductionInput,
  condition?: ModelProductionConditionInput | null,
};

export type UpdateProductionMutation = {
  updateProduction:  {
    __typename: "Production",
    dId: string,
    sId: string,
    dt: string,
    pB: number,
    pL: number,
    pS: number,
    pD: number,
  } | null,
};

export type DeleteProductionMutationVariables = {
  input: DeleteProductionInput,
  condition?: ModelProductionConditionInput | null,
};

export type DeleteProductionMutation = {
  deleteProduction:  {
    __typename: "Production",
    dId: string,
    sId: string,
    dt: string,
    pB: number,
    pL: number,
    pS: number,
    pD: number,
  } | null,
};

export type FetchUserGroupsQueryVariables = {
  accountId: string,
};

export type FetchUserGroupsQuery = {
  fetchUserGroups: Array< CognitoGroup | null > | null,
};

export type GetUserQueryVariables = {
  cId: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "User",
    cId: string,
    dId: string,
    email: string,
    pH: string,
    pending: UserStatus | null,
    name: string | null,
    chldr: Array< string | null > | null,
    meals: Array< MealType | null > | null,
    allCh: string | null,
    algrs: Array< string | null > | null,
    sAcl: Array< string | null > | null,
  } | null,
};

export type ListUsersQueryVariables = {
  cId?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      cId: string,
      dId: string,
      email: string,
      pH: string,
      pending: UserStatus | null,
      name: string | null,
      chldr: Array< string | null > | null,
      meals: Array< MealType | null > | null,
      allCh: string | null,
      algrs: Array< string | null > | null,
      sAcl: Array< string | null > | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetServingQueryVariables = {
  dId: string,
  dt: string,
  sId: string,
  pH: string,
  ts: string,
};

export type GetServingQuery = {
  getServing:  {
    __typename: "Serving",
    dId: string,
    sId: string,
    dt: string,
    pH: string,
    ts: string,
    nCB: number,
    nCL: number,
    nCS: number,
    nCD: number,
    nAB: number,
    nAL: number,
    nAS: number,
    nAD: number,
  } | null,
};

export type ListServingsQueryVariables = {
  dId?: string | null,
  dtSIdPHTs?: ModelServingPrimaryCompositeKeyConditionInput | null,
  filter?: ModelServingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServingsQuery = {
  listServings:  {
    __typename: "ModelServingConnection",
    items:  Array< {
      __typename: "Serving",
      dId: string,
      sId: string,
      dt: string,
      pH: string,
      ts: string,
      nCB: number,
      nCL: number,
      nCS: number,
      nCD: number,
      nAB: number,
      nAL: number,
      nAS: number,
      nAD: number,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetProductionQueryVariables = {
  dId: string,
  sId: string,
  dt: string,
};

export type GetProductionQuery = {
  getProduction:  {
    __typename: "Production",
    dId: string,
    sId: string,
    dt: string,
    pB: number,
    pL: number,
    pS: number,
    pD: number,
  } | null,
};

export type ListProductionsQueryVariables = {
  dId?: string | null,
  sIdDt?: ModelProductionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelProductionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductionsQuery = {
  listProductions:  {
    __typename: "ModelProductionConnection",
    items:  Array< {
      __typename: "Production",
      dId: string,
      sId: string,
      dt: string,
      pB: number,
      pL: number,
      pS: number,
      pD: number,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type PendingUsersQueryVariables = {
  pending?: UserStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PendingUsersQuery = {
  pendingUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      cId: string,
      dId: string,
      email: string,
      pH: string,
      pending: UserStatus | null,
      name: string | null,
      chldr: Array< string | null > | null,
      meals: Array< MealType | null > | null,
      allCh: string | null,
      algrs: Array< string | null > | null,
      sAcl: Array< string | null > | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ServingsForPhoneQueryVariables = {
  dId?: string | null,
  pHDtTs?: ModelServingByPhoneNumberCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ServingsForPhoneQuery = {
  servingsForPhone:  {
    __typename: "ModelServingConnection",
    items:  Array< {
      __typename: "Serving",
      dId: string,
      sId: string,
      dt: string,
      pH: string,
      ts: string,
      nCB: number,
      nCL: number,
      nCS: number,
      nCD: number,
      nAB: number,
      nAL: number,
      nAS: number,
      nAD: number,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetDistrictQueryVariables = {
  id: string,
};

export type GetDistrictQuery = {
  getDistrict:  {
    __typename: "District",
    id: string,
    name: string,
    state: string,
    iactv: boolean | null,
  } | null,
};

export type ListDistrictsQueryVariables = {
  filter?: ModelDistrictFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDistrictsQuery = {
  listDistricts:  {
    __typename: "ModelDistrictConnection",
    items:  Array< {
      __typename: "District",
      id: string,
      name: string,
      state: string,
      iactv: boolean | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetSiteQueryVariables = {
  dId: string,
  sId: string,
};

export type GetSiteQuery = {
  getSite:  {
    __typename: "Site",
    dId: string,
    sId: string,
    name: string,
    addr:  {
      __typename: "Address",
      ln1: string,
      ln2: string | null,
      zip: string,
      cty: string,
      st: string,
      ctr: string | null,
      addl: string | null,
    },
    meals: Array< MealType > | null,
    hop:  {
      __typename: "HoursOfOperation",
      m:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      t:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      w:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      th:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      f:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      st:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
      su:  {
        __typename: "TimeFrame",
        s: string,
        e: string,
      } | null,
    },
    actv: boolean,
    stCd: string | null,
    addl: string | null,
  } | null,
};

export type ListSitesQueryVariables = {
  dId?: string | null,
  sId?: ModelStringKeyConditionInput | null,
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSitesQuery = {
  listSites:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      dId: string,
      sId: string,
      name: string,
      addr:  {
        __typename: "Address",
        ln1: string,
        ln2: string | null,
        zip: string,
        cty: string,
        st: string,
        ctr: string | null,
        addl: string | null,
      },
      meals: Array< MealType > | null,
      hop:  {
        __typename: "HoursOfOperation",
        m:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
        t:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
        w:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
        th:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
        f:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
        st:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
        su:  {
          __typename: "TimeFrame",
          s: string,
          e: string,
        } | null,
      },
      actv: boolean,
      stCd: string | null,
      addl: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};
