import React from "react";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import theme from "./theme";
import { ThemeProvider, Theme, makeStyles } from "@material-ui/core";
import RouteConfig from "./pages";

const useStyles = makeStyles((currTheme: Theme) => ({
    main: {
        marginTop: 84,
        minHeight: "85vh",
        [currTheme.breakpoints.down("md")]: {
            minHeight: "84vh"
        }
    }
}));

const App: React.FC = () => {
    const styles = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <AppBar />

            <main className={styles.main}>
                <RouteConfig />
            </main>

            <Footer />
        </ThemeProvider>
    );
};

export default App;
