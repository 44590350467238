import { createBrowserHistory, History } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { sagaMiddleware } from "../control/middleware/index";
import { routerMiddleware } from "connected-react-router";
import { createRootReducer } from "./reducers";

export const history: History = createBrowserHistory();

const isProduction = process.env.NODE_ENV === "production";

export const store = configureStore({
    reducer: createRootReducer(history),
    devTools: !isProduction,
    middleware: [sagaMiddleware, routerMiddleware(history)]
});
