import React from "react";
import { List, Divider } from "@material-ui/core";
import NavListItem from "./NavListItem";
import { CognitoGroup } from "control/API";
import { useSelector } from "react-redux";
import { UserEntity } from "model/entity";

const NavigationItems: React.FC = () => {
    const isSuperAdmin = useSelector(UserEntity.isSuperAdmin);
    return (
        <List>
            <NavListItem
                labelText="Dashboard"
                pathName="/"
                accessGroup={[CognitoGroup.SuperAdmin]}
            />
            <NavListItem
                labelText="Districts"
                pathName="/districts"
                accessGroup={[CognitoGroup.SuperAdmin]}
            />
            <NavListItem
                labelText="Pending Users"
                pathName="/pending-users"
                accessGroup={[CognitoGroup.SuperAdmin]}
            />
            { isSuperAdmin ? <Divider /> : <div /> }
            <NavListItem
                labelText="Accounts"
                pathName="/accounts"
                accessGroup={[CognitoGroup.DistrictAdmin, CognitoGroup.SuperAdmin]}
            />
            <NavListItem labelText="Locations" pathName="/sites" />
            <NavListItem
                labelText="Service"
                pathName="/service"
                accessGroup={[
                    CognitoGroup.DistrictAdmin,
                    CognitoGroup.SiteAdmin
                ]}
            />
            <NavListItem
                labelText="Production"
                pathName="/production"
                accessGroup={[
                    CognitoGroup.DistrictAdmin,
                    CognitoGroup.SiteAdmin
                ]}
            />
            <Divider />
            <NavListItem
                labelText="End of Day Report"
                pathName="/end-of-day"
                accessGroup={[CognitoGroup.DistrictAdmin, CognitoGroup.SiteAdmin]}
            />
            <NavListItem
                labelText="District-Wide Summary"
                pathName="/district-wide-summary"
                accessGroup={[CognitoGroup.DistrictAdmin]}
            />
            <NavListItem
                labelText="Meal Count Report"
                pathName="/meal-count-report"
                accessGroup={[CognitoGroup.DistrictAdmin]}
            />
        </List>
    );
};

export default NavigationItems;
