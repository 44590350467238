import React, { useState } from "react";
import { makeStyles, Theme, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

/**
 * List of US States
 * https://gist.github.com/mshafrir/2646763
 */
const statesList: { Name: string; Code: string }[] = [
    {
        Name: "Alabama",
        Code: "AL"
    },
    {
        Name: "Alaska",
        Code: "AK"
    },
    {
        Name: "Arizona",
        Code: "AZ"
    },
    {
        Name: "Arkansas",
        Code: "AR"
    },
    {
        Name: "California",
        Code: "CA"
    },
    {
        Name: "Colorado",
        Code: "CO"
    },
    {
        Name: "Connecticut",
        Code: "CT"
    },
    {
        Name: "Delaware",
        Code: "DE"
    },
    {
        Name: "District Of Columbia",
        Code: "DC"
    },
    {
        Name: "Florida",
        Code: "FL"
    },
    {
        Name: "Georgia",
        Code: "GA"
    },
    {
        Name: "Guam",
        Code: "GU"
    },
    {
        Name: "Hawaii",
        Code: "HI"
    },
    {
        Name: "Idaho",
        Code: "ID"
    },
    {
        Name: "Illinois",
        Code: "IL"
    },
    {
        Name: "Indiana",
        Code: "IN"
    },
    {
        Name: "Iowa",
        Code: "IA"
    },
    {
        Name: "Kansas",
        Code: "KS"
    },
    {
        Name: "Kentucky",
        Code: "KY"
    },
    {
        Name: "Louisiana",
        Code: "LA"
    },
    {
        Name: "Maine",
        Code: "ME"
    },
    {
        Name: "Maryland",
        Code: "MD"
    },
    {
        Name: "Massachusetts",
        Code: "MA"
    },
    {
        Name: "Michigan",
        Code: "MI"
    },
    {
        Name: "Minnesota",
        Code: "MN"
    },
    {
        Name: "Mississippi",
        Code: "MS"
    },
    {
        Name: "Missouri",
        Code: "MO"
    },
    {
        Name: "Montana",
        Code: "MT"
    },
    {
        Name: "Nebraska",
        Code: "NE"
    },
    {
        Name: "Nevada",
        Code: "NV"
    },
    {
        Name: "New Hampshire",
        Code: "NH"
    },
    {
        Name: "New Jersey",
        Code: "NJ"
    },
    {
        Name: "New Mexico",
        Code: "NM"
    },
    {
        Name: "New York",
        Code: "NY"
    },
    {
        Name: "North Carolina",
        Code: "NC"
    },
    {
        Name: "North Dakota",
        Code: "ND"
    },
    {
        Name: "Ohio",
        Code: "OH"
    },
    {
        Name: "Oklahoma",
        Code: "OK"
    },
    {
        Name: "Oregon",
        Code: "OR"
    },
    {
        Name: "Pennsylvania",
        Code: "PA"
    },
    {
        Name: "Rhode Island",
        Code: "RI"
    },
    {
        Name: "South Carolina",
        Code: "SC"
    },
    {
        Name: "South Dakota",
        Code: "SD"
    },
    {
        Name: "Tennessee",
        Code: "TN"
    },
    {
        Name: "Texas",
        Code: "TX"
    },
    {
        Name: "Utah",
        Code: "UT"
    },
    {
        Name: "Vermont",
        Code: "VT"
    },
    {
        Name: "Virginia",
        Code: "VA"
    },
    {
        Name: "Washington",
        Code: "WA"
    },
    {
        Name: "West Virginia",
        Code: "WV"
    },
    {
        Name: "Wisconsin",
        Code: "WI"
    },
    {
        Name: "Wyoming",
        Code: "WY"
    }
];

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: 12,
        width: "100%",
        maxWidth: 400
    },
    menu: {
        maxHeight: 100
    }
}));

const SelectState: React.FC<{
    value: string;
    onChange: (code: string) => void;
}> = props => {
    const styles = useStyles();
    const onChange = (event: any, value: any) => {
        if (value) {
            props.onChange(value.Code);
        }
    };
    const [inputVal, setInputVal] = useState(props.value);
    const updateInputVal = (event: any, value: string) => {
        setInputVal(value);
    };

    return (
        <Autocomplete
            id="select-state"
            className={styles.container}
            options={statesList}
            autoHighlight={true}
            onChange={onChange}
            inputValue={inputVal}
            onInputChange={updateInputVal}
            getOptionLabel={(option: any) => option.Name}
            getOptionSelected={(option: any) => option.Code}
            renderOption={(option: any) => (
                <React.Fragment>{option.Name}</React.Fragment>
            )}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    label="Choose State"
                    variant="outlined"
                    required={true}
                />
            )}
        />
    );
};

export default SelectState;
