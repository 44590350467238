import React, { useState } from "react";
import BaseToolbar from "view/components/BaseToolbar";
import SelectDistrict from "view/components/SelectDistrict";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { loadSites } from "control/actions";
import { Alert } from "@material-ui/lab";
import { UserEntity } from "model/entity";

const SiteToolbar: React.FC = () => {
    const [distId, setDistId] = useState("");
    const dispatch = useDispatch();

    const isAdmin = useSelector(UserEntity.isAdmin);
    const isSiteAdmin = useSelector(UserEntity.isSiteAdmin);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (isAdmin || isSiteAdmin) {
        return <div />;
    }

    const onApply = () => {
        dispatch(loadSites(distId, true));
    };

    return (
        <BaseToolbar>
            <SelectDistrict
                value={distId}
                onChange={setDistId}
                variant="standard"
            />
            <div style={{ paddingTop: isMobile ? 10 : undefined }}>
                {distId !== "" ? (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onApply}
                    >
                        Apply
                    </Button>
                ) : null}
                {distId === "" ? (
                    <Alert severity="info">Please select a District</Alert>
                ) : (
                    <div />
                )}
            </div>
        </BaseToolbar>
    );
};

export default SiteToolbar;
