import { ISite, UserEntity } from "model/entity";
import { useDispatch, useSelector } from "react-redux";
import { updateSiteInit } from "control/actions";
import { Switch } from "@material-ui/core";
import React from "react";

const StatusSwitch: React.FC<{
    site: ISite;
}> = props => {
    const dispatch = useDispatch();
    const isAdmin = useSelector(UserEntity.isAdmin);
    const toggleStatus = () => {
        dispatch(
            updateSiteInit({
                dId: props.site.dId,
                sId: props.site.sId,
                actv: !props.site.actv
            })
        );
    };

    return (
        <Switch
            checked={props.site.actv}
            onChange={toggleStatus}
            disabled={!isAdmin}
            name="Status"
            inputProps={{ "aria-label": "Toggle Active Status" }}
        />
    );
};

export default StatusSwitch;
