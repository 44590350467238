import React, { useState } from "react";
import {
    Paper,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
    AccountEntity,
    DistrictsEntity,
    IDistrict,
    UserEntity
} from "model/entity";
import { updateUser } from "control/actions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
    paper: {
        padding: 12,
        marginBottom: 12
    },
    form: {
        display: "flex",
        flexDirection: "column"
    },
    formControl: {},
    saveButton: {
        marginTop: 6,
        alignSelf: "flex-end",
        width: "fit-content"
    }
});

const UpdateDistrictForm: React.FC = () => {
    const styles = useStyles();
    const selectedAccount = useSelector(AccountEntity.selectedAccount);
    const districts = useSelector(DistrictsEntity.items);

    const [districtId, setDistrictId] = useState<string>(
        selectedAccount ? selectedAccount.dId : ""
    );

    const handleChange = (event: any) => {
        setDistrictId(event.target.value);
    };

    const dispatch = useDispatch();
    const saveDistrictId = () => {
        if (selectedAccount && districtId) {
            dispatch(
                updateUser({
                    cId: selectedAccount.cId,
                    dId: districtId
                })
            );
        }
    };

    const isSuperAdmin = useSelector(UserEntity.isSuperAdmin);
    if (!isSuperAdmin) {
        return <div />;
    }

    return (
        <Paper className={styles.paper}>
            <form className={styles.form}>
                <Alert style={{ marginBottom: 6 }} severity="info">
                    User has to refresh the page after updating District.
                    <br /> Also try logging out if refresh does not work.
                </Alert>
                <FormControl className={styles.formControl}>
                    <InputLabel id="select-district-label">
                        Select District
                    </InputLabel>
                    <Select
                        labelId="select-district-label"
                        id="select-user-district"
                        value={districtId}
                        onChange={handleChange}
                    >
                        {districts.map((district: IDistrict) => (
                            <MenuItem key={district.id} value={district.id}>
                                {district.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    className={styles.saveButton}
                    variant="outlined"
                    color="secondary"
                    onClick={saveDistrictId}
                >
                    Save
                </Button>
            </form>
        </Paper>
    );
};

export default UpdateDistrictForm;
