import React from "react";
import BaseToolbar from "view/components/BaseToolbar";
import SelectLocation from "view/components/SelectLocation";
import SelectDate from "view/components/SelectDate";
import { makeStyles, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
    locationField: {
        marginBottom: 8,
        marginRight: 8
    },
    date: {
        width: 150,
        marginRight: 8
    },
    alert: {
        marginBottom: 8
    }
});

const DateSiteToolbar: React.FC<{
    date: Date | null;
    siteId: string | null;
    actions?: React.ReactElement;
    onChangeDate: (date: Date | null) => void;
    onChangeSite: (siteId: string | null) => void;
    onClickApply: (siteId: string, date: Date) => void;
    disableFutureDate?: boolean;
}> = props => {
    const styles = useStyles();

    const onClickApply = () => {
        if (props.date !== null && props.siteId !== null) {
            props.onClickApply(props.siteId, props.date);
        }
    };

    return (
        <BaseToolbar actions={props.actions}>
            <div className={styles.date}>
                <SelectDate
                    id="select-date"
                    value={props.date}
                    onChange={props.onChangeDate}
                    disableFutureDates={props.disableFutureDate}
                />
            </div>
            <div className={styles.locationField}>
                <SelectLocation
                    value={props.siteId}
                    onChange={props.onChangeSite}
                />
            </div>

            {props.date && props.siteId ? (
                <div style={{ marginBottom: 8 }}>
                    <Button
                        disabled={props.date === null || props.siteId === null}
                        color="primary"
                        variant="outlined"
                        onClick={onClickApply}
                    >
                        Apply
                    </Button>
                </div>
            ) : (
                <Alert className={styles.alert} severity="info">
                    Please select Date & Location
                </Alert>
            )}
        </BaseToolbar>
    );
};

export default DateSiteToolbar;
