import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { ReportEntity } from "model/entity";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { getMonthlyReport } from "control/actions";

const MealsServed: React.FC = () => {
    const items = useSelector(ReportEntity.monthlyReport);
    const status = useSelector(ReportEntity.monthlyReportStatus);
    const dispatch = useDispatch();

    const onRefreshData = () => {
        dispatch(getMonthlyReport());
    };

    return (
        <React.Fragment>
            {status === "fail" ? (
                <Alert severity="error">Unable to get the report</Alert>
            ) : (
                <div />
            )}
            <MaterialTable
                isLoading={status === "loading" || status === "initial"}
                columns={[
                    {
                        title: "Location Name",
                        field: "siteName",
                        cellStyle: (value: any) => {
                            return value === "Total"
                                ? {
                                      fontWeight: "bold"
                                  }
                                : {};
                        }
                    },
                    {
                        title: "Today",
                        field: "today",
                        type: "numeric"
                    },
                    {
                        title: "Yesterday",
                        field: "yesterday",
                        type: "numeric"
                    },
                    {
                        title: "This week",
                        field: "thisWeek",
                        type: "numeric"
                    },
                    {
                        title: "This Month",
                        field: "thisMonth",
                        type: "numeric"
                    }
                ]}
                data={items}
                options={{
                    exportButton: true,
                    search: false,
                    paging: false,
                    sorting: false,
                    rowStyle: rowData =>
                        rowData.siteName === "Total"
                            ? {
                                  background: "rgba(0,0,0,0.05)"
                              }
                            : {}
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <Button
                                style={{ marginLeft: 16 }}
                                color="primary"
                                variant="outlined"
                                onClick={onRefreshData}
                            >
                                <RefreshIcon />
                                Refresh
                            </Button>
                        </div>
                    )
                }}
                title="Meals Served"
            />
        </React.Fragment>
    );
};

export default MealsServed;
