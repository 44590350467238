import { CreateDistrictInput, UpdateDistrictInput } from "../API";
import { createAction } from "@reduxjs/toolkit";
import { IDistrict } from "model/entity";

// Items
export const loadDistricts = createAction("LOAD_DISTRICTS", (type: "only-active" | "all") => ({
    payload: type
}));
export const loadDistrictsSuccess = createAction(
    "LOAD_DISTRICTS_SUCCESS",
    (items: any[]) => ({
        payload: items
    })
);
export const loadDistrictsFail = createAction("LOAD_DISTRICTS_FAIL");

// Create
export const createDistrictOpen = createAction("CREATE_DISTRICT_OPEN");
export const createDistrictInit = createAction(
    "CREATE_DISTRICT_INIT",
    (data: CreateDistrictInput) => ({
        payload: data
    })
);
export const createDistrictSuccess = createAction(
    "CREATE_DISTRICT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const createDistrictFail = createAction("CREATE_DISTRICT_FAIL");
export const createDistrictCancel = createAction("CREATE_DISTRICT_CANCEL");

// Update
export const updateDistrictOpen = createAction(
    "UPDATE_DISTRICT_OPEN",
    (id: string) => ({
        payload: id
    })
);
export const updateDistrictInit = createAction(
    "UPDATE_DISTRICT_INIT",
    (data: UpdateDistrictInput) => ({
        payload: data
    })
);
export const updateDistrictSuccess = createAction(
    "UPDATE_DISTRICT_SUCCESS",
    (data: any) => ({
        payload: data
    })
);
export const updateDistrictFail = createAction("UPDATE_DISTRICT_FAIL");
export const updateDistrictCancel = createAction("UPDATE_DISTRICT_CANCEL");

export const toggleDistrictStatus = createAction("TOGGLE_DISTRICT_STATUS", (data: IDistrict) => ({
    payload: data
}));