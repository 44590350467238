import {
    CreateSiteInput,
    UpdateSiteInput,
    ListSitesQueryVariables,
    DeleteSiteInput
} from "control/API";
import { apiClient, publicClient } from "./api.client";
import gql from "graphql-tag";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import { concat } from "lodash";

class SiteTransport {
    public async listSites(districtId?: string, onlyActive?: boolean) {
        const getVariables = (
            nToken: string | null
        ): ListSitesQueryVariables => ({
            nextToken: nToken,
            dId: districtId || undefined,
            // only load active sites for anonymous access
            filter: onlyActive
                ? {
                      actv: {
                          eq: true
                      }
                  }
                : undefined,
            limit: 2000
        });

        let nextToken: string | null = null;
        let items: any[] = [];

        do {
            const resp: any = await publicClient.query({
                query: gql(queries.listSites),
                variables: getVariables(nextToken)
            });
            items = concat(items, resp.data.listSites.items);
            nextToken = resp.data.listSites.nextToken;

            if (!districtId) {
                break;
            }
        } while (nextToken);

        return items;
    }

    public async createSite(input: CreateSiteInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.createSite),
            variables: {
                input
            }
        });

        return resp.data.createSite;
    }

    public async updateSite(input: UpdateSiteInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.updateSite),
            variables: {
                input
            }
        });

        return resp.data.updateSite;
    }

    public async deleteSite(input: DeleteSiteInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.deleteSite),
            variables: {
                input
            }
        });

        return resp.data.deleteSite;
    }
}

export default new SiteTransport();
