import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { UiEntity, UserEntity } from "model/entity";
import {
    Drawer,
    makeStyles,
    useTheme,
    useMediaQuery,
    Theme,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    Fab
} from "@material-ui/core";
import { toggleNavbar } from "control/actions";
import NavigationItems from "./NavigationItems";
import CloseIcon from "@material-ui/icons/ChevronLeft";
import Logo from "./Logo";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 280
    },
    searchBox: {
        margin: 8
    },
    toolbar: {
        padding: 0,
        height: 80
    },
    version: {
        paddingTop: 8,
        paddingLeft: 16
    }
}));

const NavBar: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const isNavOpen = useSelector(UiEntity.isNavOpen);
    const dispatch = useDispatch();
    const styles = useStyles();
    const district = useSelector(UserEntity.getDistrict);

    const dispatchToggle = () => {
        dispatch(toggleNavbar());
    };

    return (
        <Drawer
            classes={{
                paper: styles.container
            }}
            onClose={dispatchToggle}
            variant={isMobile ? "temporary" : "persistent"}
            open={isNavOpen}
        >
            <AppBar position="static">
                <Toolbar className={styles.toolbar}>
                    <Fab
                        style={{ marginLeft: 6, marginRight: 6 }}
                        onClick={dispatchToggle}
                        color="primary"
                        size="small"
                    >
                        <CloseIcon />
                    </Fab>
                    <Logo />
                </Toolbar>
            </AppBar>
            <Typography style={{ padding: 16 }} variant="h6">
                {district?.name}
            </Typography>
            <Divider />
            <NavigationItems />
            <Divider />
            <Typography className={styles.version} variant="caption">
                <b>v1.1.7</b>
            </Typography>
        </Drawer>
    );
};

export default NavBar;
