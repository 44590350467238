/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($accountId: String!, $groupName: CognitoGroup!) {
    addUserToGroup(accountId: $accountId, groupName: $groupName)
  }
`;
export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($accountId: String!, $groupName: CognitoGroup!) {
    removeUserFromGroup(accountId: $accountId, groupName: $groupName)
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId)
  }
`;
export const createDistrict = /* GraphQL */ `
  mutation CreateDistrict(
    $input: CreateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    createDistrict(input: $input, condition: $condition) {
      id
      name
      state
      iactv
    }
  }
`;
export const updateDistrict = /* GraphQL */ `
  mutation UpdateDistrict(
    $input: UpdateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    updateDistrict(input: $input, condition: $condition) {
      id
      name
      state
      iactv
    }
  }
`;
export const deleteDistrict = /* GraphQL */ `
  mutation DeleteDistrict(
    $input: DeleteDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    deleteDistrict(input: $input, condition: $condition) {
      id
      name
      state
      iactv
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      cId
      dId
      email
      pH
      pending
      name
      chldr
      meals
      allCh
      algrs
      sAcl
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      cId
      dId
      email
      pH
      pending
      name
      chldr
      meals
      allCh
      algrs
      sAcl
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      cId
      dId
      email
      pH
      pending
      name
      chldr
      meals
      allCh
      algrs
      sAcl
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      dId
      sId
      name
      addr {
        ln1
        ln2
        zip
        cty
        st
        ctr
        addl
      }
      meals
      hop {
        m {
          s
          e
        }
        t {
          s
          e
        }
        w {
          s
          e
        }
        th {
          s
          e
        }
        f {
          s
          e
        }
        st {
          s
          e
        }
        su {
          s
          e
        }
      }
      actv
      stCd
      addl
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      dId
      sId
      name
      addr {
        ln1
        ln2
        zip
        cty
        st
        ctr
        addl
      }
      meals
      hop {
        m {
          s
          e
        }
        t {
          s
          e
        }
        w {
          s
          e
        }
        th {
          s
          e
        }
        f {
          s
          e
        }
        st {
          s
          e
        }
        su {
          s
          e
        }
      }
      actv
      stCd
      addl
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      dId
      sId
      name
      addr {
        ln1
        ln2
        zip
        cty
        st
        ctr
        addl
      }
      meals
      hop {
        m {
          s
          e
        }
        t {
          s
          e
        }
        w {
          s
          e
        }
        th {
          s
          e
        }
        f {
          s
          e
        }
        st {
          s
          e
        }
        su {
          s
          e
        }
      }
      actv
      stCd
      addl
    }
  }
`;
export const createServing = /* GraphQL */ `
  mutation CreateServing(
    $input: CreateServingInput!
    $condition: ModelServingConditionInput
  ) {
    createServing(input: $input, condition: $condition) {
      dId
      sId
      dt
      pH
      ts
      nCB
      nCL
      nCS
      nCD
      nAB
      nAL
      nAS
      nAD
    }
  }
`;
export const updateServing = /* GraphQL */ `
  mutation UpdateServing(
    $input: UpdateServingInput!
    $condition: ModelServingConditionInput
  ) {
    updateServing(input: $input, condition: $condition) {
      dId
      sId
      dt
      pH
      ts
      nCB
      nCL
      nCS
      nCD
      nAB
      nAL
      nAS
      nAD
    }
  }
`;
export const deleteServing = /* GraphQL */ `
  mutation DeleteServing(
    $input: DeleteServingInput!
    $condition: ModelServingConditionInput
  ) {
    deleteServing(input: $input, condition: $condition) {
      dId
      sId
      dt
      pH
      ts
      nCB
      nCL
      nCS
      nCD
      nAB
      nAL
      nAS
      nAD
    }
  }
`;
export const createProduction = /* GraphQL */ `
  mutation CreateProduction(
    $input: CreateProductionInput!
    $condition: ModelProductionConditionInput
  ) {
    createProduction(input: $input, condition: $condition) {
      dId
      sId
      dt
      pB
      pL
      pS
      pD
    }
  }
`;
export const updateProduction = /* GraphQL */ `
  mutation UpdateProduction(
    $input: UpdateProductionInput!
    $condition: ModelProductionConditionInput
  ) {
    updateProduction(input: $input, condition: $condition) {
      dId
      sId
      dt
      pB
      pL
      pS
      pD
    }
  }
`;
export const deleteProduction = /* GraphQL */ `
  mutation DeleteProduction(
    $input: DeleteProductionInput!
    $condition: ModelProductionConditionInput
  ) {
    deleteProduction(input: $input, condition: $condition) {
      dId
      sId
      dt
      pB
      pL
      pS
      pD
    }
  }
`;
