import React from "react";
import { MealType } from "control/API";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { uniq, filter, concat, includes } from "lodash";

const MealCheckbox: React.FC<{
    meals: MealType[];
    meal: MealType;
    onChange: (meals: MealType[]) => void;
}> = ({ meals, meal, onChange }) => {
    const updateMeals = (event: any) => {
        const updated: MealType[] = event.target.checked
            ? uniq(concat(meals, meal))
            : filter(meals, el => el !== meal);
        onChange(updated);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={includes(meals, meal)}
                    name={meal}
                    onChange={updateMeals}
                    color="primary"
                />
            }
            label={meal}
        />
    );
};

const SelectMealType: React.FC<{
    value: MealType[];
    onChange: (meals: MealType[]) => void;
}> = ({ value, onChange }) => {
    return (
        <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 8 }}
        >
            <MealCheckbox
                meals={value}
                meal={MealType.Breakfast}
                onChange={onChange}
            />
            <MealCheckbox
                meals={value}
                meal={MealType.Lunch}
                onChange={onChange}
            />
            <MealCheckbox
                meals={value}
                meal={MealType.Snack}
                onChange={onChange}
            />
            <MealCheckbox
                meals={value}
                meal={MealType.Dinner}
                onChange={onChange}
            />
        </div>
    );
};

export default SelectMealType;
