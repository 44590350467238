import {
    userLoggedOut,
    toggleNavbar,
    openBottomSheet,
    closeBottomSheet
} from "control/actions";
import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";

export interface UiState {
    navigationPanel: boolean;
    bottomSheet: boolean;
}

const initialState: UiState = {
    navigationPanel: false,
    bottomSheet: false
};

export const uiReducer = createReducer(initialState, {
    [toggleNavbar.toString()]: state =>
        produce(state, draft => {
            draft.navigationPanel = !draft.navigationPanel;
        }),
    [openBottomSheet.toString()]: state =>
        produce(state, draft => {
            draft.bottomSheet = true;
        }),
    [closeBottomSheet.toString()]: state =>
        produce(state, draft => {
            draft.bottomSheet = false;
        }),
    [userLoggedOut.toString()]: () => initialState
});
