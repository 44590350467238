import { userLogoutInit } from "./../actions/auth.actions";
import { all, take, call, put, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import logger from "util/logger";
import { Auth, Hub } from "aws-amplify";
import {
    userAuthenticated,
    userLoggedOut,
    openPage,
    setUserRegIncomplete
} from "control/actions";

/**
 * Checks and logs in a user that is cached
 */
function* loginCachedUser() {
    const user = yield Auth.currentUserInfo();
    if (user) {
        yield put(userAuthenticated());
    }
}

function* logoutUser() {
    yield Auth.signOut();
}

/**
 * Detects inactivity and emits true if inactivity detected
 */
function getCognitoAuthChannel() {
    return eventChannel(emitter => {
        Hub.listen("auth", data => {
            emitter(data);
        });

        // unsubscribe function is called when the channel is closed
        return () => {
            logger.info("Auth Channel closed");
        };
    });
}

function* processAuthEvent(data: any) {
    switch (data.payload.event) {
        case "signIn":
            yield put(userAuthenticated());
            break;

        case "signUp":
            yield put(setUserRegIncomplete());
            break;

        case "signOut":
            yield put(openPage("login"));
            yield put(userLoggedOut());
            break;
    }
}

/**
 * Watch for inactivity and log out the user in such cases
 */
function* watchAuthEvents() {
    // for info on channels (https://redux-saga.js.org/docs/advanced/Channels.html)
    const channel = yield call(getCognitoAuthChannel);

    // wait until the channel is closed
    try {
        while (true) {
            const data = yield take(channel);
            yield processAuthEvent(data);
        }
    } finally {
        logger.info("Channel closed");
    }
}

/**
 * Root saga is where all the sagas are merged
 */
export default function* authSagas() {
    yield loginCachedUser();
    yield takeEvery(userLogoutInit, logoutUser);
    yield all([watchAuthEvents()]);
}
