import { AppState } from "model/reducers";
import _ from "lodash";
import { createSelector } from "@reduxjs/toolkit";

export class AccountEntity {
    public static districtUsersStatus(state: AppState) {
        return state.user.districtUsersStatus;
    }

    public static districtUsers(appState: AppState) {
        return createSelector(
            (state: AppState) => state.user.districtUsers,
            items => _.cloneDeep(items)
        )(appState);
    }

    public static accountUpdateStatus(appState: AppState) {
        return appState.user.updateUserStatus;
    }

    public static selectedAccount(appState: AppState) {
        return appState.user.selectedUser;
    }

    public static accountUserGroups(appState: AppState) {
        return appState.user.accountUserGroups;
    }

    public static accountUserGroupStatus(appState: AppState) {
        return appState.user.userGroupStatus;
    }

    public static accountUserGroupUpdateStatus(appState: AppState) {
        return appState.user.updateUserGroupStatus;
    }

    public static deleteUserStatus(appState: AppState) {
        return appState.user.deleteUserStatus;
    }
}
