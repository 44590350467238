import React from "react";
import { TextField } from "@material-ui/core";

const SiteCode: React.FC<{
    value: string | undefined;
    onChange: (val: string | undefined) => void;
}> = props => {
    const onChange = (event: any) => {
        props.onChange(event.target.value);
    };

    return (
        <TextField
            id="site-code"
            style={{ width: "100%", marginTop: 8 }}
            value={props.value || ""}
            label="Site Code (If Applicable)"
            variant="outlined"
            autoComplete="site-code"
            onChange={onChange}
        />
    );
};

export default SiteCode;
