import { forEach } from "lodash";
import { IServing, ActionStatus, ItemsStatus } from "model/entity";
import {
    setServiceDate,
    setServiceSiteId,
    loadServings,
    loadServingsSuccess,
    loadServingsFail,
    createServingOpen,
    createServingInit,
    createServingSuccess,
    createServingFail,
    createServingCancel,
    updateServingOpen,
    updateServingInit,
    updateServingSuccess,
    updateServingFail,
    updateServingCancel,
    loadServingsForPhoneInit,
    loadServingsForPhoneSuccess,
    loadServingsForPhoneFail,
    deleteServingOpen,
    deleteServingInit,
    deleteServingSuccess,
    deleteServingFail,
    deleteServingCancel
} from "control/actions";
import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";

export interface ServingState {
    siteId: string | null;
    date: Date | null;
    items: { [id: string]: IServing };
    status: ItemsStatus;
    selected: string | null;
    selectedItem: IServing | null;
    createStatus: ActionStatus;
    updateStatus: ActionStatus;
    deleteStatus: ActionStatus;
    phRecords: IServing[];
    phRecordsStatus: ItemsStatus;
}

const initialState: ServingState = {
    siteId: null,
    date: new Date(),
    items: {},
    status: "initial",
    selected: null,
    selectedItem: null,
    createStatus: "closed",
    updateStatus: "closed",
    deleteStatus: "closed",
    phRecords: [],
    phRecordsStatus: "initial"
};

const getItemId = (item: IServing): string =>
    `${item.dt}-${item.sId}-${item.pH}-${item.ts}`;

const servingReducer = createReducer(initialState, {
    [setServiceDate.toString()]: (state, action) =>
        produce(state, draft => {
            draft.date = action.payload;
        }),
    [setServiceSiteId.toString()]: (state, action) =>
        produce(state, draft => {
            draft.siteId = action.payload;
        }),

    // Items
    [loadServings.toString()]: (state, action) =>
        produce(state, draft => {
            draft.items = {};
            draft.status = "loading";
        }),
    [loadServingsSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            forEach(action.payload, item => {
                draft.items[getItemId(item)] = item;
            });
            draft.status = "success";
        }),
    [loadServingsFail.toString()]: (state, action) =>
        produce(state, draft => {
            draft.status = "fail";
        }),

    // Create
    [createServingOpen.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "open";
        }),
    [createServingInit.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "in-progress";
        }),
    [createServingSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.createStatus = "closed";
            draft.items[getItemId(item)] = item;
            draft.phRecords = [];
            draft.phRecordsStatus = "initial";
        }),
    [createServingFail.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "fail";
            draft.phRecords = [];
            draft.phRecordsStatus = "initial";
        }),
    [createServingCancel.toString()]: state =>
        produce(state, draft => {
            draft.createStatus = "closed";
            draft.phRecords = [];
            draft.phRecordsStatus = "initial";
        }),

    // Update
    [updateServingOpen.toString()]: (state, action) =>
        produce(state, draft => {
            draft.updateStatus = "open";
            draft.selected = getItemId(action.payload);
        }),
    [updateServingInit.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "in-progress";
        }),
    [updateServingSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.updateStatus = "closed";
            draft.items[getItemId(item)] = item;
        }),
    [updateServingFail.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "fail";
        }),
    [updateServingCancel.toString()]: state =>
        produce(state, draft => {
            draft.updateStatus = "closed";
        }),

    // Ph records
    [loadServingsForPhoneInit.toString()]: state =>
        produce(state, draft => {
            draft.phRecords = [];
            draft.phRecordsStatus = "loading";
        }),
    [loadServingsForPhoneSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.phRecords = action.payload;
            draft.phRecordsStatus = "success";
        }),
    [loadServingsForPhoneFail.toString()]: state =>
        produce(state, draft => {
            draft.phRecordsStatus = "fail";
        }),

    // Delete serving
    [deleteServingOpen.toString()]: (state, action) =>
        produce(state, draft => {
            draft.deleteStatus = "open";
            draft.selectedItem = action.payload;
        }),
    [deleteServingInit.toString()]: state =>
        produce(state, draft => {
            draft.deleteStatus = "in-progress";
        }),
    [deleteServingSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            const item = action.payload;
            draft.deleteStatus = "closed";
            delete draft.items[getItemId(item)];
        }),
    [deleteServingFail.toString()]: state =>
        produce(state, draft => {
            draft.deleteStatus = "fail";
        }),
    [deleteServingCancel.toString()]: state =>
        produce(state, draft => {
            draft.deleteStatus = "closed";
        })
});

export default servingReducer;
