import React from "react";
import { Divider, Typography } from "@material-ui/core";

const SeparatorLabel: React.FC<{
    label: string;
    dense?: boolean;
}> = props => {
    return (
        <React.Fragment>
            {props.dense ? (
                <div style={{ margin: "8px 0px" }} />
            ) : (
                <Divider style={{ marginTop: 12, marginBottom: 8 }} />
            )}
            <Typography
                style={{ textAlign: "center" }}
                variant="subtitle2"
                color="textPrimary"
            >
                <b>{props.label}</b>
            </Typography>
        </React.Fragment>
    );
};

export default SeparatorLabel;
