import React from "react";
import { useDispatch } from "react-redux";
import { getMealCountReport } from "control/actions";
import DateRangeToolbar from "view/components/DateRangeToolbar";

const ReportsToolbar: React.FC = () => {
    const dispatch = useDispatch();

    const onClickApply = (stDate: Date, endDate: Date) => {
        dispatch(getMealCountReport(stDate, endDate));
    };

    return <DateRangeToolbar onClickApply={onClickApply} />;
};

export default ReportsToolbar;
