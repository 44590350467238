import React from "react";
import { useSelector } from "react-redux";
import { SiteEntity, ISite } from "model/entity";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    alert: {
        marginBottom: 8
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    }
});

const InactiveSitesAlert: React.FC = () => {
    const inactiveSites = useSelector(SiteEntity.listInactiveSites);
    const styles = useStyles();

    if (inactiveSites.length === 0) {
        return <div />;
    }

    return (
        <Alert severity="info" className={styles.alert}>
            <AlertTitle>Inactive Location(s) Found</AlertTitle>
            The following report does not have serving records from the
            following locations
            <div className={styles.container}>
                {inactiveSites.map((site: ISite) => (
                    <Chip
                        key={site.sId}
                        color="primary"
                        size="small"
                        className={styles.chip}
                        label={site.name}
                    />
                ))}
            </div>
        </Alert>
    );
};

export default InactiveSitesAlert;
