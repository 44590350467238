import { AppState } from "../reducers";
import { AuthStatus } from "../reducers/user.reducer";
import * as _ from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { UserStatus, CognitoGroup } from "control/API";

export interface IUserInfo {
    cId: string;
    dId: string;
    email: string;
    pH: string;
    sAcl?: string[];
}

export class UserEntity {
    public static isUnauthenticated(state: AppState) {
        return state.user.authStatus === AuthStatus.Unauthenticated;
    }

    public static isAuthenticated(state: AppState) {
        return state.user.authStatus === AuthStatus.UserAuthenticated;
    }

    public static isSuperAdmin(state: AppState) {
        return _.includes(state.user.userGroups, CognitoGroup.SuperAdmin);
    }

    public static isDistrictAdmin(state: AppState) {
        return _.includes(state.user.userGroups, CognitoGroup.DistrictAdmin);
    }

    public static isSiteAdmin(state: AppState) {
        return _.includes(state.user.userGroups, CognitoGroup.SiteAdmin);
    }

    public static isAuthorized(accessGroups: CognitoGroup[] | undefined) {
        return (state: AppState) =>
            !_.flow([
                _.partialRight(_.intersection, accessGroups || []),
                _.isEmpty
            ])(state.user.userGroups);
    }

    public static isAdmin(state: AppState) {
        return (
            UserEntity.isSuperAdmin(state) || UserEntity.isDistrictAdmin(state)
        );
    }

    public static isUserRegComplete(state: AppState): boolean {
        return state.user.userRegStatus === "complete";
    }

    public static isUserRegInProgress(state: AppState): boolean {
        return state.user.userRegStatus === "in-progress";
    }

    public static isUserDataLoading(state: AppState): boolean {
        return (
            state.user.userRegStatus === "in-progress"
        );
    }

    public static userGroups(state: AppState): string[] {
        return state.user.userGroups;
    }

    public static userData(state: AppState) {
        return state.user.userData;
    }

    public static siteAccessControlList(state: AppState) {
        const data = state.user.userData;
        return data && data.sAcl ? data.sAcl : [];
    }

    public static getDistrict(state: AppState) {
        return state.user.district;
    }

    public static attributes(state: AppState) {
        return state.user.attributes;
    }

    public static isPendingApproval(state: AppState) {
        return state.user.userData.pending === UserStatus.PendingApproval;
    }

    public static pendingUsersStatus(state: AppState) {
        return state.user.pendingUsersStatus;
    }

    public static pendingUsers(appState: AppState) {
        return createSelector(
            (state: AppState) => state.user.pendingUsers,
            items => _.cloneDeep(items)
        )(appState);
    }
}
