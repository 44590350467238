import { ItemsStatus } from "model/entity";
import {
    getEndOfDayReport,
    getEndOfDayReportFail,
    getEndOfDayReportSuccess,
    getMealCountReport,
    getMealCountReportSuccess,
    getMealCountReportFail,
    getMonthlyReport,
    getMonthlyReportSuccess,
    getMonthlyReportFail,
    getDistrictWideSummaryReport,
    getDistrictWideSummaryReportSuccess,
    getDistrictWideSummaryReportFail
} from "control/actions";
import { createReducer } from "@reduxjs/toolkit";
import { IServingReport, IMealCountReport } from "./../entity/report.entity";
import produce from "immer";

export interface ReportState {
    endOfDay: IServingReport[];
    endOfDayStatus: ItemsStatus;
    mealCount: IMealCountReport[];
    mealCountStatus: ItemsStatus;
    monthlyReport: [];
    monthlyReportStatus: ItemsStatus;
    districtWideReport: [];
    districtWideReportStatus: ItemsStatus;
}

const initialState: ReportState = {
    endOfDay: [],
    endOfDayStatus: "initial",
    mealCount: [],
    mealCountStatus: "initial",
    monthlyReport: [],
    monthlyReportStatus: "initial",
    districtWideReport: [],
    districtWideReportStatus: "initial"
};

const reportReducer = createReducer(initialState, {
    // End of day
    [getEndOfDayReport.toString()]: state =>
        produce(state, draft => {
            draft.endOfDay = [];
            draft.endOfDayStatus = "loading";
        }),
    [getEndOfDayReportSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.endOfDay = action.payload;
            draft.endOfDayStatus = "success";
        }),
    [getEndOfDayReportFail.toString()]: state =>
        produce(state, draft => {
            draft.endOfDayStatus = "fail";
        }),

    // Get meal count
    [getMealCountReport.toString()]: state =>
        produce(state, draft => {
            draft.mealCount = [];
            draft.mealCountStatus = "loading";
        }),
    [getMealCountReportSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.mealCount = action.payload;
            draft.mealCountStatus = "success";
        }),
    [getMealCountReportFail.toString()]: state =>
        produce(state, draft => {
            draft.mealCountStatus = "fail";
        }),

    // Monthly report
    [getMonthlyReport.toString()]: state =>
        produce(state, draft => {
            draft.monthlyReport = [];
            draft.monthlyReportStatus = "loading";
        }),
    [getMonthlyReportSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.monthlyReport = action.payload;
            draft.monthlyReportStatus = "success";
        }),
    [getMonthlyReportFail.toString()]: state =>
        produce(state, draft => {
            draft.monthlyReportStatus = "fail";
        }),

    // District Wide Report
    [getDistrictWideSummaryReport.toString()]: state =>
        produce(state, draft => {
            draft.districtWideReport = [];
            draft.districtWideReportStatus = "loading";
        }),
    [getDistrictWideSummaryReportSuccess.toString()]: (state, action) =>
        produce(state, draft => {
            draft.districtWideReport = action.payload;
            draft.districtWideReportStatus = "success";
        }),
    [getDistrictWideSummaryReportFail.toString()]: state =>
        produce(state, draft => {
            draft.districtWideReportStatus = "fail";
        })
});

export default reportReducer;
