import productionReducer, { ProductionState } from "./production.reducer";
import servingReducer, { ServingState } from "./serving.reducer";
import { UserState, userReducer } from "./user.reducer";
import { UiState, uiReducer } from "./ui.reducer";
import { combineReducers, AnyAction, Reducer } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { DistrictsState, districtsReducer } from "./districts.reducer";
import { SiteState, siteReducer } from "./site.reducer";
import reportReducer, { ReportState } from "./report.reducer";

export interface AppState {
    router: RouterState;
    ui: UiState;
    user: UserState;
    districts: DistrictsState;
    sites: SiteState;
    serving: ServingState;
    production: ProductionState;
    report: ReportState;
}

type CreateRootReducer = (history: History) => Reducer<AppState, AnyAction>;

export const createRootReducer: CreateRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        ui: uiReducer,
        user: userReducer,
        districts: districtsReducer,
        sites: siteReducer,
        serving: servingReducer,
        production: productionReducer,
        report: reportReducer
    });
