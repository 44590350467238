import { AppState } from "model/reducers";
import { createSelector } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export interface IProduction {
    sId: string;
    dt: string;
    pB: number;
    pL: number;
    pS: number;
    pD: number;
}

export class ProductionEntity {
    public static siteId(state: AppState) {
        return state.production.siteId;
    }

    public static date(state: AppState) {
        return state.production.date;
    }

    public static items(appState: AppState) {
        return createSelector(
            (state: AppState) => Object.values(state.production.items),
            items => cloneDeep(items)
        )(appState);
    }

    public static status(appState: AppState) {
        return appState.production.status;
    }

    public static createStatus(appState: AppState) {
        return appState.production.createStatus;
    }

    public static updateStatus(appState: AppState) {
        return appState.production.updateStatus;
    }

    public static editingItem(appState: AppState): IProduction {
        return (
            appState.production.editingItem || {
                sId: "",
                dt: "",
                pB: 0,
                pL: 0,
                pS: 0,
                pD: 0
            }
        );
    }
}
