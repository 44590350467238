import React, { useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { UserEntity, IDistrict, IUserInfo, AccountEntity } from "model/entity";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
    loadDistrictUsers,
    updateUserInit,
    fetchUserGroup,
    deleteUserInit
} from "control/actions";
import { Alert } from "@material-ui/lab";
import { formatPhoneNumber } from "react-phone-number-input";
import DeleteAccountForm from "./DeleteAccountForm";

const AccountsTable: React.FC = () => {
    const status = useSelector(AccountEntity.districtUsersStatus);
    const items = useSelector(AccountEntity.districtUsers);
    const dispatch = useDispatch();
    const district: IDistrict = useSelector(UserEntity.getDistrict);

    const onRefreshData = () => {
        dispatch(loadDistrictUsers(district.id));
    };

    const onEditUser = (event: any, data: IUserInfo | IUserInfo[]) => {
        if (typeof data === "object") {
            const user = data as IUserInfo;
            dispatch(updateUserInit(user));
            dispatch(fetchUserGroup(user.cId));
        }
    };

    const openDeleteDialog = (event: any, data: IUserInfo | IUserInfo[]) => {
        if (typeof data === "object") {
            const user = data as IUserInfo;
            dispatch(deleteUserInit(user));
        }
    }

    // load users on page load
    useEffect(() => {
        if (status === "initial") {
            onRefreshData();
        }
    });

    return (
        <React.Fragment>
            {status === "fail" && (
                <Alert severity="error">Unable to fetch Accounts</Alert>
            )}
            <DeleteAccountForm />
            <MaterialTable
                isLoading={status === "loading"}
                columns={[
                    {
                        title: "Email",
                        field: "email"
                    },
                    {
                        title: "Phone Number",
                        field: "pH",
                        emptyValue: "NA",
                        render: rowData => formatPhoneNumber(rowData.pH)
                    }
                ]}
                data={items}
                options={{
                    exportButton: true,
                    actionsColumnIndex: -1
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <Button
                                style={{ marginLeft: 16 }}
                                color="primary"
                                variant="outlined"
                                onClick={onRefreshData}
                            >
                                {items.length === 0 ? <div /> : <RefreshIcon />}
                                {items.length === 0 ? "Load" : "Refresh"}
                            </Button>
                        </div>
                    )
                }}
                actions={[
                    {
                        icon: "edit",
                        tooltip: "Edit User",
                        onClick: onEditUser
                    },
                    {
                        icon: "delete",
                        tooltip: "Delete User",
                        onClick: openDeleteDialog
                    }
                ]}
                title="Accounts"
            />
        </React.Fragment>
    );
};

export default AccountsTable;
