import React, { useState } from "react";
import { Button, TextField, makeStyles } from "@material-ui/core";
import { isEmpty, toNumber } from "lodash";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles({
    container: (props: any) => ({
        display: props.hidden ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center"
    }),
    field: {
        marginTop: 6,
        marginBottom: 6
    },
    button: {
        margin: "0px 4px"
    }
});

const InputNumber: React.FC<{
    label: string;
    value: number;
    hidden?: boolean;
    onChange: (val: number) => void;
}> = props => {
    const styles = useStyles(props);
    const [helperText, setHelperText] = useState("");
    const onChange = (event: any) => {
        const value = event.target.value;

        if (value < 0) {
            setHelperText("Value cannot be negative");
            props.onChange(0);
            return;
        }

        props.onChange(value === "" ? 0 : value);
        setHelperText("");
    };

    const onIncrement = () => {
        props.onChange(toNumber(props.value) + 1);
    };

    const onDecrement = () => {
        props.onChange(toNumber(props.value) - 1);
    };

    return (
        <div className={styles.container}>
            <Button
                variant="outlined"
                className={styles.button}
                disabled={props.value <= 0}
                onClick={onDecrement}
            >
                <RemoveIcon />
            </Button>
            <TextField
                type="number"
                variant="outlined"
                className={styles.field}
                error={!isEmpty(helperText)}
                helperText={helperText}
                label={props.label}
                value={props.value === 0 ? "" : props.value}
                onChange={onChange}
            />
            <Button
                variant="outlined"
                color="primary"
                className={styles.button}
                onClick={onIncrement}
            >
                <AddIcon />
            </Button>
        </div>
    );
};

export default InputNumber;
