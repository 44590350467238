import React, { useState } from "react";
import DateSiteToolbar from "view/components/DateSiteToolbar";
import { useDispatch } from "react-redux";
import { getEndOfDayReport } from "control/actions/reports.actions";

const Toolbar: React.FC<{
    siteId: string | null;
    onChangeSite: (id: string | null) => void;
}> = props => {
    const [currDate, setDate] = useState<Date | null>(new Date());
    const dispatch = useDispatch();

    const onClickApply = () => {
        if (currDate && props.siteId) {
            dispatch(getEndOfDayReport(props.siteId, currDate));
        }
    };

    return (
        <DateSiteToolbar
            date={currDate}
            siteId={props.siteId}
            onChangeDate={setDate}
            onChangeSite={props.onChangeSite}
            onClickApply={onClickApply}
        />
    );
};

export default Toolbar;
