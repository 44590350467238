import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
    palette: {
        primary: {
            main: "#2f4858"
        },
        secondary: {
            main: "#00808C"
        },
    }
});
theme = responsiveFontSizes(theme);

export default theme;
