import {
    CreateUserInput,
    UpdateUserInput,
    PendingUsersQueryVariables,
    UserStatus,
    AddUserToGroupMutationVariables,
    ListUsersQueryVariables,
    FetchUserGroupsQueryVariables,
    RemoveUserFromGroupMutationVariables,
    DeleteAccountMutationVariables
} from "control/API";
import { apiClient } from "./api.client";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import gql from "graphql-tag";
import _ from "lodash";

class UserTransport {
    public async listPendingUsers() {
        const getVariables = (
            nToken: string | null
        ): PendingUsersQueryVariables => ({
            limit: 3000,
            pending: UserStatus.PendingApproval,
            nextToken: nToken
        });

        let nextToken: string | null = null;
        let items: any[] = [];

        do {
            const resp: any = await apiClient.query({
                query: gql(queries.pendingUsers),
                variables: getVariables(nextToken),
                fetchPolicy: "network-only"
            });
            items = _.concat(items, resp.data.pendingUsers.items);
            nextToken = resp.data.pendingUsers.nextToken;
        } while (nextToken);

        return items;
    }

    public async listDistrictUsers(districtId: string) {
        const getVariables = (
            nToken: string | null
        ): ListUsersQueryVariables => ({
            limit: 3000,
            nextToken: nToken,
            filter: {
                dId: {
                    eq: districtId
                }
            }
            });

        let nextToken: string | null = null;
        let items: any[] = [];

        do {
            const resp: any = await apiClient.query({
                query: gql(queries.listUsers),
                variables: getVariables(nextToken),
                fetchPolicy: "network-only"
            });
            items = _.concat(items, resp.data.listUsers.items);
            nextToken = resp.data.listUsers.nextToken;
        } while (nextToken);

        return items;
    }

    public async createUser(input: CreateUserInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.createUser),
            variables: {
                input
            }
        });

        return resp.data.createUser;
    }

    public async updateUser(input: UpdateUserInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.updateUser),
            variables: {
                input
            }
        });

        return resp.data.updateUser;
    }

    public async getUser(cognitoId: string) {
        const resp: any = await apiClient.query({
            query: gql(queries.getUser),
            variables: {
                cId: cognitoId
            },
            fetchPolicy: "network-only"
        });

        return resp.data.getUser;
    }

    public async deleteAccount(variables: DeleteAccountMutationVariables) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.deleteAccount),
            variables
        });

        return resp.data.deleteUser;
    }

    public async fetchUserGroups(variables: FetchUserGroupsQueryVariables) {
        const resp: any = await apiClient.query({
            query: gql(queries.fetchUserGroups),
            variables,
            fetchPolicy: "network-only"
        });

        return resp.data.fetchUserGroups;
    }

    public async addUserToGroup(variables: AddUserToGroupMutationVariables) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.addUserToGroup),
            variables
        });

        return resp.data.addUserToGroup;
    }

    public async removeUserFromGroup(variables: RemoveUserFromGroupMutationVariables) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.removeUserFromGroup),
            variables
        });

        return resp.data.removeUserFromGroup;
    }
}

export default new UserTransport();
