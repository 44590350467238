import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./view/App";
import * as serviceWorker from "./control/offline/serviceWorker";
import { sagaMiddleware, rootSaga } from "./control/middleware";
import { store } from "./model/store";
import { Provider } from "react-redux";
import { initApp } from "./control/actions";
import awsConfig from "./aws-exports";
import Amplify from "aws-amplify";

// initialize the amplify resources
Amplify.configure(awsConfig);

// initialize the saga middleware
// and also initialize the app using an init action
sagaMiddleware.run(rootSaga);
store.dispatch(initApp());

//@ts-ignore
// window.Amplify = Amplify;

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
