import {
    ListDistrictsQueryVariables,
    CreateDistrictInput,
    UpdateDistrictInput,
    DeleteDistrictInput,
    GetDistrictQueryVariables
} from "control/API";
import { apiClient, publicClient } from "./api.client";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import gql from "graphql-tag";
import { concat } from "lodash";

class DistrictTransport {
    public async listDistricts(onlyActive?: boolean) {
        const getVariables = (
            nToken: string | null
        ): ListDistrictsQueryVariables => ({
            limit: 1000,
            nextToken: nToken,
                filter: onlyActive ? {
                    iactv: {
                        ne: true
                    }
            } : undefined
        });

        let nextToken: string | null = null;
        let allItems: any[] = [];

        do {
            const resp: any = await publicClient.query({
                query: gql(queries.listDistricts),
                variables: getVariables(nextToken)
            });
            allItems = concat(allItems, resp.data.listDistricts.items);
            nextToken = resp.data.listDistricts.nextToken;
        } while (nextToken);

        return allItems;
    }

    public async getDistrict(variables: GetDistrictQueryVariables) {
        const resp: any = await publicClient.query({
            query: gql(queries.getDistrict),
            variables
        });

        return resp.data.getDistrict;
    }

    public async createDistrict(input: CreateDistrictInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.createDistrict),
            variables: {
                input
            }
        });

        return resp.data.createDistrict;
    }

    public async updateDistrict(input: UpdateDistrictInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.updateDistrict),
            variables: {
                input
            }
        });

        return resp.data.updateDistrict;
    }

    public async deleteDistrict(input: DeleteDistrictInput) {
        const resp: any = await apiClient.mutate({
            mutation: gql(mutations.deleteDistrict),
            variables: {
                input
            }
        });

        return resp.data.deleteDistrict;
    }
}

export default new DistrictTransport();
