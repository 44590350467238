import { format } from "date-fns";
import { Maybe } from "./maybe";

export enum LogLevel {
    DEBUG = 0,
    INFO,
    WARN,
    ERROR,
    SILENT
}

export class Logger {
    public static toLogLevel(level: string | undefined): Maybe<LogLevel> {
        switch (level) {
            case "DEBUG":
                return Maybe.just(LogLevel.DEBUG);

            case "INFO":
                return Maybe.just(LogLevel.INFO);

            case "WARN":
                return Maybe.just(LogLevel.WARN);

            case "ERROR":
                return Maybe.just(LogLevel.ERROR);

            case "SILENT":
                return Maybe.just(LogLevel.SILENT);

            default:
                return Maybe.nothing();
        }
    }

    private logName: string;
    private logLevel: LogLevel;

    constructor(name: string, logLevel: LogLevel) {
        this.logName = name;
        this.logLevel = logLevel;
    }

    public toString() {
        return `Current Log Level : ${this.logLevel}`;
    }

    // tslint:disable:no-console

    public getLevel() {
        return this.logLevel;
    }

    public setLevel(level: LogLevel) {
        this.logLevel = level;
    }

    public doNothing() {
        // do not log
    }

    public get debug() {
        if (this.logLevel <= LogLevel.DEBUG) {
            const timestamp = this.getTimestamp();
            return Function.prototype.bind.call(
                console.log,
                console,
                `%cDEBUG%c${timestamp.value}`,
                "font-size:10px;background:rgba(0,0,0,0.1);color:black;padding:3px 6px",
                timestamp.style
            );
        } else {
            return this.doNothing;
        }
    }

    public get info() {
        if (this.logLevel <= LogLevel.INFO) {
            const timestamp = this.getTimestamp();
            return Function.prototype.bind.call(
                console.log,
                console,
                `%c${timestamp.value}`,
                timestamp.style
            );
        } else {
            return this.doNothing;
        }
    }

    public get warn() {
        if (this.logLevel <= LogLevel.WARN) {
            const timestamp = this.getTimestamp();
            return Function.prototype.bind.call(
                console.warn,
                console,
                `%cWARN%c${timestamp.value}`,
                "font-size:10px;background:#FB8C00;color:white;padding:3px 6px;",
                timestamp.style
            );
        } else {
            return this.doNothing;
        }
    }

    public get error() {
        if (this.logLevel <= LogLevel.ERROR) {
            const timestamp = this.getTimestamp();
            return Function.prototype.bind.call(
                console.error,
                console,
                `%cERROR%c${timestamp.value}`,
                "font-size:10px;background:red;color:white;padding:3px 6px;",
                timestamp.style
            );
        } else {
            return this.doNothing;
        }
    }

    public get assert() {
        const timestamp = this.getTimestamp();
        return Function.prototype.bind.call(
            console.assert,
            console,
            `%cASSERT%c${timestamp.value}`,
            "font-size:10px;background:red;color:white;padding:3px 6px;",
            timestamp.style
        );
    }

    private getTimestamp() {
        return {
            value: format(Date.now(), "HH:mm:ss:SSS"),
            style:
                "font-size:10px;padding:3px 6px;background:rgba(0,0,0,0.1);font-weight:500;"
        };
    }
}
