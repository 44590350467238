import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    useTheme,
    useMediaQuery
} from "@material-ui/core";

const BaseDialog: React.FC<{
    open: boolean;
    label: string;
    title: string;
    actions?: React.ReactElement;
    onClose: () => void;
}> = props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog
            open={props.open}
            fullScreen={isMobile}
            onClose={props.onClose}
            aria-labelledby={props.label}
        >
            <DialogTitle id={props.label}>{props.title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            {props.actions}
        </Dialog>
    );
};

export default BaseDialog;
